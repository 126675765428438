import React, { useState } from 'react';
import { MessageSquare, Send, ThumbsUp, ThumbsDown, AlertCircle } from 'lucide-react';
import { useFormValidation } from '../hooks/useFormValidation';

interface FeedbackForm {
  name: string;
  email: string;
  feedback: string;
  rating: 'up' | 'down' | null;
}

const UserFeedback: React.FC = () => {
  const [formData, setFormData] = useState<FeedbackForm>({
    name: '',
    email: '',
    feedback: '',
    rating: null
  });
  const [submitted, setSubmitted] = useState(false);

  const { errors, validate, clearErrors } = useFormValidation({
    name: {
      required: true,
      minLength: 2,
      message: 'Name is required and must be at least 2 characters'
    },
    email: {
      required: true,
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'Please enter a valid email address'
    },
    feedback: {
      required: true,
      minLength: 10,
      message: 'Feedback must be at least 10 characters long'
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const validationResult = validate(formData);
    if (!validationResult.isValid) return;

    // In a real implementation, this would send the feedback to a server
    setSubmitted(true);
    console.log('Feedback submitted:', formData);
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-6 flex items-center text-gray-800 dark:text-white">
          <MessageSquare className="mr-2" />
          User Feedback
        </h2>

        {submitted ? (
          <div className="text-center py-8">
            <ThumbsUp className="w-16 h-16 text-green-500 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Thank You!</h3>
            <p className="text-gray-600 dark:text-gray-400">Your feedback has been submitted successfully.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">
                Name
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                className={`w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white ${
                  errors.name ? 'border-red-500' : 'border-gray-300'
                }`}
                placeholder="Your name"
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-500">{errors.name}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">
                Email
              </label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                className={`w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white ${
                  errors.email ? 'border-red-500' : 'border-gray-300'
                }`}
                placeholder="your.email@example.com"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-500">{errors.email}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">
                Rating
              </label>
              <div className="flex space-x-4">
                <button
                  type="button"
                  onClick={() => setFormData(prev => ({ ...prev, rating: 'up' }))}
                  className={`p-3 rounded-lg border transition-colors duration-200 ${
                    formData.rating === 'up'
                      ? 'bg-green-100 border-green-500 text-green-700'
                      : 'border-gray-300 hover:bg-gray-50'
                  }`}
                >
                  <ThumbsUp size={24} />
                </button>
                <button
                  type="button"
                  onClick={() => setFormData(prev => ({ ...prev, rating: 'down' }))}
                  className={`p-3 rounded-lg border transition-colors duration-200 ${
                    formData.rating === 'down'
                      ? 'bg-red-100 border-red-500 text-red-700'
                      : 'border-gray-300 hover:bg-gray-50'
                  }`}
                >
                  <ThumbsDown size={24} />
                </button>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">
                Your Feedback
              </label>
              <textarea
                value={formData.feedback}
                onChange={(e) => setFormData(prev => ({ ...prev, feedback: e.target.value }))}
                rows={4}
                className={`w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white ${
                  errors.feedback ? 'border-red-500' : 'border-gray-300'
                }`}
                placeholder="Share your thoughts, suggestions, or report issues..."
              />
              {errors.feedback && (
                <p className="mt-1 text-sm text-red-500">{errors.feedback}</p>
              )}
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center"
              >
                <Send className="w-4 h-4 mr-2" />
                Submit Feedback
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default UserFeedback;