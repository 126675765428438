import { Agent, Message } from '../types';
import AzureService from '../services/AzureService';
import { AgentFactory } from '../services/AgentFactory';
import { ensureSerializable } from './serialization';
import JSZip from 'jszip';

let vectorDatabase: any[] = [];
const agentFactory = AgentFactory.getInstance();
const azureService = AzureService.getInstance();

interface APIConfig {
  apiKey: string;
  endpoint?: string;
  deploymentName?: string;
}

export const setVectorDatabase = (data: any[]) => {
  vectorDatabase = ensureSerializable(data);
};

export const getVectorDatabase = () => {
  return vectorDatabase;
};

export const sendMessage = async (
  message: string, 
  pastMessages: Message[], 
  selectedLLM: string, 
  apiConfig: APIConfig,
  selectedAgent: Agent,
  uploadedFiles: File[]
): Promise<{ data: { message: string } }> => {
  try {
    if (!message.trim()) {
      throw new Error('Message cannot be empty');
    }

    // Process any uploaded files first
    if (uploadedFiles.length > 0) {
      try {
        const processedFiles = await processUploadedFiles(uploadedFiles);
        setVectorDatabase([...vectorDatabase, ...processedFiles]);
      } catch (error) {
        throw new Error(`Failed to process uploaded files: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    }

    // Get the appropriate agent
    const agent = agentFactory.getAgent(selectedAgent);
    if (!agent) {
      throw new Error(`Agent ${selectedAgent} not found`);
    }

    try {
      // Generate response using Azure service
      const response = await azureService.generateCompletion(message, selectedAgent);
      if (!response) {
        throw new Error('No response generated from the agent');
      }

      return { data: { message: response } };
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes('401')) {
          throw new Error('Invalid API key. Please check your credentials.');
        } else if (error.message.includes('429')) {
          throw new Error('Rate limit exceeded. Please try again later.');
        } else if (error.message.includes('500')) {
          throw new Error('Service is temporarily unavailable. Please try again later.');
        }
      }
      throw new Error(`Failed to generate response: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  } catch (error) {
    console.error('Error in sendMessage:', error);
    throw error;
  }
};

export const processUploadedFiles = async (files: File[]): Promise<any[]> => {
  const processedData: any[] = [];

  try {
    for (const file of files) {
      const id = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

      if (file.type === 'application/zip') {
        const zip = new JSZip();
        const zipContents = await zip.loadAsync(file);
        
        for (const [filename, zipEntry] of Object.entries(zipContents.files)) {
          if (!zipEntry.dir) {
            try {
              const content = await zipEntry.async('string');
              processedData.push({
                id: `${id}-${filename}`,
                content,
                metadata: {
                  filename,
                  type: zipEntry.comment || 'Unknown',
                  category: 'Imported',
                  lastModified: new Date()
                }
              });
            } catch (error) {
              throw new Error(`Failed to process zip entry ${filename}: ${error instanceof Error ? error.message : 'Unknown error'}`);
            }
          }
        }
      } else {
        try {
          const content = await file.text();
          processedData.push({
            id,
            content,
            metadata: {
              filename: file.name,
              type: file.type || 'text/plain',
              category: 'Imported',
              lastModified: new Date()
            }
          });
        } catch (error) {
          throw new Error(`Failed to process file ${file.name}: ${error instanceof Error ? error.message : 'Unknown error'}`);
        }
      }
    }

    return processedData;
  } catch (error) {
    console.error('Error processing files:', error);
    throw error;
  }
};