import { useCallback, useEffect, useRef } from 'react';

interface UseA11yOptions {
  onEscape?: () => void;
  onEnter?: () => void;
  onSpace?: () => void;
  onArrowKeys?: (direction: 'up' | 'down' | 'left' | 'right') => void;
  trapFocus?: boolean;
  autoFocus?: boolean;
}

export const useA11y = ({
  onEscape,
  onEnter,
  onSpace,
  onArrowKeys,
  trapFocus = false,
  autoFocus = false,
}: UseA11yOptions = {}) => {
  const elementRef = useRef<HTMLElement>(null);
  const previousFocusRef = useRef<HTMLElement | null>(null);

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        onEscape?.();
        break;
      case 'Enter':
        onEnter?.();
        break;
      case ' ':
        onSpace?.();
        break;
      case 'ArrowUp':
        onArrowKeys?.('up');
        break;
      case 'ArrowDown':
        onArrowKeys?.('down');
        break;
      case 'ArrowLeft':
        onArrowKeys?.('left');
        break;
      case 'ArrowRight':
        onArrowKeys?.('right');
        break;
    }
  }, [onEscape, onEnter, onSpace, onArrowKeys]);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    if (autoFocus) {
      previousFocusRef.current = document.activeElement as HTMLElement;
      element.focus();
    }

    element.addEventListener('keydown', handleKeyDown);

    return () => {
      element.removeEventListener('keydown', handleKeyDown);
      if (autoFocus && previousFocusRef.current) {
        previousFocusRef.current.focus();
      }
    };
  }, [handleKeyDown, autoFocus]);

  useEffect(() => {
    if (!trapFocus) return;

    const element = elementRef.current;
    if (!element) return;

    const handleFocusTrap = (e: KeyboardEvent) => {
      if (e.key !== 'Tab') return;

      const focusableElements = element.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );

      if (!focusableElements.length) return;

      const firstElement = focusableElements[0] as HTMLElement;
      const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;

      if (e.shiftKey) {
        if (document.activeElement === firstElement) {
          e.preventDefault();
          lastElement.focus();
        }
      } else {
        if (document.activeElement === lastElement) {
          e.preventDefault();
          firstElement.focus();
        }
      }
    };

    element.addEventListener('keydown', handleFocusTrap);
    return () => element.removeEventListener('keydown', handleFocusTrap);
  }, [trapFocus]);

  return { elementRef };
};

export default useA11y;