import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { BarChart2, FileText, Clock } from 'lucide-react';
import { ProcessedFile } from '../../utils/fileProcessing';

interface KnowledgeBaseAnalyticsProps {
  files: ProcessedFile[];
}

const KnowledgeBaseAnalytics: React.FC<KnowledgeBaseAnalyticsProps> = ({ files }) => {
  const fileTypes = files.reduce((acc: Record<string, number>, file) => {
    const type = file.type || 'unknown';
    acc[type] = (acc[type] || 0) + 1;
    return acc;
  }, {});

  const categories = files.reduce((acc: Record<string, number>, file) => {
    const category = file.metadata.category || 'uncategorized';
    acc[category] = (acc[category] || 0) + 1;
    return acc;
  }, {});

  const uploadsByDate = files.reduce((acc: Record<string, number>, file) => {
    const date = new Date(file.metadata.lastModified).toLocaleDateString();
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  const typeChartData = {
    labels: Object.keys(fileTypes),
    datasets: [{
      data: Object.values(fileTypes),
      backgroundColor: [
        'rgba(54, 162, 235, 0.6)',
        'rgba(75, 192, 192, 0.6)',
        'rgba(153, 102, 255, 0.6)',
        'rgba(255, 159, 64, 0.6)',
        'rgba(255, 99, 132, 0.6)',
      ],
    }],
  };

  const categoryChartData = {
    labels: Object.keys(categories),
    datasets: [{
      data: Object.values(categories),
      backgroundColor: [
        'rgba(255, 99, 132, 0.6)',
        'rgba(54, 162, 235, 0.6)',
        'rgba(255, 206, 86, 0.6)',
        'rgba(75, 192, 192, 0.6)',
        'rgba(153, 102, 255, 0.6)',
      ],
    }],
  };

  const uploadChartData = {
    labels: Object.keys(uploadsByDate),
    datasets: [{
      label: 'Files Uploaded',
      data: Object.values(uploadsByDate),
      backgroundColor: 'rgba(54, 162, 235, 0.6)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1,
    }],
  };

  return (
    <div className="space-y-6">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold mb-4 flex items-center">
          <FileText className="mr-2" size={20} />
          Knowledge Base Stats
        </h3>
        <div className="grid grid-cols-2 gap-4">
          <div className="text-center">
            <div className="text-3xl font-bold text-blue-500">{files.length}</div>
            <div className="text-sm text-gray-500">Total Files</div>
          </div>
          <div className="text-center">
            <div className="text-3xl font-bold text-green-500">
              {Object.keys(categories).length}
            </div>
            <div className="text-sm text-gray-500">Categories</div>
          </div>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold mb-4 flex items-center">
          <BarChart2 className="mr-2" size={20} />
          File Types Distribution
        </h3>
        <div className="h-64">
          <Pie data={typeChartData} options={{ maintainAspectRatio: false }} />
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold mb-4 flex items-center">
          <Clock className="mr-2" size={20} />
          Upload Activity
        </h3>
        <div className="h-64">
          <Bar 
            data={uploadChartData} 
            options={{
              maintainAspectRatio: false,
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    stepSize: 1
                  }
                }
              }
            }} 
          />
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold mb-4">Category Distribution</h3>
        <div className="h-64">
          <Pie data={categoryChartData} options={{ maintainAspectRatio: false }} />
        </div>
      </div>
    </div>
  );
};

export default KnowledgeBaseAnalytics;