import React, { useState } from 'react';
import { 
  Wrench, 
  Database, 
  FileText, 
  RefreshCw, 
  Download, 
  Upload, 
  CheckCircle, 
  AlertTriangle,
  Settings,
  Search,
  Save,
  Trash2,
  Play,
  Pause,
  X
} from 'lucide-react';

interface Tool {
  id: string;
  name: string;
  description: string;
  icon: React.ElementType;
  status: 'available' | 'maintenance' | 'deprecated';
}

const ToolsUtilities: React.FC = () => {
  const [selectedTool, setSelectedTool] = useState<string | null>(null);
  const [isRunning, setIsRunning] = useState(false);
  const [output, setOutput] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');

  const tools: Tool[] = [
    {
      id: 'database-cleanup',
      name: 'Database Cleanup',
      description: 'Clean and optimize database tables, remove unused data',
      icon: Database,
      status: 'available'
    },
    {
      id: 'log-analyzer',
      name: 'Log Analyzer',
      description: 'Analyze system logs for patterns and issues',
      icon: FileText,
      status: 'available'
    },
    {
      id: 'data-export',
      name: 'Data Export Tool',
      description: 'Export data in various formats (JSON, CSV, Excel)',
      icon: Download,
      status: 'available'
    },
    {
      id: 'system-diagnostics',
      name: 'System Diagnostics',
      description: 'Run comprehensive system health checks',
      icon: Settings,
      status: 'available'
    },
    {
      id: 'backup-restore',
      name: 'Backup & Restore',
      description: 'Manage system backups and restoration',
      icon: Save,
      status: 'maintenance'
    }
  ];

  const handleToolSelect = (toolId: string) => {
    setSelectedTool(toolId);
    setOutput('');
  };

  const handleRunTool = async () => {
    if (!selectedTool) return;

    setIsRunning(true);
    setOutput('');

    try {
      // Simulate tool execution
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      const tool = tools.find(t => t.id === selectedTool);
      if (tool) {
        setOutput(`Successfully executed ${tool.name}!\n\nResults:\n- Operation completed\n- No errors found\n- Performance metrics collected\n\nRecommendations:\n1. Schedule regular maintenance\n2. Monitor system performance\n3. Review logs periodically`);
      }
    } catch (error) {
      setOutput(`Error executing tool: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsRunning(false);
    }
  };

  const filteredTools = tools.filter(tool =>
    tool.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    tool.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getStatusColor = (status: Tool['status']) => {
    switch (status) {
      case 'available':
        return 'text-green-500';
      case 'maintenance':
        return 'text-yellow-500';
      case 'deprecated':
        return 'text-red-500';
      default:
        return 'text-gray-500';
    }
  };

  const getStatusIcon = (status: Tool['status']) => {
    switch (status) {
      case 'available':
        return CheckCircle;
      case 'maintenance':
        return RefreshCw;
      case 'deprecated':
        return AlertTriangle;
      default:
        return AlertTriangle;
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center">
          <Wrench className="mr-2 text-blue-500" size={24} />
          Tools & Utilities
        </h2>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            <input
              type="text"
              placeholder="Search tools..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredTools.map((tool) => {
          const StatusIcon = getStatusIcon(tool.status);
          return (
            <div
              key={tool.id}
              onClick={() => handleToolSelect(tool.id)}
              className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md cursor-pointer transition-all duration-200 hover:shadow-lg ${
                selectedTool === tool.id ? 'ring-2 ring-blue-500' : ''
              }`}
            >
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <tool.icon className="text-blue-500" size={24} />
                  <h3 className="ml-2 text-lg font-semibold">{tool.name}</h3>
                </div>
                <StatusIcon className={`${getStatusColor(tool.status)}`} size={20} />
              </div>
              <p className="text-gray-600 dark:text-gray-400 mb-4">{tool.description}</p>
              <div className="flex justify-between items-center">
                <span className={`text-sm ${getStatusColor(tool.status)} capitalize`}>
                  {tool.status}
                </span>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToolSelect(tool.id);
                  }}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200"
                >
                  Select Tool
                </button>
              </div>
            </div>
          )
        })}
      </div>

      {selectedTool && (
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Tool Execution Panel</h3>
            <div className="flex space-x-2">
              <button
                onClick={handleRunTool}
                disabled={isRunning}
                className={`px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors duration-200 flex items-center ${
                  isRunning ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isRunning ? (
                  <>
                    <RefreshCw className="animate-spin mr-2" size={20} />
                    Running...
                  </>
                ) : (
                  <>
                    <Play className="mr-2" size={20} />
                    Run Tool
                  </>
                )}
              </button>
              <button
                onClick={() => setSelectedTool(null)}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors duration-200 flex items-center"
              >
                <X className="mr-2" size={20} />
                Close
              </button>
            </div>
          </div>

          {output && (
            <div className="mt-4 bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
              <pre className="whitespace-pre-wrap text-sm text-gray-800 dark:text-gray-200">
                {output}
              </pre>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ToolsUtilities;