import { KnowledgeItem } from '../types';

const acronymList = {
  "acronyms": [
    {
      "acronym": "IPMDAR",
      "meaning": "Integrated Program Management Data and Analysis Report",
      "description": "A report containing data for measuring contract execution progress on DoD acquisition contracts."
    }
  ]
};

const modelPrompts = {
  "systemPrompt": "As Occam, an AI assistant based on the Proteus Envision user guide...",
  "chatParameters": {
    "deploymentName": "gpt-4o-mini",
    "maxResponseLength": 800,
    "temperature": 0.7,
    "topProbablities": 0.95,
    "stopSequences": [],
    "pastMessagesToInclude": 10,
    "frequencyPenalty": 0,
    "presencePenalty": 0
  }
};

const testDataset = {
  "tests": [
    {
      "name": "SPD Aligns to Schedule Performance Dataset",
      "instructions": "Check the SPD file against DEI and FFS for business rules",
      "metricType": "Boolean",
      "type": "Data Quality"
    }
  ]
};

const userFeedback = `User Feedback:
Date: 2023-06-15
Version: 1
...`;

export const getBaseKnowledge = (): KnowledgeItem[] => {
  return [
    {
      id: 'acronyms',
      name: 'OccamAcronymList.json',
      content: JSON.stringify(acronymList, null, 2),
      type: 'json',
      category: 'IPMDAR',
      lastModified: new Date()
    },
    {
      id: 'prompts',
      name: 'occam_model_prompts.json',
      content: JSON.stringify(modelPrompts, null, 2),
      type: 'json',
      category: 'Prompts',
      lastModified: new Date()
    },
    {
      id: 'test-dataset',
      name: 'occam_test_fulldataset.json',
      content: JSON.stringify(testDataset, null, 2),
      type: 'json',
      category: 'Testing',
      lastModified: new Date()
    },
    {
      id: 'user-feedback',
      name: 'UserFeedback.txt',
      content: userFeedback,
      type: 'text',
      category: 'Feedback',
      lastModified: new Date()
    }
  ];
};