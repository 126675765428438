import React, { useState, useEffect, useRef } from 'react';
import { FileText, Upload, Check, Archive, ChevronDown, ChevronUp } from 'lucide-react';
import FileAnalyzer from './analysis/FileAnalyzer';
import JSZip from 'jszip';
import { motion, AnimatePresence } from 'framer-motion';

interface FileAnalysisButtonProps {
  files: File[];
}

interface ZipContent {
  name: string;
  path: string;
  isFromZip: boolean;
  zipParent?: string;
  content?: string;
}

const FileAnalysisButton: React.FC<FileAnalysisButtonProps> = ({ files }) => {
  const [showAnalyzer, setShowAnalyzer] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Set<string>>(new Set());
  const [expandedZips, setExpandedZips] = useState<Set<string>>(new Set());
  const [fileList, setFileList] = useState<ZipContent[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    processFiles();
  }, [files]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const processFiles = async () => {
    setIsProcessing(true);
    const processedFiles: ZipContent[] = [];

    for (const file of files) {
      if (file.type === 'application/zip' || file.name.endsWith('.zip')) {
        try {
          const zip = new JSZip();
          const zipContents = await zip.loadAsync(file);
          
          // Add the zip file itself
          processedFiles.push({
            name: file.name,
            path: file.name,
            isFromZip: false
          });

          // Process zip contents
          for (const [filename, zipEntry] of Object.entries(zipContents.files)) {
            if (!zipEntry.dir) {
              const content = await zipEntry.async('string');
              processedFiles.push({
                name: filename,
                path: `${file.name}/${filename}`,
                isFromZip: true,
                zipParent: file.name,
                content
              });
            }
          }
        } catch (error) {
          console.error(`Error processing zip file ${file.name}:`, error);
        }
      } else {
        processedFiles.push({
          name: file.name,
          path: file.name,
          isFromZip: false
        });
      }
    }

    setFileList(processedFiles);
    setIsProcessing(false);
  };

  const toggleZipExpansion = (zipName: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setExpandedZips(prev => {
      const newSet = new Set(prev);
      if (newSet.has(zipName)) {
        newSet.delete(zipName);
      } else {
        newSet.add(zipName);
      }
      return newSet;
    });
  };

  const toggleFileSelection = (filePath: string) => {
    setSelectedFiles(prev => {
      const newSet = new Set(prev);
      if (newSet.has(filePath)) {
        newSet.delete(filePath);
      } else {
        newSet.add(filePath);
      }
      return newSet;
    });
  };

  const getSelectedFilesWithContent = () => {
    return fileList
      .filter(file => selectedFiles.has(file.path))
      .map(file => ({
        name: file.name,
        path: file.path,
        content: file.content,
        isFromZip: file.isFromZip,
        zipParent: file.zipParent
      }));
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setShowDropdown(!showDropdown)}
        className="flex items-center space-x-2 px-4 py-2 bg-blue-500 text-white rounded-lg transition-colors duration-200 hover:bg-blue-600"
      >
        <FileText size={20} />
        <span>Select Files to Analyze ({selectedFiles.size})</span>
        {showDropdown ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </button>

      <AnimatePresence>
        {showDropdown && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute top-full right-0 mt-2 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 min-w-[400px] max-h-[600px] overflow-y-auto z-50"
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-sm font-semibold">Select Files to Analyze</h3>
              {isProcessing && (
                <span className="text-blue-500 text-sm">Processing...</span>
              )}
            </div>

            <div className="space-y-2">
              {fileList.map((file) => {
                if (file.isFromZip && !expandedZips.has(file.zipParent!)) {
                  return null;
                }

                return (
                  <div
                    key={file.path}
                    className={`${file.isFromZip ? 'ml-6' : ''}`}
                  >
                    <div className="flex items-center space-x-2 p-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded">
                      {!file.isFromZip && file.name.endsWith('.zip') ? (
                        <button
                          onClick={(e) => toggleZipExpansion(file.name, e)}
                          className="p-1 hover:bg-gray-200 dark:hover:bg-gray-600 rounded"
                        >
                          {expandedZips.has(file.name) ? (
                            <ChevronUp size={16} />
                          ) : (
                            <ChevronDown size={16} />
                          )}
                        </button>
                      ) : (
                        <input
                          type="checkbox"
                          checked={selectedFiles.has(file.path)}
                          onChange={() => toggleFileSelection(file.path)}
                          className="form-checkbox h-4 w-4 text-blue-500"
                        />
                      )}
                      <span className="flex items-center space-x-2 flex-1">
                        {file.isFromZip ? (
                          <FileText size={16} className="text-gray-400" />
                        ) : (
                          <Archive size={16} className="text-blue-500" />
                        )}
                        <span className="truncate">{file.name}</span>
                      </span>
                      {selectedFiles.has(file.path) && (
                        <Check size={16} className="text-blue-500" />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            {selectedFiles.size > 0 && (
              <div className="mt-4 pt-4 border-t dark:border-gray-700">
                <button
                  onClick={() => {
                    setShowDropdown(false);
                    setShowAnalyzer(true);
                  }}
                  className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200"
                >
                  Analyze Selected Files ({selectedFiles.size})
                </button>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      {showAnalyzer && (
        <FileAnalyzer
          files={getSelectedFilesWithContent()}
          onClose={() => setShowAnalyzer(false)}
        />
      )}
    </div>
  );
};

export default FileAnalysisButton;