import React from 'react';
import { AlertTriangle, CheckCircle, AlertOctagon } from 'lucide-react';

interface Discrepancy {
  id: string;
  lineNumber: number;
  description: string;
  severity: 'High' | 'Medium' | 'Low';
  impact: string;
  recommendation: string;
  location: string;
  code: string;
}

interface ContractMetadata {
  contractNumber: string;
  contractor: string;
  source: string;
  submissionDate: string;
}

interface QualityReportProps {
  discrepancies: Discrepancy[];
  contractMetadata: ContractMetadata;
  onSelectDiscrepancy: (discrepancy: Discrepancy) => void;
  selectedDiscrepancy: Discrepancy | null;
}

const QualityReport: React.FC<QualityReportProps> = ({
  discrepancies,
  contractMetadata,
  onSelectDiscrepancy,
  selectedDiscrepancy
}) => {
  const getSeverityIcon = (severity: string) => {
    switch (severity) {
      case 'High':
        return <AlertOctagon className="text-red-500" size={20} />;
      case 'Medium':
        return <AlertTriangle className="text-yellow-500" size={20} />;
      case 'Low':
        return <CheckCircle className="text-green-500" size={20} />;
      default:
        return null;
    }
  };

  const getSeverityClass = (severity: string) => {
    switch (severity) {
      case 'High':
        return 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200';
      case 'Medium':
        return 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200';
      case 'Low':
        return 'bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200';
      default:
        return '';
    }
  };

  return (
    <div className="space-y-6">
      {/* Contract Metadata */}
      <div className="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Contract Information</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400">Contract Number</p>
            <p className="font-medium">{contractMetadata.contractNumber}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400">Contractor</p>
            <p className="font-medium">{contractMetadata.contractor}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400">Source</p>
            <p className="font-medium">{contractMetadata.source}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400">Submission Date</p>
            <p className="font-medium">{contractMetadata.submissionDate}</p>
          </div>
        </div>
      </div>

      {/* Severity Legend */}
      <div className="flex space-x-4 mb-4">
        <div className="flex items-center">
          <AlertOctagon className="text-red-500 mr-1" size={16} />
          <span className="text-sm">High</span>
        </div>
        <div className="flex items-center">
          <AlertTriangle className="text-yellow-500 mr-1" size={16} />
          <span className="text-sm">Medium</span>
        </div>
        <div className="flex items-center">
          <CheckCircle className="text-green-500 mr-1" size={16} />
          <span className="text-sm">Low</span>
        </div>
      </div>

      {/* Discrepancies List */}
      <div className="space-y-4">
        {discrepancies.map((discrepancy) => (
          <div
            key={discrepancy.id}
            onClick={() => onSelectDiscrepancy(discrepancy)}
            className={`p-4 rounded-lg cursor-pointer transition-all duration-200 ${
              selectedDiscrepancy?.id === discrepancy.id
                ? 'ring-2 ring-blue-500'
                : 'hover:bg-gray-50 dark:hover:bg-gray-700'
            }`}
          >
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 mt-1">
                {getSeverityIcon(discrepancy.severity)}
              </div>
              <div className="flex-1">
                <div className="flex items-center justify-between">
                  <h4 className="font-semibold">{discrepancy.location}</h4>
                  <span className={`px-2 py-1 rounded-full text-xs ${getSeverityClass(discrepancy.severity)}`}>
                    {discrepancy.severity}
                  </span>
                </div>
                <p className="text-sm mt-1">Line {discrepancy.lineNumber}</p>
                <p className="mt-2">{discrepancy.description}</p>
                <div className="mt-2 space-y-2">
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    <strong>Impact:</strong> {discrepancy.impact}
                  </p>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    <strong>Recommendation:</strong> {discrepancy.recommendation}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QualityReport;