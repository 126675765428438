import { useState, useCallback, useEffect } from 'react';
import { Agent } from '../types';
import SpeechSynthesisService from '../services/SpeechSynthesisService';
import SpeechRecognitionService from '../services/SpeechRecognitionService';

const useVoice = () => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const speechSynthesis = SpeechSynthesisService.getInstance();
  const speechRecognition = SpeechRecognitionService.getInstance();

  useEffect(() => {
    return () => {
      speechSynthesis.cleanup();
      speechRecognition.cleanup();
    };
  }, []);

  const speak = useCallback(async (text: string, agent: Agent = 'Occam') => {
    try {
      if (isSpeaking) {
        return; // Prevent multiple simultaneous speech attempts
      }
      setError(null);
      setIsSpeaking(true);
      await speechSynthesis.speak(text, agent);
      setIsSpeaking(false);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Speech synthesis failed');
      setIsSpeaking(false);
    }
  }, [isSpeaking]);

  const startListening = useCallback(async (): Promise<string> => {
    try {
      if (isListening) {
        throw new Error('Already listening');
      }
      setError(null);
      setIsListening(true);
      const text = await speechRecognition.startListening();
      return text;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Speech recognition failed';
      setError(errorMessage);
      throw err;
    } finally {
      setIsListening(false);
    }
  }, [isListening]);

  const stopSpeaking = useCallback(() => {
    speechSynthesis.stop();
    setIsSpeaking(false);
    setIsPaused(false);
    setError(null);
  }, []);

  const stopListening = useCallback(() => {
    speechRecognition.stop();
    setIsListening(false);
    setError(null);
  }, []);

  const pauseSpeaking = useCallback(() => {
    if (isSpeaking) {
      speechSynthesis.pause();
      setIsPaused(true);
    }
  }, [isSpeaking]);

  const resumeSpeaking = useCallback(() => {
    if (isPaused) {
      speechSynthesis.resume();
      setIsPaused(false);
    }
  }, [isPaused]);

  return {
    isSpeaking,
    isListening,
    isPaused,
    error,
    speak,
    startListening,
    stopSpeaking,
    stopListening,
    pauseSpeaking,
    resumeSpeaking
  };
};

export default useVoice;