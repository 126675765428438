import React, { useState } from 'react';
import { Brain, Database, Book } from 'lucide-react';
import PretrainedDocsViewer from './PretrainedDocsViewer';

interface KnowledgeBaseHeaderProps {
  activeSection: 'knowledge' | 'agent';
  setActiveSection: (section: 'knowledge' | 'agent') => void;
}

const KnowledgeBaseHeader: React.FC<KnowledgeBaseHeaderProps> = ({
  activeSection,
  setActiveSection,
}) => {
  const [showPretrainedDocs, setShowPretrainedDocs] = useState(false);

  return (
    <div className="flex justify-between items-center">
      <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center">
        <Brain className="mr-2 text-blue-500" size={24} />
        Unified Knowledge Management
      </h2>
      <div className="flex space-x-2">
        <button
          onClick={() => setShowPretrainedDocs(true)}
          className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors duration-200 flex items-center"
        >
          <Book className="mr-2" size={20} />
          Pre-trained Documents
        </button>
        <button
          onClick={() => setActiveSection('knowledge')}
          className={`px-4 py-2 rounded-lg flex items-center ${
            activeSection === 'knowledge'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
          }`}
        >
          <Database className="mr-2" size={20} />
          Knowledge Base
        </button>
        <button
          onClick={() => setActiveSection('agent')}
          className={`px-4 py-2 rounded-lg flex items-center ${
            activeSection === 'agent'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
          }`}
        >
          <Brain className="mr-2" size={20} />
          AI Agents
        </button>
      </div>

      {showPretrainedDocs && (
        <PretrainedDocsViewer onClose={() => setShowPretrainedDocs(false)} />
      )}
    </div>
  );
};

export default KnowledgeBaseHeader;