import React, { useState, useEffect } from 'react';
import { Mic, VolumeX, Volume2, AlertCircle, Pause, Play, Square } from 'lucide-react';
import useVoice from '../hooks/useVoice';
import { Agent } from '../types';

interface VoiceControlsProps {
  onSpeechResult: (text: string) => void;
  messageToSpeak?: string;
  disabled?: boolean;
  agent?: Agent;
}

const VoiceControls: React.FC<VoiceControlsProps> = ({ 
  onSpeechResult, 
  messageToSpeak,
  disabled = false,
  agent = 'Occam'
}) => {
  const { 
    isSpeaking, 
    isListening, 
    isPaused,
    error, 
    speak, 
    startListening, 
    stopSpeaking, 
    stopListening,
    pauseSpeaking,
    resumeSpeaking
  } = useVoice();
  const [showError, setShowError] = useState(false);
  const [lastSpokenMessage, setLastSpokenMessage] = useState<string>('');

  useEffect(() => {
    if (messageToSpeak && 
        !isSpeaking && 
        !isPaused && 
        agent === 'Occam' && 
        messageToSpeak !== lastSpokenMessage) {
      speak(messageToSpeak, agent);
      setLastSpokenMessage(messageToSpeak);
    }
  }, [messageToSpeak, speak, isSpeaking, isPaused, agent, lastSpokenMessage]);

  useEffect(() => {
    if (error) {
      setShowError(true);
      const timer = setTimeout(() => setShowError(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  // Only show voice controls for Occam
  if (agent !== 'Occam') {
    return null;
  }

  const handleListeningClick = async () => {
    if (isListening) {
      stopListening();
    } else {
      try {
        const text = await startListening();
        if (text) {
          onSpeechResult(text);
        }
      } catch (error) {
        console.error('Speech recognition error:', error);
      }
    }
  };

  const handleSpeakingClick = () => {
    if (isSpeaking) {
      if (isPaused) {
        resumeSpeaking();
      } else {
        pauseSpeaking();
      }
    } else if (messageToSpeak && messageToSpeak !== lastSpokenMessage) {
      speak(messageToSpeak, agent);
      setLastSpokenMessage(messageToSpeak);
    }
  };

  const handleStopClick = () => {
    stopSpeaking();
    setLastSpokenMessage('');
  };

  return (
    <div className="relative">
      <div className="flex space-x-2">
        <button
          onClick={handleListeningClick}
          disabled={disabled}
          className={`p-2 rounded-lg transition-colors duration-200 ${
            isListening
              ? 'bg-green-500 text-white'
              : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
          } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
          title={isListening ? 'Stop listening' : 'Start listening'}
        >
          <Mic size={24} className={isListening ? 'animate-pulse' : ''} />
        </button>

        {isSpeaking && (
          <button
            onClick={handleStopClick}
            className="p-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-200"
            title="Stop speaking"
          >
            <Square size={24} />
          </button>
        )}

        <button
          onClick={handleSpeakingClick}
          disabled={disabled || !messageToSpeak}
          className={`p-2 rounded-lg transition-colors duration-200 ${
            isSpeaking
              ? 'bg-green-500 text-white'
              : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
          } ${disabled || !messageToSpeak ? 'opacity-50 cursor-not-allowed' : ''}`}
          title={isSpeaking ? (isPaused ? 'Resume speaking' : 'Pause speaking') : 'Start speaking'}
        >
          {isSpeaking ? (
            isPaused ? <Play size={24} /> : <Pause size={24} />
          ) : (
            <Volume2 size={24} />
          )}
        </button>
      </div>

      {showError && (
        <div className="absolute bottom-full left-0 mb-2 w-64 bg-red-900/50 text-red-200 p-2 rounded-lg shadow-lg flex items-center backdrop-blur-sm border border-red-700">
          <AlertCircle size={16} className="mr-2 flex-shrink-0" />
          <span className="text-sm">{error}</span>
        </div>
      )}
    </div>
  );
};

export default VoiceControls;