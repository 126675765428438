import { Agent } from '../types';

interface IPMDARMetrics {
  cpi: number;
  spi: number;
  bac: number;
  eac: number;
}

interface ValidationResult {
  isConsistent: boolean;
  details: string[];
  alternativeCases: Array<{
    inputs: IPMDARMetrics;
    output: number;
    isValid: boolean;
  }>;
}

export class IPMDARValidatorService {
  private static instance: IPMDARValidatorService;

  private constructor() {}

  public static getInstance(): IPMDARValidatorService {
    if (!IPMDARValidatorService.instance) {
      IPMDARValidatorService.instance = new IPMDARValidatorService();
    }
    return IPMDARValidatorService.instance;
  }

  private generateRandomNumber(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  private generateAlternativeInputs(paramRanges: Record<string, [number, number]>, numCases: number = 5): IPMDARMetrics[] {
    const alternativeInputs: IPMDARMetrics[] = [];
    
    for (let i = 0; i < numCases; i++) {
      alternativeInputs.push({
        cpi: this.generateRandomNumber(paramRanges.cpi[0], paramRanges.cpi[1]),
        spi: this.generateRandomNumber(paramRanges.spi[0], paramRanges.spi[1]),
        bac: this.generateRandomNumber(paramRanges.bac[0], paramRanges.bac[1]),
        eac: this.generateRandomNumber(paramRanges.eac[0], paramRanges.eac[1])
      });
    }

    return alternativeInputs;
  }

  private calculateVAC(metrics: IPMDARMetrics): number {
    return metrics.bac - metrics.eac;
  }

  public validateReasoning(agent: Agent, initialCase: IPMDARMetrics): ValidationResult {
    const paramRanges = {
      cpi: [0.8, 1.2],
      spi: [0.8, 1.2],
      bac: [100000, 500000],
      eac: [80000, 450000]
    };

    const goldOutput = this.calculateVAC(initialCase);
    const alternativeCases = this.generateAlternativeInputs(paramRanges);
    const details: string[] = [];
    let isConsistent = true;

    const results = alternativeCases.map(inputs => {
      const output = this.calculateVAC(inputs);
      const isValid = Math.abs(output - goldOutput) < 0.01;
      
      if (!isValid) {
        isConsistent = false;
        details.push(`Inconsistency detected for ${agent}: Expected VAC close to ${goldOutput}, got ${output}`);
      }

      return {
        inputs,
        output,
        isValid
      };
    });

    if (isConsistent) {
      details.push(`${agent} demonstrated consistent reasoning across all test cases`);
    }

    return {
      isConsistent,
      details,
      alternativeCases: results
    };
  }

  public validateAgentResponse(agent: Agent, response: string): boolean {
    // Implement validation logic for agent responses
    const keyMetrics = ['CPI', 'SPI', 'BAC', 'EAC', 'VAC'];
    const mentionsKeyMetrics = keyMetrics.some(metric => response.includes(metric));
    
    // Check for numerical consistency
    const numbers = response.match(/\d+(\.\d+)?/g);
    const hasNumbers = numbers !== null && numbers.length > 0;
    
    // Check for logical flow
    const hasLogicalConnectors = /therefore|because|as a result|consequently/i.test(response);
    
    return mentionsKeyMetrics && hasNumbers && hasLogicalConnectors;
  }
}

export default IPMDARValidatorService;