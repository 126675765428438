import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Brain, GitBranch, Check, AlertTriangle, Scale, Lightbulb, ArrowRight, Download, ChevronDown, ChevronUp, Eye, BarChart2, Zap } from 'lucide-react';
import { Player } from '@lottiefiles/react-lottie-player';
import ComplexityMetrics from './ComplexityMetrics';
import SolutionComparison from './SolutionComparison';
import VisualizationPanel from './VisualizationPanel';

interface AnalysisStep {
  id: string;
  description: string;
  complexity: number;
  assumptions: string[];
  confidence: number;
  alternatives: Array<{
    description: string;
    score: number;
  }>;
}

interface Solution {
  id: string;
  description: string;
  complexityScore: number;
  assumptionCount: number;
  simplificationPotential: number;
  confidenceRating: number;
  feasibility: number;
  resourceRequirements: {
    time: number;
    cost: number;
    personnel: number;
  };
}

const getAnalysisSteps = (): AnalysisStep[] => [
  {
    id: 'initial',
    description: 'Analyzing initial problem state',
    complexity: 8,
    assumptions: ['Complete data available', 'System is stable'],
    confidence: 0.7,
    alternatives: [
      { description: 'Full analysis path', score: 0.8 },
      { description: 'Quick assessment', score: 0.6 }
    ]
  },
  {
    id: 'simplification',
    description: 'Identifying core components',
    complexity: 6,
    assumptions: ['Components are independent', 'Linear relationships'],
    confidence: 0.8,
    alternatives: [
      { description: 'Component isolation', score: 0.9 },
      { description: 'Holistic approach', score: 0.7 }
    ]
  },
  {
    id: 'optimization',
    description: 'Optimizing solution paths',
    complexity: 4,
    assumptions: ['Optimization is possible', 'Resources available'],
    confidence: 0.9,
    alternatives: [
      { description: 'Incremental improvement', score: 0.85 },
      { description: 'Complete redesign', score: 0.75 }
    ]
  }
];

const OccamAnalysis: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const analysisSteps = getAnalysisSteps();
  const [expandedSections, setExpandedSections] = useState<string[]>([]);
  const [solutions, setSolutions] = useState<Solution[]>([]);
  const [isAnalyzing, setIsAnalyzing] = useState(true);
  const [selectedSolution, setSelectedSolution] = useState<string | null>(null);
  const [visualizationData, setVisualizationData] = useState({
    nodes: [],
    links: []
  });
  const [complexityScore, setComplexityScore] = useState(0);
  const [assumptionCount, setAssumptionCount] = useState(0);
  const [simplificationPotential, setSimplificationPotential] = useState(0);
  const [confidenceRating, setConfidenceRating] = useState(0);
  const [resourceRequirements, setResourceRequirements] = useState({
    time: 0,
    cost: 0,
    personnel: 0
  });

  useEffect(() => {
    // Simulate analysis process
    const timer = setInterval(() => {
      if (currentStep < analysisSteps.length - 1) {
        setCurrentStep(prev => prev + 1);
        updateMetrics(prev + 1);
      } else {
        setIsAnalyzing(false);
        clearInterval(timer);
      }
    }, 2000);

    return () => clearInterval(timer);
  }, [currentStep]);

  const updateMetrics = (step: number) => {
    const currentStepData = analysisSteps[step];
    if (currentStepData) {
      setComplexityScore(currentStepData.complexity);
      setAssumptionCount(currentStepData.assumptions.length);
      setSimplificationPotential(Math.floor(Math.random() * 40) + 60); // 60-100
      setConfidenceRating(currentStepData.confidence);
      setResourceRequirements({
        time: Math.floor(Math.random() * 20) + 10,
        cost: Math.floor(Math.random() * 50000) + 10000,
        personnel: Math.floor(Math.random() * 5) + 2
      });
    }
  };
  useEffect(() => {
    if (analysisSteps) {
      const nodes = analysisSteps.map((step, index) => ({
        id: step.id,
        label: step.description,
        type: index === 0 ? 'problem' : index === analysisSteps.length - 1 ? 'solution' : 'step',
        status: index < currentStep ? 'completed' : index === currentStep ? 'active' : 'pending'
      }));

      const links = analysisSteps.slice(0, -1).map((step, index) => ({
        source: step.id,
        target: analysisSteps[index + 1].id,
        value: 1
      }));

      setVisualizationData({ nodes, links });
    }
  }, [currentStep]);

  const toggleSection = (sectionId: string) => {
    setExpandedSections(prev =>
      prev.includes(sectionId)
        ? prev.filter(id => id !== sectionId)
        : [...prev, sectionId]
    );
  };

  const getComplexityColor = (score: number) => {
    if (score <= 3) return 'text-green-500';
    if (score <= 6) return 'text-yellow-500';
    return 'text-red-500';
  };

  const exportAnalysis = () => {
    const analysisData = {
      steps: analysisSteps,
      solutions,
      timestamp: new Date().toISOString()
    };

    const blob = new Blob([JSON.stringify(analysisData, null, 2)], {
      type: 'application/json'
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `occam-analysis-${Date.now()}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center">
          <Brain className="mr-2 text-blue-500" size={24} />
          Occam's Razor Analysis
        </h2>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2 text-sm">
            <Zap className="text-yellow-500" size={16} />
            <span>Processing Power:</span>
            <span className="font-medium">{isAnalyzing ? '100%' : '25%'}</span>
          </div>
          <button
            onClick={exportAnalysis}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200 flex items-center"
          >
            <Download size={20} className="mr-2" />
            Export Analysis
          </button>
        </div>
      </div>

      {/* Analysis Progress */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-lg font-semibold mb-4 flex items-center">
          <GitBranch className="mr-2 text-blue-500" size={20} />
          Occam's Analysis Progress
        </h3>
        <div className="mb-6">
          <Player
            autoplay
            loop
            src="https://lottie.host/embed/thinking-brain/thinking-brain.json"
            style={{ height: '200px', width: '200px' }}
          />
        </div>
        <div className="space-y-4">
          {analysisSteps.map((step, index) => (
            <motion.div
              key={step.id}
              initial={{ opacity: 0, x: -20 }}
              animate={{ 
                opacity: index <= currentStep ? 1 : 0.5, 
                x: 0 
              }}
              className={`p-4 rounded-lg ${
                index <= currentStep 
                  ? 'bg-blue-50 dark:bg-blue-900/30' 
                  : 'bg-gray-50 dark:bg-gray-700/30'
              }`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {index < currentStep && (
                    <Check className="text-green-500 mr-2" size={20} />
                  )}
                  {index === currentStep && (
                    <motion.div
                      animate={{ rotate: 360 }}
                      transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                    >
                      <Brain className="text-blue-500 mr-2" size={20} />
                    </motion.div>
                  )}
                  <span className="font-medium">{step.description}</span>
                </div>
                <div className="flex items-center space-x-4">
                  <span className={`font-medium ${getComplexityColor(step.complexity)}`}>
                    Complexity: {step.complexity}
                  </span>
                  <button
                    onClick={() => toggleSection(step.id)}
                    className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                  >
                    {expandedSections.includes(step.id) ? (
                      <ChevronUp size={20} />
                    ) : (
                      <ChevronDown size={20} />
                    )}
                  </button>
                </div>
              </div>

              <AnimatePresence>
                {expandedSections.includes(step.id) && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    className="mt-4 space-y-4"
                  >
                    <div className="grid grid-cols-2 gap-4">
                      <div className="bg-white dark:bg-gray-700 p-4 rounded-lg">
                        <h4 className="font-medium mb-2">Assumptions</h4>
                        <ul className="list-disc list-inside space-y-1">
                          {step.assumptions.map((assumption, i) => (
                            <li key={i} className="text-sm text-gray-600 dark:text-gray-300">
                              {assumption}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="bg-white dark:bg-gray-700 p-4 rounded-lg">
                        <h4 className="font-medium mb-2">Alternative Approaches</h4>
                        <div className="space-y-2">
                          {step.alternatives.map((alt, i) => (
                            <div key={i} className="flex justify-between items-center">
                              <span className="text-sm text-gray-600 dark:text-gray-300">
                                {alt.description}
                              </span>
                              <span className="text-sm font-medium">
                                Score: {(alt.score * 100).toFixed(0)}%
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg">
                      <div className="flex items-center justify-between mb-2">
                        <span className="font-medium">Confidence Rating</span>
                        <span className="text-blue-600 dark:text-blue-400">
                          {(step.confidence * 100).toFixed(0)}%
                        </span>
                      </div>
                      <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                        <div
                          className="bg-blue-500 h-2 rounded-full transition-all duration-500"
                          style={{ width: `${step.confidence * 100}%` }}
                        />
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Visualization Panel */}
      <VisualizationPanel
        nodes={visualizationData.nodes}
        links={visualizationData.links}
        width={800}
        height={400}
      />

      {/* Complexity Metrics */}
      <ComplexityMetrics
        score={analysisSteps[currentStep]?.complexity || 0}
        assumptions={analysisSteps[currentStep]?.assumptions.length || 0}
        simplificationPotential={75}
        warning={
          analysisSteps[currentStep]?.complexity > 7 
            ? "High complexity detected. Consider further simplification."
            : undefined
        }
      />

      {/* Solution Comparison */}
      <SolutionComparison
        solutions={[
          {
            id: '1',
            name: 'Optimized Solution A',
            complexity: 4,
            feasibility: 8,
            resourceCost: 6,
            timeToImplement: 14,
            risks: ['Initial learning curve', 'Integration complexity'],
            benefits: ['High performance', 'Scalable architecture']
          },
          {
            id: '2',
            name: 'Alternative Solution B',
            complexity: 6,
            feasibility: 9,
            resourceCost: 4,
            timeToImplement: 7,
            risks: ['Limited scalability', 'Technical debt'],
            benefits: ['Quick implementation', 'Low resource usage']
          }
        ]}
        onSelect={(id) => console.log('Selected solution:', id)}
      />

      {/* Analysis Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-4 flex items-center">
            <Scale className="mr-2 text-blue-500" size={20} />
            Complexity Analysis
          </h3>
          <div className="space-y-4">
            {analysisSteps.map((step, index) => (
              <div key={index} className="flex items-center justify-between">
                <span className="text-sm text-gray-600 dark:text-gray-400">
                  {step.description}
                </span>
                <span className={`font-medium ${getComplexityColor(step.complexity)}`}>
                  {step.complexity}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-4 flex items-center">
            <Lightbulb className="mr-2 text-blue-500" size={20} />
            Solution Insights
          </h3>
          <div className="space-y-4">
            {analysisSteps.map((step, index) => (
              <div key={index} className="flex items-center justify-between">
                <span className="text-sm text-gray-600 dark:text-gray-400">
                  Alternative Solutions
                </span>
                <span className="font-medium">
                  {step.alternatives.length}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-4 flex items-center">
            <BarChart2 className="mr-2 text-blue-500" size={20} />
            Performance Metrics
          </h3>
          <div className="space-y-4">
            {analysisSteps.map((step, index) => (
              <div key={index}>
                <div className="flex items-center justify-between mb-1">
                  <span className="text-sm text-gray-600 dark:text-gray-400">
                    Confidence
                  </span>
                  <span className="font-medium">
                    {(step.confidence * 100).toFixed(0)}%
                  </span>
                </div>
                <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                  <div
                    className="bg-blue-500 h-2 rounded-full transition-all duration-500"
                    style={{ width: `${step.confidence * 100}%` }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {!isAnalyzing && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-green-100 dark:bg-green-900/30 p-6 rounded-lg shadow-lg"
        >
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold text-green-800 dark:text-green-200 flex items-center">
              <Check className="mr-2" size={20} />
              Analysis Complete
            </h3>
            <span className="text-sm text-green-600 dark:text-green-400">
              Total Steps: {analysisSteps.length}
            </span>
          </div>
          <p className="text-green-700 dark:text-green-300">
            All analysis steps have been completed. You can now review the results and export the analysis.
          </p>
        </motion.div>
      )}
    </div>
  );
};

export default OccamAnalysis;