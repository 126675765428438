import React from 'react';
import { FileText } from 'lucide-react';
import ReactPaginate from 'react-paginate';

interface Document {
  name: string;
  type: string;
  createdAt: Date;
  summary: string;
}

interface DocumentListProps {
  documents: Document[];
  currentPage: number;
  onPageChange: (page: number) => void;
  onDocumentSelect: (index: number) => void;
  selectedDocument: number;
}

const ITEMS_PER_PAGE = 10;

const DocumentList: React.FC<DocumentListProps> = ({
  documents,
  currentPage,
  onPageChange,
  onDocumentSelect,
  selectedDocument,
}) => {
  const pageCount = Math.ceil(documents.length / ITEMS_PER_PAGE);
  const offset = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentDocuments = documents.slice(offset, offset + ITEMS_PER_PAGE);

  return (
    <div className="space-y-4">
      <div className="overflow-y-auto max-h-[calc(90vh-12rem)]">
        {currentDocuments.map((doc, index) => {
          const actualIndex = offset + index;
          return (
            <button
              key={doc.name}
              onClick={() => onDocumentSelect(actualIndex)}
              className={`w-full text-left p-3 rounded-lg mb-2 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200 ${
                selectedDocument === actualIndex
                  ? 'bg-blue-50 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
                  : ''
              }`}
            >
              <div className="flex items-center space-x-3">
                <FileText size={20} className="flex-shrink-0" />
                <div className="flex-1 min-w-0">
                  <p className="font-medium truncate">{doc.name}</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                    {doc.summary}
                  </p>
                  <p className="text-xs text-gray-400 dark:text-gray-500">
                    {doc.createdAt.toLocaleDateString()}
                  </p>
                </div>
              </div>
            </button>
          );
        })}
      </div>

      {pageCount > 1 && (
        <ReactPaginate
          previousLabel="←"
          nextLabel="→"
          pageCount={pageCount}
          onPageChange={({ selected }) => onPageChange(selected + 1)}
          containerClassName="flex items-center justify-center space-x-2"
          pageClassName="px-3 py-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700"
          previousClassName="px-3 py-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700"
          nextClassName="px-3 py-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700"
          activeClassName="bg-blue-500 text-white hover:bg-blue-600"
          disabledClassName="opacity-50 cursor-not-allowed"
        />
      )}
    </div>
  );
};

export default DocumentList;