import React, { useState, useEffect, useRef } from 'react';
import { Send, Download, Bot, AlertCircle, List, Clock } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface Discrepancy {
  id: string;
  lineNumber: number;
  description: string;
  severity: 'High' | 'Medium' | 'Low';
  impact: string;
  recommendation: string;
  location: string;
  code: string;
}

interface Message {
  id: string;
  text: string;
  sender: 'user' | 'occam';
  timestamp: Date;
}

interface OccamChatProps {
  discrepancy: Discrepancy | null;
  onDownloadChat: () => void;
}

const OccamChat: React.FC<OccamChatProps> = ({ discrepancy, onDownloadChat }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [showQuestions, setShowQuestions] = useState(false);
  const [nextQuestionTime, setNextQuestionTime] = useState<Date | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const questionTimerRef = useRef<NodeJS.Timeout>();

  const predefinedQuestions = [
    'Can you explain the impact of this discrepancy in more detail?',
    'What are the potential risks if this issue is not addressed?',
    'Are there similar discrepancies in other parts of the data?',
    'What is the recommended timeline for addressing this issue?',
    'How does this affect our IPMDAR compliance?'
  ];

  useEffect(() => {
    if (discrepancy) {
      const initialMessage: Message = {
        id: Date.now().toString(),
        text: `I've analyzed the discrepancy in ${discrepancy.location}. Would you like me to explain the impact and potential solutions?`,
        sender: 'occam',
        timestamp: new Date()
      };
      setMessages([initialMessage]);
      scheduleNextQuestion();
    }

    return () => {
      if (questionTimerRef.current) {
        clearTimeout(questionTimerRef.current);
      }
    };
  }, [discrepancy]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scheduleNextQuestion = () => {
    const nextTime = new Date();
    nextTime.setMinutes(nextTime.getMinutes() + 5);
    setNextQuestionTime(nextTime);

    if (questionTimerRef.current) {
      clearTimeout(questionTimerRef.current);
    }

    questionTimerRef.current = setTimeout(() => {
      addProactiveQuestion();
      scheduleNextQuestion();
    }, 5 * 60 * 1000); // 5 minutes
  };

  const addProactiveQuestion = () => {
    const questions = [
      'Would you like me to provide more context about this type of discrepancy?',
      'Should we review similar issues in other parts of the data?',
      'Would you like to see relevant IPMDAR guidelines for this case?',
      'Shall we discuss potential prevention strategies for the future?'
    ];
    
    const randomQuestion = questions[Math.floor(Math.random() * questions.length)];
    
    addMessage(randomQuestion, 'occam');
  };

  const addMessage = (text: string, sender: 'user' | 'occam') => {
    const newMessage: Message = {
      id: Date.now().toString(),
      text,
      sender,
      timestamp: new Date()
    };
    setMessages(prev => [...prev, newMessage]);
    scrollToBottom();
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;

    addMessage(inputMessage, 'user');
    setInputMessage('');
    setIsTyping(true);

    // Simulate Occam's response
    setTimeout(() => {
      const response = `I understand your question about "${inputMessage}". Based on the IPMDAR requirements...`;
      addMessage(response, 'occam');
      setIsTyping(false);
    }, 1500);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleQuestionSelect = (question: string) => {
    setInputMessage(question);
    setShowQuestions(false);
  };

  return (
    <div className="mt-6 border-t dark:border-gray-700 pt-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <Bot className="mr-2 text-blue-500" size={20} />
          Discussion with Occam
        </h3>
        <div className="flex items-center space-x-4">
          {nextQuestionTime && (
            <div className="flex items-center text-sm text-gray-500">
              <Clock size={16} className="mr-1" />
              Next question in {Math.max(0, Math.floor((nextQuestionTime.getTime() - Date.now()) / 1000 / 60))}m
            </div>
          )}
          <button
            onClick={onDownloadChat}
            className="text-blue-500 hover:text-blue-600"
          >
            <Download size={20} />
          </button>
        </div>
      </div>

      <div className="h-80 overflow-y-auto mb-4 space-y-4 p-4 bg-gray-50 dark:bg-gray-900 rounded-lg">
        <AnimatePresence>
          {messages.map((message) => (
            <motion.div
              key={message.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`max-w-3/4 p-3 rounded-lg ${
                  message.sender === 'user'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 dark:bg-gray-700'
                }`}
              >
                <p>{message.text}</p>
                <p className="text-xs mt-1 opacity-75">
                  {message.timestamp.toLocaleTimeString()}
                </p>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
        {isTyping && (
          <div className="flex items-center text-gray-500 dark:text-gray-400">
            <Bot size={16} className="mr-2" />
            <span>Occam is typing...</span>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="relative">
        <div className="flex space-x-2">
          <button
            onClick={() => setShowQuestions(!showQuestions)}
            className="p-2 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
          >
            <List size={20} />
          </button>
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            placeholder="Ask Occam about this discrepancy..."
            className="flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600"
          />
          <button
            onClick={handleSendMessage}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
          >
            <Send size={20} />
          </button>
        </div>

        <AnimatePresence>
          {showQuestions && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              className="absolute bottom-full left-0 mb-2 w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg z-10"
            >
              <div className="p-2">
                {predefinedQuestions.map((question, index) => (
                  <button
                    key={index}
                    onClick={() => handleQuestionSelect(question)}
                    className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                  >
                    {question}
                  </button>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default OccamChat;