import React, { useState } from 'react';
import OccamWorkerBeeInterface from './components/Dashboard';
import Login from './components/Login';
import A11yAnnouncer from './components/A11yAnnouncer';
import SkipLink from './components/SkipLink';
import { useKeyboardFocus } from './hooks/useKeyboardFocus';
import A11yErrorBoundary from './components/A11yErrorBoundary';

const App: React.FC = () => {
  const [user, setUser] = useState<string | null>(null);
  const [darkMode, setDarkMode] = useState(false);

  useKeyboardFocus();

  return (
    <A11yErrorBoundary>
      <div className={`${darkMode ? 'dark' : ''} min-h-screen bg-gray-100 dark:bg-gray-900`}>
        <SkipLink />
        <A11yAnnouncer />
        <main id="main-content" className="min-h-screen">
          {user ? (
            <OccamWorkerBeeInterface 
              setUser={setUser} 
              darkMode={darkMode} 
              setDarkMode={setDarkMode} 
              userRole={user} 
            />
          ) : (
            <Login setUser={setUser} />
          )}
        </main>
      </div>
    </A11yErrorBoundary>
  );
};

export default App;