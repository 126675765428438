import React, { useState } from 'react';
import { Trash2, Tags, Download, ChevronDown, Brain, RefreshCw } from 'lucide-react';
import KnowledgeTrainingService from '../../services/KnowledgeTrainingService';

interface KnowledgeBaseBatchActionsProps {
  selectedCount: number;
  onAction: (action: 'delete' | 'categorize' | 'export' | 'train', category?: string) => void;
  isTraining?: boolean;
}

const KnowledgeBaseBatchActions: React.FC<KnowledgeBaseBatchActionsProps> = ({
  selectedCount,
  onAction,
  isTraining = false
}) => {
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const categories = ['IPMDAR', 'Project Management', 'Technical Documentation', 'Reports', 'Other'];

  return (
    <div className="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg flex items-center justify-between">
      <span className="text-blue-700 dark:text-blue-300">
        {selectedCount} file{selectedCount !== 1 ? 's' : ''} selected
      </span>
      <div className="flex space-x-2">
        <div className="relative">
          <button
            onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
            className="flex items-center px-3 py-2 bg-white dark:bg-gray-700 rounded-lg shadow-sm hover:bg-gray-50 dark:hover:bg-gray-600"
          >
            <Tags size={16} className="mr-2" />
            Categorize
            <ChevronDown size={16} className="ml-2" />
          </button>
          {showCategoryDropdown && (
            <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg z-10">
              {categories.map((category) => (
                <button
                  key={category}
                  onClick={() => {
                    onAction('categorize', category);
                    setShowCategoryDropdown(false);
                  }}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  {category}
                </button>
              ))}
            </div>
          )}
        </div>
        <button
          onClick={() => onAction('export')}
          className="flex items-center px-3 py-2 bg-green-500 text-white rounded-lg shadow-sm hover:bg-green-600"
        >
          <Download size={16} className="mr-2" />
          Export
        </button>
        <button
          onClick={() => onAction('train')}
          disabled={isTraining}
          className={`flex items-center px-3 py-2 bg-purple-500 text-white rounded-lg shadow-sm hover:bg-purple-600 ${
            isTraining ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isTraining ? (
            <RefreshCw size={16} className="mr-2 animate-spin" />
          ) : (
            <Brain size={16} className="mr-2" />
          )}
          {isTraining ? 'Training...' : 'Train AI Agents'}
        </button>
        <button
          onClick={() => onAction('delete')}
          className="flex items-center px-3 py-2 bg-red-500 text-white rounded-lg shadow-sm hover:bg-red-600"
        >
          <Trash2 size={16} className="mr-2" />
          Delete
        </button>
      </div>
    </div>
  );
};

export default KnowledgeBaseBatchActions;