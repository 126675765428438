class PasswordService {
  private static instance: PasswordService;
  private users: Map<string, { password: string; role: string }>;

  private constructor() {
    this.users = new Map([
      ['superadmin', { password: 'S#p3rAdm!n2024@', role: 'Super Administrator' }],
      ['admin', { password: 'Adm!nP@ss2024#', role: 'Administrator' }],
      ['user', { password: 'Us3rP@ss2024!', role: 'User' }]
    ]);
  }

  public static getInstance(): PasswordService {
    if (!PasswordService.instance) {
      PasswordService.instance = new PasswordService();
    }
    return PasswordService.instance;
  }

  public async changePassword(
    username: string,
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    const user = this.users.get(username);
    
    if (!user) {
      throw new Error('User not found');
    }

    if (user.password !== oldPassword) {
      throw new Error('Current password is incorrect');
    }

    // Password validation
    if (!this.isPasswordValid(newPassword)) {
      throw new Error(
        'Password must be at least 12 characters long and contain uppercase, lowercase, numbers, and special characters'
      );
    }

    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Update password
    this.users.set(username, { ...user, password: newPassword });
  }

  private isPasswordValid(password: string): boolean {
    // Password must:
    // - Be at least 12 characters long
    // - Contain at least one uppercase letter
    // - Contain at least one lowercase letter
    // - Contain at least one number
    // - Contain at least one special character
    const minLength = 12;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);

    return (
      password.length >= minLength &&
      hasUppercase &&
      hasLowercase &&
      hasNumber &&
      hasSpecial
    );
  }

  public validateCredentials(username: string, password: string): string | null {
    const user = this.users.get(username);
    if (user && user.password === password) {
      return user.role;
    }
    return null;
  }
}

export default PasswordService;