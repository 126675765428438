import { ChartConfig, ChartRequest } from '../types';

export class ChartGenerationService {
  private static instance: ChartGenerationService;

  private constructor() {}

  public static getInstance(): ChartGenerationService {
    if (!ChartGenerationService.instance) {
      ChartGenerationService.instance = new ChartGenerationService();
    }
    return ChartGenerationService.instance;
  }

  public async generateGanttChart(tasks: any[]): Promise<ChartConfig> {
    return {
      type: 'bar',
      data: {
        labels: tasks.map(t => t.name),
        datasets: [{
          data: tasks.map(t => t.duration),
          backgroundColor: 'rgba(54, 162, 235, 0.5)'
        }]
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'Project Gantt Chart'
          }
        }
      }
    };
  }

  public async generateEVMChart(data: any): Promise<ChartConfig> {
    return {
      type: 'line',
      data: {
        labels: data.timePeriods,
        datasets: [
          {
            label: 'Planned Value',
            data: data.plannedValue,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          },
          {
            label: 'Earned Value',
            data: data.earnedValue,
            borderColor: 'rgb(255, 99, 132)',
            tension: 0.1
          },
          {
            label: 'Actual Cost',
            data: data.actualCost,
            borderColor: 'rgb(54, 162, 235)',
            tension: 0.1
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'Earned Value Management Chart'
          }
        }
      }
    };
  }

  public async generateChart(request: ChartRequest): Promise<ChartConfig> {
    switch (request.type) {
      case 'gantt':
        return this.generateGanttChart(request.data);
      case 'evm':
        return this.generateEVMChart(request.data);
      default:
        throw new Error(`Unsupported chart type: ${request.type}`);
    }
  }
}

export default ChartGenerationService;