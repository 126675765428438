export const azureConfig = {
  apiKey: 'a1bdb11c3a4f4453a23a7982483e1809',
  endpoint: 'https://ai-occamaiv1hub770033508777.openai.azure.com',
  deployment: 'gpt-4o',
  apiVersion: '2024-02-15-preview',
  speechKey: process.env.VITE_AZURE_SPEECH_KEY,
  speechRegion: process.env.VITE_AZURE_SPEECH_REGION,
};

export const azureEndpoints = {
  languageModel: `${azureConfig.endpoint}/openai/deployments/${azureConfig.deployment}/chat/completions?api-version=${azureConfig.apiVersion}`,
  realtime: `${azureConfig.endpoint}/openai/realtime?api-version=${azureConfig.apiVersion}&deployment=${azureConfig.deployment}`,
  dalle: `${azureConfig.endpoint}/openai/images/generations:submit?api-version=${azureConfig.apiVersion}`,
  whisper: `${azureConfig.endpoint}/openai/audio/transcriptions?api-version=${azureConfig.apiVersion}`,
  modelScaleset: `${azureConfig.endpoint}/openai/models?api-version=${azureConfig.apiVersion}`,
  token: `${azureConfig.endpoint}/token?api-version=${azureConfig.apiVersion}`,
};