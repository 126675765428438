import { BaseAIAgent } from './BaseAIAgent';
import { ChartGenerationService } from './ChartGenerationService';

export class ProjectManagerAgent extends BaseAIAgent {
  private chartService: ChartGenerationService;

  constructor() {
    super('Project Manager');
    this.chartService = ChartGenerationService.getInstance();
  }

  public async generateVisualization(data: any): Promise<any> {
    return this.chartService.generateChart({
      type: 'gantt',
      data,
      agent: this.name
    });
  }

  public async validateData(data: any): Promise<boolean> {
    // Implement project management specific validation
    return true;
  }

  public getCapabilities(): string[] {
    return [
      'Project Planning',
      'Resource Management',
      'Schedule Analysis',
      'Risk Management',
      'Performance Tracking'
    ];
  }
}