import { ChartGenerationService } from './ChartGenerationService';

interface ProjectMetrics {
  schedulePerformance: {
    delays: Array<{
      activity: string;
      delay: number;
      cause: string;
    }>;
    criticalPath: string[];
    slippage: number;
  };
  budgetPerformance: {
    overruns: Array<{
      category: string;
      amount: number;
      trend: 'increasing' | 'decreasing' | 'stable';
    }>;
    spendingPatterns: Array<{
      period: string;
      planned: number;
      actual: number;
    }>;
  };
  resourceMetrics: {
    bottlenecks: Array<{
      resource: string;
      utilization: number;
      impact: string;
    }>;
    allocation: Array<{
      resource: string;
      allocated: number;
      actual: number;
    }>;
  };
}

interface ConnectionConfig {
  server: string;
  database: string;
  username: string;
  password: string;
  options?: {
    encrypt?: boolean;
    trustServerCertificate?: boolean;
  };
}

export class ProteusService {
  private static instance: ProteusService;
  private chartService: ChartGenerationService;
  private isConnected: boolean = false;
  private connectionConfig: ConnectionConfig | null = null;
  private retryAttempts: number = 3;
  private retryDelay: number = 1000;

  private constructor() {
    this.chartService = ChartGenerationService.getInstance();
  }

  public static getInstance(): ProteusService {
    if (!ProteusService.instance) {
      ProteusService.instance = new ProteusService();
    }
    return ProteusService.instance;
  }

  private async validateConnectionConfig(config: ConnectionConfig): Promise<void> {
    if (!config.server || !config.database || !config.username || !config.password) {
      throw new Error('Invalid connection configuration: All fields are required');
    }

    // Add default options if not provided
    config.options = {
      encrypt: config.options?.encrypt ?? true,
      trustServerCertificate: config.options?.trustServerCertificate ?? false
    };
  }

  private async delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public async connect(config: ConnectionConfig): Promise<void> {
    try {
      await this.validateConnectionConfig(config);
      this.connectionConfig = config;

      for (let attempt = 1; attempt <= this.retryAttempts; attempt++) {
        try {
          // Simulate connection - in production, this would be a real database connection
          await this.delay(1000);
          
          // Connection successful
          this.isConnected = true;
          console.log('Successfully connected to Proteus database');
          return;
        } catch (error) {
          if (attempt === this.retryAttempts) {
            throw error;
          }
          console.warn(`Connection attempt ${attempt} failed, retrying...`);
          await this.delay(this.retryDelay * attempt);
        }
      }
    } catch (error) {
      this.isConnected = false;
      this.connectionConfig = null;
      const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
      throw new Error(`Failed to connect to Proteus database: ${errorMessage}`);
    }
  }

  public async disconnect(): Promise<void> {
    try {
      if (this.isConnected) {
        // Simulate disconnection
        await this.delay(500);
        this.isConnected = false;
        this.connectionConfig = null;
      }
    } catch (error) {
      console.error('Error disconnecting from Proteus:', error);
      throw error;
    }
  }

  public async analyzeProjectPerformance(projectId: string): Promise<ProjectMetrics> {
    if (!this.isConnected) {
      throw new Error('Not connected to Proteus database. Please connect first.');
    }

    try {
      // Simulate data analysis
      await this.delay(1500);

      return {
        schedulePerformance: {
          delays: [
            { activity: 'Task A', delay: 5, cause: 'Resource unavailability' },
            { activity: 'Task B', delay: 3, cause: 'Technical issues' }
          ],
          criticalPath: ['Task A', 'Task C', 'Task E'],
          slippage: 8
        },
        budgetPerformance: {
          overruns: [
            { category: 'Labor', amount: 15000, trend: 'increasing' },
            { category: 'Materials', amount: 8000, trend: 'stable' }
          ],
          spendingPatterns: [
            { period: 'Jan', planned: 50000, actual: 55000 },
            { period: 'Feb', planned: 75000, actual: 85000 }
          ]
        },
        resourceMetrics: {
          bottlenecks: [
            { resource: 'Developer Team', utilization: 0.95, impact: 'High workload causing delays' },
            { resource: 'Testing Team', utilization: 0.85, impact: 'Delayed testing cycles' }
          ],
          allocation: [
            { resource: 'Developers', allocated: 160, actual: 180 },
            { resource: 'Testers', allocated: 80, actual: 90 }
          ]
        }
      };
    } catch (error) {
      console.error('Error analyzing project performance:', error);
      throw new Error('Failed to analyze project performance');
    }
  }

  public async generatePerformanceReport(projectId: string): Promise<{
    metrics: ProjectMetrics;
    recommendations: string[];
    charts: any[];
  }> {
    if (!this.isConnected) {
      throw new Error('Not connected to Proteus database. Please connect first.');
    }

    try {
      const metrics = await this.analyzeProjectPerformance(projectId);
      
      // Generate recommendations based on analysis
      const recommendations: string[] = [];
      
      if (metrics.schedulePerformance.slippage > 5) {
        recommendations.push('Critical schedule slippage detected. Consider fast-tracking critical path activities.');
      }

      const significantOverruns = metrics.budgetPerformance.overruns
        .filter(o => o.trend === 'increasing')
        .length;
      
      if (significantOverruns > 0) {
        recommendations.push('Multiple cost categories showing increasing overruns. Implement stricter cost control measures.');
      }

      const overutilizedResources = metrics.resourceMetrics.bottlenecks.length;
      if (overutilizedResources > 0) {
        recommendations.push('Resource bottlenecks identified. Consider resource leveling and capacity planning.');
      }

      // Generate visualizations
      const charts = await Promise.all([
        this.chartService.generateChart({
          type: 'bar',
          data: {
            labels: metrics.schedulePerformance.delays.map(d => d.activity),
            datasets: [{
              label: 'Schedule Delays (Days)',
              data: metrics.schedulePerformance.delays.map(d => d.delay),
              backgroundColor: 'rgba(255, 99, 132, 0.5)'
            }]
          },
          agent: 'Project Manager'
        }),
        this.chartService.generateChart({
          type: 'line',
          data: {
            labels: metrics.budgetPerformance.spendingPatterns.map(s => s.period),
            datasets: [
              {
                label: 'Planned Spending',
                data: metrics.budgetPerformance.spendingPatterns.map(s => s.planned),
                borderColor: 'rgb(75, 192, 192)'
              },
              {
                label: 'Actual Spending',
                data: metrics.budgetPerformance.spendingPatterns.map(s => s.actual),
                borderColor: 'rgb(255, 99, 132)'
              }
            ]
          },
          agent: 'Cost Control Analyst'
        }),
        this.chartService.generateChart({
          type: 'bar',
          data: {
            labels: metrics.resourceMetrics.allocation.map(r => r.resource),
            datasets: [
              {
                label: 'Allocated Hours',
                data: metrics.resourceMetrics.allocation.map(r => r.allocated),
                backgroundColor: 'rgba(75, 192, 192, 0.5)'
              },
              {
                label: 'Actual Hours',
                data: metrics.resourceMetrics.allocation.map(r => r.actual),
                backgroundColor: 'rgba(255, 99, 132, 0.5)'
              }
            ]
          },
          agent: 'Resource Manager'
        })
      ]);

      return {
        metrics,
        recommendations,
        charts
      };
    } catch (error) {
      console.error('Error generating performance report:', error);
      throw new Error('Failed to generate performance report');
    }
  }

  public isConnectedToProteus(): boolean {
    return this.isConnected;
  }
}

export default ProteusService;