import React from 'react';
import { Filter, Calendar } from 'lucide-react';

interface KnowledgeBaseFiltersProps {
  filters: {
    type: string;
    category: string;
    dateRange: string;
  };
  setFilters: (filters: any) => void;
}

const KnowledgeBaseFilters: React.FC<KnowledgeBaseFiltersProps> = ({
  filters,
  setFilters,
}) => {
  return (
    <div className="flex space-x-4">
      <div className="relative">
        <select
          value={filters.type}
          onChange={(e) => setFilters({ ...filters, type: e.target.value })}
          className="pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
        >
          <option value="all">All Types</option>
          <option value="text">Text</option>
          <option value="pdf">PDF</option>
          <option value="doc">Word</option>
          <option value="json">JSON</option>
        </select>
        <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
      </div>

      <div className="relative">
        <select
          value={filters.category}
          onChange={(e) => setFilters({ ...filters, category: e.target.value })}
          className="pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
        >
          <option value="all">All Categories</option>
          <option value="ipmdar">IPMDAR</option>
          <option value="project">Project Management</option>
          <option value="technical">Technical Documentation</option>
          <option value="reports">Reports</option>
        </select>
        <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
      </div>

      <div className="relative">
        <select
          value={filters.dateRange}
          onChange={(e) => setFilters({ ...filters, dateRange: e.target.value })}
          className="pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
        >
          <option value="all">All Time</option>
          <option value="today">Today</option>
          <option value="week">This Week</option>
          <option value="month">This Month</option>
          <option value="year">This Year</option>
        </select>
        <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
      </div>
    </div>
  );
};

export default KnowledgeBaseFilters;