import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Book } from 'lucide-react';
import DocumentList from './DocumentList';
import DocumentPreview from './DocumentPreview';
import DocumentSearch from './DocumentSearch';

interface PretrainedDocsViewerProps {
  onClose: () => void;
}

const PretrainedDocsViewer: React.FC<PretrainedDocsViewerProps> = ({ onClose }) => {
  const [currentDocIndex, setCurrentDocIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedContent, setSelectedContent] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);

  const documents = [
    // Core System Documents
    {
      name: 'Administration.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'System administration and user management configuration',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- Administration content -->\n</Workspace>'
    },
    {
      name: 'Quick Look.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'Quick overview and summary dashboard',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- Quick Look content -->\n</Workspace>'
    },
    {
      name: 'Workspace Launcher.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'Workspace initialization and configuration',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- Workspace Launcher content -->\n</Workspace>'
    },

    // IPMDAR Documents
    {
      name: 'IPMDAR BCR.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'IPMDAR Baseline Change Report configuration',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- IPMDAR BCR content -->\n</Workspace>'
    },
    {
      name: 'IPMDAR Performance.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'IPMDAR performance analysis and metrics',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- IPMDAR Performance content -->\n</Workspace>'
    },
    {
      name: 'IPMDAR Performance Narrative Report.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'IPMDAR performance narrative generation',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- IPMDAR Performance Narrative content -->\n</Workspace>'
    },
    {
      name: 'IPMDAR Performance Recalc and Data Entry.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'IPMDAR performance data recalculation',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- IPMDAR Performance Recalc content -->\n</Workspace>'
    },

    // DECM Documents
    {
      name: 'DECM Overview.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'DECM system overview and configuration',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- DECM Overview content -->\n</Workspace>'
    },
    {
      name: 'DECM Process Overview.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'DECM process flow and implementation',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- DECM Process Overview content -->\n</Workspace>'
    },
    {
      name: 'DECM Details.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'Detailed DECM configuration and settings',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- DECM Details content -->\n</Workspace>'
    },
    {
      name: 'DECM Portfolio.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'DECM portfolio management',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- DECM Portfolio content -->\n</Workspace>'
    },
    {
      name: 'DECM Data Entry.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'DECM data entry interface',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- DECM Data Entry content -->\n</Workspace>'
    },
    {
      name: 'DECM Test Record IDs.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'DECM test record tracking',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- DECM Test Record IDs content -->\n</Workspace>'
    },
    {
      name: 'DECM Test Step Table Mgr.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'DECM test step management',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- DECM Test Step Table Manager content -->\n</Workspace>'
    },
    {
      name: 'DECM Breakout Reporting.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'DECM detailed reporting',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- DECM Breakout Reporting content -->\n</Workspace>'
    },
    {
      name: 'DECM Recalc and Loaded Data.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'DECM data recalculation',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- DECM Recalc content -->\n</Workspace>'
    },

    // IPMR Documents
    {
      name: 'IPMR Analysis.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'IPMR analysis and metrics',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- IPMR Analysis content -->\n</Workspace>'
    },
    {
      name: 'IPMR Cost Overview.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'IPMR cost analysis dashboard',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- IPMR Cost Overview content -->\n</Workspace>'
    },
    {
      name: 'IPMR Schedule Analysis.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'IPMR schedule analysis tools',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- IPMR Schedule Analysis content -->\n</Workspace>'
    },
    {
      name: 'IPMR Timephase Analysis.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'IPMR timephase analysis tools',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- IPMR Timephase Analysis content -->\n</Workspace>'
    },

    // LENS Documents
    {
      name: 'LENS Analysis.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'LENS analysis tools and metrics',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- LENS Analysis content -->\n</Workspace>'
    },
    {
      name: 'LENS Recalculate.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'LENS data recalculation tools',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- LENS Recalculate content -->\n</Workspace>'
    },
    {
      name: 'LENS Risk Entry and Review.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'LENS risk management interface',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- LENS Risk Entry content -->\n</Workspace>'
    },

    // Schedule Documents
    {
      name: 'Schedule Compare.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'Schedule comparison tools',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- Schedule Compare content -->\n</Workspace>'
    },
    {
      name: 'IMS Metrics.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'IMS metrics and analysis',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- IMS Metrics content -->\n</Workspace>'
    },
    {
      name: 'IMS Driving Path.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'IMS path analysis',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- IMS Driving Path content -->\n</Workspace>'
    },

    // CAED Documents
    {
      name: 'CAED IPMR Analysis.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'CAED IPMR analysis tools',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- CAED IPMR Analysis content -->\n</Workspace>'
    },
    {
      name: 'CAED Schedule Metrics.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'CAED schedule metrics',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- CAED Schedule Metrics content -->\n</Workspace>'
    },
    {
      name: 'CAED Contract EV Charts.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'CAED contract EV visualization',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- CAED Contract EV Charts content -->\n</Workspace>'
    },
    {
      name: 'CAED Cost and Schedule Metrics.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'CAED cost and schedule analysis',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- CAED Cost and Schedule Metrics content -->\n</Workspace>'
    },

    // Portfolio Documents
    {
      name: 'Portfolio One Pager.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'Portfolio summary dashboard',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- Portfolio One Pager content -->\n</Workspace>'
    },
    {
      name: 'Portfolio EV Charts.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'Portfolio EV visualization',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- Portfolio EV Charts content -->\n</Workspace>'
    },
    {
      name: 'Directorate Dashboard.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'Directorate overview dashboard',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- Directorate Dashboard content -->\n</Workspace>'
    },

    // Analysis Documents
    {
      name: 'Element Child Drivers.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'Element hierarchy analysis',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- Element Child Drivers content -->\n</Workspace>'
    },
    {
      name: 'Timephase Compare.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'Timephase comparison tools',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- Timephase Compare content -->\n</Workspace>'
    },
    {
      name: 'Variance Analysis Contract.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'Contract variance analysis',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- Variance Analysis content -->\n</Workspace>'
    },
    {
      name: '14 Point Assessment.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: '14-point assessment framework',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- 14 Point Assessment content -->\n</Workspace>'
    },

    // User Documents
    {
      name: 'User Performance.proteus',
      type: 'proteus',
      createdAt: new Date('2024-01-15'),
      summary: 'User performance tracking',
      content: '<?xml version="1.0"?>\n<Workspace>\n  <!-- User Performance content -->\n</Workspace>'
    },

    // Configuration Documents
    {
      name: 'deepeval_metrics.json',
      type: 'json',
      createdAt: new Date('2024-01-15'),
      summary: 'DeepEval metrics configuration',
      content: '{\n  "metrics": {\n    "responseQuality": {\n      "accuracy": {\n        "description": "Measures factual correctness"\n      }\n    }\n  }\n}'
    },
    {
      name: 'deepeval_test_config.json',
      type: 'json',
      createdAt: new Date('2024-01-15'),
      summary: 'DeepEval test configuration',
      content: '{\n  "testConfig": {\n    "hallucination": {\n      "threshold": 0.85\n    }\n  }\n}'
    },
    {
      name: 'OccamAcronymList.json',
      type: 'json',
      createdAt: new Date('2024-01-15'),
      summary: 'IPMDAR/EVM acronym list',
      content: '{\n  "acronyms": [\n    {\n      "acronym": "IPMDAR",\n      "meaning": "Integrated Program Management Data and Analysis Report"\n    }\n  ]\n}'
    },
    {
      name: 'occam_model_prompts.json',
      type: 'json',
      createdAt: new Date('2024-01-15'),
      summary: 'AI model prompts',
      content: '{\n  "prompts": {\n    "systemPrompt": "As Occam, an AI assistant..."\n  }\n}'
    },
    {
      name: 'occam_test_fulldataset.json',
      type: 'json',
      createdAt: new Date('2024-01-15'),
      summary: 'Test dataset',
      content: '{\n  "tests": [\n    {\n      "name": "SPD Aligns to Schedule Performance Dataset"\n    }\n  ]\n}'
    },

    // PDF Documents
    {
      name: 'Denodo_How-to-Lay-Foundation-for-Data-AI-DoD-1-1.pdf',
      type: 'pdf',
      createdAt: new Date('2024-01-15'),
      summary: 'DoD Data/AI foundation guide',
      content: 'PDF content not displayed in preview'
    },
    {
      name: 'DOD 2023 Data_Analytics_and_ Ai Adoption Strategy.pdf',
      type: 'pdf',
      createdAt: new Date('2024-01-15'),
      summary: 'DoD Data Analytics/AI strategy',
      content: 'PDF content not displayed in preview'
    }
  ];

  const filteredDocs = documents.filter(doc => 
    doc.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    doc.summary.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (filteredDocs.length > 0) {
      setSelectedContent(filteredDocs[currentDocIndex].content);
    }
  }, [currentDocIndex, filteredDocs]);

  const handlePrevious = () => {
    const newIndex = currentDocIndex > 0 ? currentDocIndex - 1 : filteredDocs.length - 1;
    setCurrentDocIndex(newIndex);
    setScrollPosition(0);
  };

  const handleNext = () => {
    const newIndex = currentDocIndex < filteredDocs.length - 1 ? currentDocIndex + 1 : 0;
    setCurrentDocIndex(newIndex);
    setScrollPosition(0);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    setScrollPosition(target.scrollLeft);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.95 }}
        className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-6xl w-full mx-4 max-h-[90vh] overflow-hidden"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center p-4 border-b dark:border-gray-700">
          <div className="flex items-center">
            <Book className="mr-2 text-blue-500" size={24} />
            <h2 className="text-xl font-semibold">Pre-trained Documents</h2>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
          >
            <X size={20} />
          </button>
        </div>

        <div className="flex border-b dark:border-gray-700">
          <div className="w-1/3 border-r dark:border-gray-700 p-4">
            <DocumentSearch
              searchQuery={searchQuery}
              onSearchChange={setSearchQuery}
            />
            
            <DocumentList
              documents={filteredDocs}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              onDocumentSelect={setCurrentDocIndex}
              selectedDocument={currentDocIndex}
            />
          </div>

          <DocumentPreview
            document={filteredDocs[currentDocIndex]}
            currentIndex={currentDocIndex}
            totalDocuments={filteredDocs.length}
            onPrevious={handlePrevious}
            onNext={handleNext}
            onScroll={handleScroll}
          />
        </div>

        {scrollPosition > 0 && (
          <div className="absolute bottom-4 right-4 bg-blue-500 text-white px-3 py-1 rounded-full text-sm">
            Scroll horizontally to view more
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default PretrainedDocsViewer;