import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Bot, Activity, FileText, Database, TrendingUp, AlertTriangle, Users, Server } from 'lucide-react';
import AIAgents from './AIAgents';
import FileAnalysisButton from './FileAnalysisButton';
import { ForensicRiskAnalysis } from './RiskAnalysis';
import AIAgentOrgChart from './AIAgentOrgChart';
import UnifiedHealthMonitoring from './UnifiedHealthMonitoring';
import ReportingAnalytics from './ReportingAnalytics';
import DatabaseInterface from './DatabaseInterface';
import RiskyProject from './RiskyProject';
import UserManagement from './UserManagement';
import UnifiedKnowledgeManagement from './UnifiedKnowledgeManagement';
import OccamEvalTestPage from './OccamEvalTestPage';
import InstallationBuilder from './InstallationBuilder';
import SettingsPanel from './SettingsPanel';
import AgentPanel from './AgentPanel';
import { ConnectModal } from './ConnectModal';
import HelpDesk from './HelpDesk';
import DecouplingInterop from './DecouplingInterop';
import AgentChatInterface from './AgentChatInterface';
import ProteusAnalytics from './ProteusAnalytics';
import Sidebar from './Sidebar';
import { DashboardHeader } from './DashboardHeader';
import UserFeedback from './UserFeedback';
import EditSidebar from './EditSidebar';
import ToolsUtilities from './ToolsUtilities';

interface DashboardProps {
  setUser: (user: string | null) => void;
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
  userRole: string;
}

const Dashboard: React.FC<DashboardProps> = ({ setUser, darkMode, setDarkMode, userRole }) => {
  const [currentView, setCurrentView] = useState('dashboard');
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showSettingsPanel, setShowSettingsPanel] = useState(false);
  const [showAgentPanel, setShowAgentPanel] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [connectionInfo, setConnectionInfo] = useState({
    server: '',
    database: '',
    username: '',
    password: ''
  });

  const handleConnectToProteus = async () => {
    setShowConnectModal(false);
  };

  const addRecentActivity = (icon: React.ElementType, message: string) => {
    console.log(message);
  };

  return (
    <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
      <Sidebar 
        setCurrentView={setCurrentView} 
        currentView={currentView} 
        userRole={userRole} 
      />
      
      <div className="flex-1 flex flex-col overflow-hidden">
        <DashboardHeader 
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          setUser={setUser}
          setShowConnectModal={setShowConnectModal}
        />

        <main className="flex-1 overflow-x-hidden overflow-y-auto">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentView}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
              >
                {currentView === 'dashboard' && (
                  <div className="space-y-6">
                    <div className="bg-gradient-to-r from-blue-800 to-blue-900 text-white p-6 rounded-lg shadow-lg">
                      <div className="max-w-3xl">
                        <h2 className="text-2xl font-bold mb-2">Welcome to Occam AI</h2>
                        <p className="text-lg opacity-90">
                          Your intelligent assistant for the Proteus Envision Suite. Occam helps you navigate complex project data, analyze IPMDAR reports, and make informed decisions through natural conversations.
                        </p>
                      </div>
                    </div>
                    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-bold flex items-center">
                          <Bot className="mr-2 text-blue-500" size={24} />
                          AI Agent Interface
                        </h2>
                        <FileAnalysisButton files={uploadedFiles} />
                      </div>
                      <AIAgents 
                        addRecentActivity={addRecentActivity} 
                        onFileUpload={setUploadedFiles}
                      />
                    </div>
                    
                    {uploadedFiles.length > 0 && (
                      <ForensicRiskAnalysis 
                        uploadedFiles={uploadedFiles}
                        onAnalysisComplete={(report) => {
                          addRecentActivity(AlertTriangle, 'Risk analysis completed');
                          console.log('Risk Analysis Report:', report);
                        }}
                      />
                    )}
                  </div>
                )}
                {currentView === 'aiagents' && <AIAgentOrgChart />}
                {currentView === 'health' && <UnifiedHealthMonitoring />}
                {currentView === 'reports' && <ReportingAnalytics />}
                {currentView === 'database' && <DatabaseInterface />}
                {currentView === 'riskyproject' && <RiskyProject />}
                {currentView === 'usermanagement' && <UserManagement />}
                {currentView === 'data' && <UnifiedKnowledgeManagement userRole={userRole} />}
                {currentView === 'occameval' && <OccamEvalTestPage />}
                {currentView === 'installer' && userRole === 'Super Administrator' && <InstallationBuilder />}
                {currentView === 'helpdesk' && <HelpDesk />}
                {currentView === 'decouplingInterop' && userRole === 'Super Administrator' && <DecouplingInterop />}
                {currentView === 'proteus' && <ProteusAnalytics />}
                {currentView === 'feedback' && <UserFeedback />}
                {currentView === 'editSidebar' && <EditSidebar />}
                {currentView === 'tools' && <ToolsUtilities />}
              </motion.div>
            </AnimatePresence>
          </div>
        </main>

        <AgentChatInterface />
      </div>

      <ConnectModal
        showConnectModal={showConnectModal}
        setShowConnectModal={setShowConnectModal}
        connectionInfo={connectionInfo}
        setConnectionInfo={setConnectionInfo}
        handleConnectToProteus={handleConnectToProteus}
      />

      <SettingsPanel
        isOpen={showSettingsPanel}
        onClose={() => setShowSettingsPanel(false)}
        selectedLLM="gpt-4"
        apiKey=""
        onLLMChange={() => {}}
        onApiKeyChange={() => {}}
        onApiKeySubmit={() => {}}
      />

      <AgentPanel
        isOpen={showAgentPanel}
        onClose={() => setShowAgentPanel(false)}
        selectedAgent="Occam"
        onAgentChange={() => {}}
        agents={[
          'Occam',
          'Project Manager',
          'Earned Value Management Professional',
          'Program Manager',
          'Cost Control Analyst',
          'Schedule Analyst',
          'Risk Manager',
          'Performance Data Analyst',
          'Project Controller',
          'Quality Controller'
        ]}
      />
    </div>
  );
};

export default Dashboard;