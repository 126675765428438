import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Clock, TrendingUp, AlertCircle } from 'lucide-react';

interface TestMetricsProps {
  results: Array<{
    id: string;
    passed: boolean;
    duration: number;
    error?: string;
  }>;
  tests: Array<{
    id: string;
    name: string;
    category: string;
  }>;
}

const TestMetrics: React.FC<TestMetricsProps> = ({ results, tests }) => {
  const passRate = (results.filter(r => r.passed).length / results.length) * 100;
  const averageDuration = results.reduce((acc, curr) => acc + curr.duration, 0) / results.length;
  const errorRate = (results.filter(r => r.error).length / results.length) * 100;

  const categoryResults = tests.reduce((acc, test) => {
    const result = results.find(r => r.id === test.id);
    if (!result) return acc;

    if (!acc[test.category]) {
      acc[test.category] = { total: 0, passed: 0 };
    }

    acc[test.category].total++;
    if (result.passed) acc[test.category].passed++;

    return acc;
  }, {} as Record<string, { total: number; passed: number }>);

  const durationData = {
    labels: tests.map(t => t.name),
    datasets: [
      {
        label: 'Test Duration (ms)',
        data: tests.map(test => {
          const result = results.find(r => r.id === test.id);
          return result ? result.duration : 0;
        }),
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        borderColor: 'rgb(59, 130, 246)',
        borderWidth: 1,
      },
    ],
  };

  const categoryData = {
    labels: Object.keys(categoryResults),
    datasets: [
      {
        label: 'Pass Rate by Category',
        data: Object.values(categoryResults).map(
          ({ total, passed }) => (passed / total) * 100
        ),
        backgroundColor: 'rgba(16, 185, 129, 0.5)',
        borderColor: 'rgb(16, 185, 129)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <TrendingUp className="text-green-500 mr-2" size={24} />
              <span className="text-gray-600 dark:text-gray-400">Pass Rate</span>
            </div>
            <span className="text-2xl font-bold text-gray-800 dark:text-white">
              {passRate.toFixed(1)}%
            </span>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Clock className="text-blue-500 mr-2" size={24} />
              <span className="text-gray-600 dark:text-gray-400">Avg Duration</span>
            </div>
            <span className="text-2xl font-bold text-gray-800 dark:text-white">
              {averageDuration.toFixed(2)}ms
            </span>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <AlertCircle className="text-red-500 mr-2" size={24} />
              <span className="text-gray-600 dark:text-gray-400">Error Rate</span>
            </div>
            <span className="text-2xl font-bold text-gray-800 dark:text-white">
              {errorRate.toFixed(1)}%
            </span>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4 text-gray-800 dark:text-white">
            Test Duration Distribution
          </h3>
          <Bar
            data={durationData}
            options={{
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true,
                  grid: {
                    color: 'rgba(156, 163, 175, 0.1)',
                  },
                  ticks: {
                    color: 'rgb(156, 163, 175)',
                  },
                },
                x: {
                  grid: {
                    color: 'rgba(156, 163, 175, 0.1)',
                  },
                  ticks: {
                    color: 'rgb(156, 163, 175)',
                  },
                },
              },
              plugins: {
                legend: {
                  labels: {
                    color: 'rgb(156, 163, 175)',
                  },
                },
              },
            }}
          />
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4 text-gray-800 dark:text-white">
            Pass Rate by Category
          </h3>
          <Bar
            data={categoryData}
            options={{
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true,
                  max: 100,
                  grid: {
                    color: 'rgba(156, 163, 175, 0.1)',
                  },
                  ticks: {
                    color: 'rgb(156, 163, 175)',
                  },
                },
                x: {
                  grid: {
                    color: 'rgba(156, 163, 175, 0.1)',
                  },
                  ticks: {
                    color: 'rgb(156, 163, 175)',
                  },
                },
              },
              plugins: {
                legend: {
                  labels: {
                    color: 'rgb(156, 163, 175)',
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TestMetrics;