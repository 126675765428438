import { useState, useCallback } from 'react';
import { ProcessedFile, processFile, generateEmbeddings, searchVectorDatabase } from '../utils/fileProcessing';
import { setVectorDatabase, getVectorDatabase } from '../utils/api';

export const useKnowledgeBase = () => {
  const [files, setFiles] = useState<ProcessedFile[]>(() => getVectorDatabase());
  const [isProcessing, setIsProcessing] = useState(false);

  const addFiles = useCallback(async (newFiles: File[]) => {
    setIsProcessing(true);
    try {
      const processedFiles: ProcessedFile[] = [];
      
      for (const file of newFiles) {
        const processed = await processFile(file);
        processedFiles.push(...processed);
      }

      // Generate embeddings for each file
      for (const file of processedFiles) {
        const embedding = await generateEmbeddings(file.content);
        file.metadata.embedding = embedding;
      }

      setFiles(prev => {
        const updated = [...prev, ...processedFiles];
        setVectorDatabase(updated);
        return updated;
      });

      return processedFiles;
    } catch (error) {
      console.error('Error processing files:', error);
      throw error;
    } finally {
      setIsProcessing(false);
    }
  }, []);

  const removeFile = useCallback((id: string) => {
    setFiles(prev => {
      const updated = prev.filter(file => file.id !== id);
      setVectorDatabase(updated);
      return updated;
    });
  }, []);

  const searchFiles = useCallback(async (query: string) => {
    if (!query.trim()) return [];
    return searchVectorDatabase(query, files);
  }, [files]);

  const updateFile = useCallback((id: string, updates: Partial<ProcessedFile>) => {
    setFiles(prev => {
      const updated = prev.map(file => 
        file.id === id ? { ...file, ...updates } : file
      );
      setVectorDatabase(updated);
      return updated;
    });
  }, []);

  return {
    files,
    isProcessing,
    addFiles,
    removeFile,
    searchFiles,
    updateFile,
  };
};

export default useKnowledgeBase;