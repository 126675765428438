import React from 'react';
import { Scale, AlertTriangle, TrendingDown } from 'lucide-react';

interface ComplexityMetricsProps {
  score: number;
  assumptions: number;
  simplificationPotential: number;
  confidenceRating: number;
  resourceRequirements: {
    time: number;
    cost: number;
    personnel: number;
  };
  warning?: string;
}

const ComplexityMetrics: React.FC<ComplexityMetricsProps> = ({
  score,
  assumptions,
  simplificationPotential,
  confidenceRating,
  resourceRequirements,
  warning
}) => {
  const getScoreColor = (value: number) => {
    if (value <= 3) return 'text-green-500';
    if (value <= 6) return 'text-yellow-500';
    return 'text-red-500';
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
      <h3 className="text-lg font-semibold mb-4 flex items-center">
        <Scale className="mr-2 text-blue-500" size={20} />
        Complexity Metrics
      </h3>

      <div className="space-y-4">
        {/* Complexity Score */}
        <div>
          <div className="flex justify-between items-center mb-2">
            <span className="text-gray-600 dark:text-gray-400">Complexity Score</span>
            <span className={`font-bold ${getScoreColor(score)}`}>{score}/10</span>
          </div>
          <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
            <div
              className={`h-2 rounded-full transition-all duration-300 ${
                score <= 3 ? 'bg-green-500' :
                score <= 6 ? 'bg-yellow-500' :
                'bg-red-500'
              }`}
              style={{ width: `${(score / 10) * 100}%` }}
            />
          </div>
        </div>

        {/* Assumptions */}
        <div>
          <div className="flex justify-between items-center mb-2">
            <span className="text-gray-600 dark:text-gray-400">Assumptions</span>
            <span className="font-medium">{assumptions}</span>
          </div>
          <div className="flex items-center space-x-1">
            {Array.from({ length: assumptions }).map((_, i) => (
              <div
                key={i}
                className="w-2 h-2 rounded-full bg-blue-500"
              />
            ))}
          </div>
        </div>

        {/* Simplification Potential */}
        <div>
          <div className="flex justify-between items-center mb-2">
            <span className="text-gray-600 dark:text-gray-400">
              Simplification Potential
            </span>
            <span className="font-medium">{simplificationPotential}%</span>
          </div>
          <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
            <div
              className="bg-blue-500 h-2 rounded-full transition-all duration-300"
              style={{ width: `${simplificationPotential}%` }}
            />
          </div>
        </div>

        {/* Confidence Rating */}
        <div>
          <div className="flex justify-between items-center mb-2">
            <span className="text-gray-600 dark:text-gray-400">Confidence Rating</span>
            <span className="font-medium">{(confidenceRating * 100).toFixed(0)}%</span>
          </div>
          <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
            <div
              className="bg-green-500 h-2 rounded-full transition-all duration-300"
              style={{ width: `${confidenceRating * 100}%` }}
            />
          </div>
        </div>

        {/* Resource Requirements */}
        <div className="grid grid-cols-3 gap-4 mt-4">
          <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
            <div className="text-sm text-gray-600 dark:text-gray-400">Time</div>
            <div className="font-medium">{resourceRequirements.time} days</div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
            <div className="text-sm text-gray-600 dark:text-gray-400">Cost</div>
            <div className="font-medium">${resourceRequirements.cost.toLocaleString()}</div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
            <div className="text-sm text-gray-600 dark:text-gray-400">Team Size</div>
            <div className="font-medium">{resourceRequirements.personnel} people</div>
          </div>
        </div>

        {/* Warning Message */}
        {warning && (
          <div className="mt-4 p-3 bg-yellow-100 dark:bg-yellow-900/30 rounded-lg flex items-start">
            <AlertTriangle className="text-yellow-500 mr-2 flex-shrink-0 mt-1" size={16} />
            <p className="text-sm text-yellow-700 dark:text-yellow-300">{warning}</p>
          </div>
        )}

        {/* Optimization Tips */}
        <div className="mt-4 p-4 bg-blue-50 dark:bg-blue-900/30 rounded-lg">
          <div className="flex items-center mb-2">
            <TrendingDown className="text-blue-500 mr-2" size={16} />
            <h4 className="font-medium text-blue-700 dark:text-blue-300">
              Optimization Opportunities
            </h4>
          </div>
          <ul className="list-disc list-inside text-sm text-blue-600 dark:text-blue-400 space-y-1">
            <li>Reduce assumption dependencies</li>
            <li>Consolidate similar components</li>
            <li>Eliminate redundant processes</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ComplexityMetrics;