import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ForceGraph2D from 'react-force-graph-2d';
import { Brain, Bot, AlertCircle, Search, FileText, BarChart2, X, Briefcase, DollarSign, Clock, TrendingUp, Shield, CheckSquare, Database, Activity, RefreshCw } from 'lucide-react';
import ProteusService from '../services/ProteusService';

interface AgentNode {
  id: string;
  name: string;
  role: string;
  description: string;
  icon: React.ElementType;
  color: string;
  group: number;
}

interface AgentLink {
  source: string;
  target: string;
  value: number;
}

const AIAgentOrgChart: React.FC = () => {
  const [graphData, setGraphData] = useState<{ nodes: AgentNode[]; links: AgentLink[] }>({
    nodes: [],
    links: []
  });
  const [selectedNode, setSelectedNode] = useState<AgentNode | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const graphRef = useRef<any>();

  useEffect(() => {
    checkProteusConnection();
    initializeGraph();
  }, []);

  const checkProteusConnection = async () => {
    const proteusService = ProteusService.getInstance();
    setIsConnected(proteusService.isConnectedToProteus());
  };

  const initializeGraph = () => {
    const agents: AgentNode[] = [
      {
        id: 'occam',
        name: 'Occam',
        role: 'Main AI Assistant',
        description: 'Central AI system coordinating all other agents',
        icon: Brain,
        color: '#3B82F6',
        group: 1
      },
      {
        id: 'project-manager',
        name: 'Project Manager',
        role: 'Project Management',
        description: 'Manages project planning and execution',
        icon: Briefcase,
        color: '#10B981',
        group: 2
      },
      {
        id: 'evm-professional',
        name: 'EVM Professional',
        role: 'EVM Analysis',
        description: 'Handles earned value management analysis',
        icon: TrendingUp,
        color: '#6366F1',
        group: 2
      },
      {
        id: 'cost-analyst',
        name: 'Cost Analyst',
        role: 'Cost Control',
        description: 'Analyzes and controls project costs',
        icon: DollarSign,
        color: '#F59E0B',
        group: 3
      },
      {
        id: 'schedule-analyst',
        name: 'Schedule Analyst',
        role: 'Schedule Management',
        description: 'Manages project schedules and timelines',
        icon: Clock,
        color: '#EC4899',
        group: 3
      },
      {
        id: 'performance-analyst',
        name: 'Performance Analyst',
        role: 'Performance Analysis',
        description: 'Analyzes project performance metrics',
        icon: Activity,
        color: '#8B5CF6',
        group: 4
      },
      {
        id: 'quality-controller',
        name: 'Quality Controller',
        role: 'Quality Control',
        description: 'Ensures data quality and validation',
        icon: CheckSquare,
        color: '#14B8A6',
        group: 4
      },
      {
        id: 'risk-manager',
        name: 'Risk Manager',
        role: 'Risk Analysis',
        description: 'Analyzes and assesses project risks',
        icon: AlertCircle,
        color: '#F43F5E',
        group: 4
      },
      {
        id: 'data-analyst',
        name: 'Data Analyst',
        role: 'Data Analysis',
        description: 'Processes and analyzes project data',
        icon: Database,
        color: '#0EA5E9',
        group: 4
      }
    ];

    const links: AgentLink[] = [
      { source: 'occam', target: 'project-manager', value: 5 },
      { source: 'occam', target: 'evm-professional', value: 5 },
      { source: 'project-manager', target: 'cost-analyst', value: 3 },
      { source: 'project-manager', target: 'schedule-analyst', value: 3 },
      { source: 'evm-professional', target: 'cost-analyst', value: 4 },
      { source: 'evm-professional', target: 'schedule-analyst', value: 4 },
      { source: 'occam', target: 'performance-analyst', value: 4 },
      { source: 'occam', target: 'quality-controller', value: 4 },
      { source: 'occam', target: 'risk-manager', value: 4 },
      { source: 'occam', target: 'data-analyst', value: 4 },
      { source: 'performance-analyst', target: 'quality-controller', value: 2 },
      { source: 'quality-controller', target: 'risk-manager', value: 2 },
      { source: 'risk-manager', target: 'data-analyst', value: 2 },
      { source: 'data-analyst', target: 'performance-analyst', value: 2 }
    ];

    setGraphData({ nodes: agents, links });
  };

  const handleNodeClick = (node: AgentNode) => {
    setSelectedNode(node);
  };

  const handleSearchProteus = async () => {
    if (!isConnected) {
      alert('Please connect to Proteus Envision Suite first');
      return;
    }

    setIsSearching(true);
    try {
      const proteusService = ProteusService.getInstance();
      // Implement actual Proteus search here
      await new Promise(resolve => setTimeout(resolve, 1500));
    } catch (error) {
      console.error('Error searching Proteus:', error);
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center">
          <Bot className="mr-2 text-blue-500" size={24} />
          AI Agent Organization
        </h2>
        <button
          onClick={handleSearchProteus}
          disabled={!isConnected || isSearching}
          className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200 flex items-center ${
            (!isConnected || isSearching) && 'opacity-50 cursor-not-allowed'
          }`}
        >
          {isSearching ? (
            <RefreshCw className="animate-spin mr-2" size={20} />
          ) : (
            <Search className="mr-2" size={20} />
          )}
          Search Proteus Envision Suite
        </button>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4" style={{ height: '600px' }}>
        <ForceGraph2D
          ref={graphRef}
          graphData={graphData}
          nodeLabel="name"
          nodeColor={node => (node as AgentNode).color}
          nodeCanvasObject={(node: AgentNode, ctx: CanvasRenderingContext2D, globalScale: number) => {
            const label = node.name;
            const fontSize = 12/globalScale;
            ctx.font = `${fontSize}px Sans-Serif`;
            const textWidth = ctx.measureText(label).width;
            const Icon = node.icon;
            
            // Draw node circle
            ctx.beginPath();
            ctx.arc(node.x!, node.y!, 8, 0, 2 * Math.PI);
            ctx.fillStyle = node.color;
            ctx.fill();
            
            // Draw text background
            ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
            ctx.fillRect(
              node.x! - textWidth/2,
              node.y! + 10,
              textWidth,
              fontSize
            );
            
            // Draw text
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = '#000';
            ctx.fillText(label, node.x!, node.y! + 10 + fontSize/2);
          }}
          linkWidth={link => (link as AgentLink).value}
          linkColor={() => '#999'}
          onNodeClick={handleNodeClick}
          cooldownTicks={100}
          onEngineStop={() => {
            graphRef.current.zoomToFit(400);
          }}
        />
      </div>

      <AnimatePresence>
        {selectedNode && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={() => setSelectedNode(null)}
          >
            <motion.div
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-lg w-full mx-4"
              onClick={e => e.stopPropagation()}
            >
              <div className="flex justify-between items-start mb-4">
                <div className="flex items-center">
                  <selectedNode.icon className="mr-2 text-blue-500" size={24} />
                  <div>
                    <h3 className="text-xl font-bold">{selectedNode.name}</h3>
                    <p className="text-gray-500">{selectedNode.role}</p>
                  </div>
                </div>
                <button
                  onClick={() => setSelectedNode(null)}
                  className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                >
                  <X size={20} />
                </button>
              </div>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                {selectedNode.description}
              </p>
              <div className="grid grid-cols-2 gap-4">
                <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded">
                  <p className="text-sm text-gray-500 dark:text-gray-400">Tasks Completed</p>
                  <p className="text-2xl font-bold text-blue-500">127</p>
                </div>
                <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded">
                  <p className="text-sm text-gray-500 dark:text-gray-400">Success Rate</p>
                  <p className="text-2xl font-bold text-green-500">98%</p>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AIAgentOrgChart;