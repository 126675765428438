import { Agent } from '../types';
import { defaultAgentConfig } from './AIAgentConfig';
import AzureService from './AzureService';

export abstract class BaseAIAgent {
  protected name: Agent;
  protected config = defaultAgentConfig;
  protected azureService: AzureService;

  constructor(name: Agent) {
    this.name = name;
    this.azureService = AzureService.getInstance();
  }

  public async processMessage(message: string): Promise<string> {
    try {
      return await this.azureService.generateCompletion(message, this.name);
    } catch (error) {
      console.error(`Error processing message for ${this.name}:`, error);
      throw error;
    }
  }

  public abstract generateVisualization(data: any): Promise<any>;
  public abstract validateData(data: any): Promise<boolean>;
  public abstract getCapabilities(): string[];
}