import { Agent } from '../types';
import { BaseAIAgent } from './BaseAIAgent';
import { OccamAgent } from './OccamAgent';
import { ProjectManagerAgent } from './ProjectManagerAgent';
import { EVMAgent } from './EVMAgent';

export class AgentFactory {
  private static instance: AgentFactory;
  private agents: Map<Agent, BaseAIAgent> = new Map();

  private constructor() {
    this.initializeAgents();
  }

  public static getInstance(): AgentFactory {
    if (!AgentFactory.instance) {
      AgentFactory.instance = new AgentFactory();
    }
    return AgentFactory.instance;
  }

  private initializeAgents(): void {
    this.agents.set('Occam', new OccamAgent());
    this.agents.set('Project Manager', new ProjectManagerAgent());
    this.agents.set('Earned Value Management Professional', new EVMAgent());
    // Initialize other agents...
  }

  public getAgent(agentType: Agent): BaseAIAgent {
    const agent = this.agents.get(agentType);
    if (!agent) {
      throw new Error(`Agent type ${agentType} not found`);
    }
    return agent;
  }

  public getAllAgents(): BaseAIAgent[] {
    return Array.from(this.agents.values());
  }
}