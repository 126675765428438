import LZString from 'lz-string';

const MAX_ITEMS = 1000; // Set a reasonable limit

export const compressData = (data: any): string => {
  try {
    return LZString.compress(JSON.stringify(data));
  } catch (error) {
    console.warn('Compression failed, falling back to JSON.stringify:', error);
    return JSON.stringify(data);
  }
};

export const decompressData = (compressedData: string): any => {
  try {
    return JSON.parse(LZString.decompress(compressedData));
  } catch (error) {
    console.warn('Decompression failed, falling back to JSON.parse:', error);
    return JSON.parse(compressedData);
  }
};

export const saveToStorage = (key: string, data: any): void => {
  try {
    const compressedData = compressData(data);
    localStorage.setItem(key, compressedData);
  } catch (error) {
    console.warn('Failed to save to localStorage:', error);
    throw error;
  }
};

export const loadFromStorage = (key: string): any => {
  const storedData = localStorage.getItem(key);
  if (storedData) {
    return decompressData(storedData);
  }
  return null;
};