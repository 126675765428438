import { useState, useCallback } from 'react';

interface TestCase {
  id: string;
  name: string;
  description: string;
  test: () => Promise<boolean>;
  category: string;
}

interface TestResult {
  id: string;
  passed: boolean;
  error?: string;
  duration: number;
}

export const useTestSuite = (testCases: TestCase[]) => {
  const [results, setResults] = useState<TestResult[]>([]);
  const [isRunning, setIsRunning] = useState(false);
  const [currentTest, setCurrentTest] = useState<string | null>(null);

  const runTest = useCallback(async (testCase: TestCase): Promise<TestResult> => {
    const startTime = performance.now();
    try {
      const passed = await testCase.test();
      const duration = performance.now() - startTime;
      return {
        id: testCase.id,
        passed,
        duration,
      };
    } catch (error) {
      const duration = performance.now() - startTime;
      return {
        id: testCase.id,
        passed: false,
        error: error instanceof Error ? error.message : 'Unknown error',
        duration,
      };
    }
  }, []);

  const runAllTests = useCallback(async () => {
    setIsRunning(true);
    setResults([]);
    const newResults: TestResult[] = [];

    for (const testCase of testCases) {
      setCurrentTest(testCase.id);
      const result = await runTest(testCase);
      newResults.push(result);
      setResults(prev => [...prev, result]);
    }

    setCurrentTest(null);
    setIsRunning(false);
    return newResults;
  }, [testCases, runTest]);

  const runTestsByCategory = useCallback(async (category: string) => {
    setIsRunning(true);
    setResults([]);
    const newResults: TestResult[] = [];

    const filteredTests = testCases.filter(test => test.category === category);

    for (const testCase of filteredTests) {
      setCurrentTest(testCase.id);
      const result = await runTest(testCase);
      newResults.push(result);
      setResults(prev => [...prev, result]);
    }

    setCurrentTest(null);
    setIsRunning(false);
    return newResults;
  }, [testCases, runTest]);

  return {
    results,
    isRunning,
    currentTest,
    runAllTests,
    runTestsByCategory,
    runTest,
  };
};

export default useTestSuite;