import React from 'react';
import { Brain, CheckCircle, AlertTriangle, Users as UsersIcon } from 'lucide-react';
import { Agent } from '../../types';
import KnowledgeTrainingService from '../../services/KnowledgeTrainingService';

interface AgentTrainingStatusProps {
  agents: Agent[];
}

const AgentTrainingStatus: React.FC<AgentTrainingStatusProps> = ({ agents }) => {
  const trainingService = KnowledgeTrainingService.getInstance();
  const completedCount = agents.filter(a => trainingService.isAgentTrained(a)).length;
  const trainingCount = agents.filter(a => trainingService.getTrainingStatus(a).status === 'training').length;

  return (
    <div className="bg-blue-100 dark:bg-blue-900 p-6 rounded-lg">
      <h3 className="text-lg font-semibold mb-4 text-blue-800 dark:text-blue-200 flex items-center">
        <Brain className="mr-2" size={20} />
        AI Agent Training Status
      </h3>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg">
          <div className="flex items-center justify-between">
            <span className="text-gray-600 dark:text-gray-400">Trained Agents</span>
            <CheckCircle className="text-green-500" size={20} />
          </div>
          <p className="text-2xl font-bold mt-2">{completedCount}</p>
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg">
          <div className="flex items-center justify-between">
            <span className="text-gray-600 dark:text-gray-400">In Training</span>
            <Brain className="text-blue-500" size={20} />
          </div>
          <p className="text-2xl font-bold mt-2">{trainingCount}</p>
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg">
          <div className="flex items-center justify-between">
            <span className="text-gray-600 dark:text-gray-400">Total Agents</span>
            <UsersIcon className="text-gray-500" size={20} />
          </div>
          <p className="text-2xl font-bold mt-2">{agents.length}</p>
        </div>
      </div>

      <div className="text-blue-700 dark:text-blue-300">
        {completedCount === agents.length ? (
          <div className="flex items-center">
            <CheckCircle className="mr-2" size={16} />
            All agents are trained and ready
          </div>
        ) : trainingCount > 0 ? (
          <div className="flex items-center">
            <Brain className="mr-2 animate-pulse" size={16} />
            {trainingCount} agent{trainingCount !== 1 ? 's' : ''} currently training
          </div>
        ) : (
          <div className="flex items-center">
            <AlertTriangle className="mr-2" size={16} />
            Some agents need training
          </div>
        )}
      </div>
    </div>
  );
};

export default AgentTrainingStatus;