import React from 'react';
import { motion } from 'framer-motion';
import { FileText, Trash2, Download, Eye, CheckSquare, Square } from 'lucide-react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

interface ProcessedFile {
  id: string;
  name: string;
  content: string;
  type: string;
  size: number;
  metadata: {
    filename: string;
    type: string;
    lastModified: number;
    category?: string;
  };
}

interface KnowledgeBaseFileListProps {
  files: ProcessedFile[];
  onRemove: (id: string) => void;
  selectedFiles: string[];
  setSelectedFiles: (files: string[]) => void;
  filters: {
    type: string;
    category: string;
    dateRange: string;
  };
  searchQuery: string;
}

const KnowledgeBaseFileList: React.FC<KnowledgeBaseFileListProps> = ({
  files,
  onRemove,
  selectedFiles,
  setSelectedFiles,
  filters,
  searchQuery,
}) => {
  const filteredFiles = files.filter(file => {
    const matchesSearch = 
      file.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      file.content.toLowerCase().includes(searchQuery.toLowerCase());
    
    const matchesType = filters.type === 'all' || file.type.includes(filters.type);
    const matchesCategory = filters.category === 'all' || file.metadata.category === filters.category;
    
    let matchesDate = true;
    const fileDate = new Date(file.metadata.lastModified);
    const now = new Date();
    
    switch (filters.dateRange) {
      case 'today':
        matchesDate = fileDate.toDateString() === now.toDateString();
        break;
      case 'week':
        const weekAgo = new Date(now.setDate(now.getDate() - 7));
        matchesDate = fileDate >= weekAgo;
        break;
      case 'month':
        const monthAgo = new Date(now.setMonth(now.getMonth() - 1));
        matchesDate = fileDate >= monthAgo;
        break;
      case 'year':
        const yearAgo = new Date(now.setFullYear(now.getFullYear() - 1));
        matchesDate = fileDate >= yearAgo;
        break;
    }

    return matchesSearch && matchesType && matchesCategory && matchesDate;
  });

  const toggleFileSelection = (fileId: string) => {
    setSelectedFiles(prev => 
      prev.includes(fileId)
        ? prev.filter(id => id !== fileId)
        : [...prev, fileId]
    );
  };

  const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const file = filteredFiles[index];
    const isSelected = selectedFiles.includes(file.id);

    return (
      <motion.div
        style={style}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md mb-4 ${
          isSelected ? 'ring-2 ring-blue-500' : ''
        }`}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => toggleFileSelection(file.id)}
              className="p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              {isSelected ? (
                <CheckSquare className="text-blue-500" size={20} />
              ) : (
                <Square className="text-gray-400" size={20} />
              )}
            </button>
            <FileText className="text-blue-500" size={24} />
            <div>
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">{file.name}</h3>
              <div className="flex space-x-2 text-sm text-gray-500 dark:text-gray-400">
                <span>{new Date(file.metadata.lastModified).toLocaleDateString()}</span>
                <span>•</span>
                <span>{file.metadata.category || 'Uncategorized'}</span>
                <span>•</span>
                <span>{(file.size / 1024).toFixed(1)} KB</span>
              </div>
            </div>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => window.open(URL.createObjectURL(new Blob([file.content])), '_blank')}
              className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              <Eye size={20} />
            </button>
            <button
              onClick={() => {
                const blob = new Blob([file.content], { type: file.type });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = file.name;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
              }}
              className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              <Download size={20} />
            </button>
            <button
              onClick={() => onRemove(file.id)}
              className="p-2 text-red-500 hover:text-red-600"
            >
              <Trash2 size={20} />
            </button>
          </div>
        </div>
      </motion.div>
    );
  };

  return (
    <div style={{ height: '600px' }}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            width={width}
            itemCount={filteredFiles.length}
            itemSize={120}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    </div>
  );
};

export default KnowledgeBaseFileList;