import React from 'react';
import { Clock, AlertCircle } from 'lucide-react';

interface TestResultsProps {
  testId: string;
  results: Array<{
    id: string;
    passed: boolean;
    error?: string;
    duration: number;
  }>;
}

const TestResults: React.FC<TestResultsProps> = ({ testId, results }) => {
  const result = results.find(r => r.id === testId);

  if (!result) {
    return (
      <div className="text-gray-500 dark:text-gray-400">
        No results available
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-4">
        <div className="flex items-center text-gray-600 dark:text-gray-400">
          <Clock size={16} className="mr-1" />
          <span>{result.duration.toFixed(2)}ms</span>
        </div>
        {result.error && (
          <div className="flex items-center text-red-500">
            <AlertCircle size={16} className="mr-1" />
            <span>Error occurred</span>
          </div>
        )}
      </div>

      {result.error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded">
          <p className="text-red-700 dark:text-red-300">{result.error}</p>
        </div>
      )}

      {!result.error && !result.passed && (
        <div className="bg-yellow-50 dark:bg-yellow-900/20 border-l-4 border-yellow-500 p-4 rounded">
          <p className="text-yellow-700 dark:text-yellow-300">
            Test failed but no error was thrown. This might indicate an assertion failure.
          </p>
        </div>
      )}

      {result.passed && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded">
          <p className="text-green-700 dark:text-green-300">
            Test passed successfully
          </p>
        </div>
      )}
    </div>
  );
};

export default TestResults;