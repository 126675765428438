import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import { Agent } from '../types';

// Constants for Occam's voice configuration
const AZURE_KEY = 'c558aa7aeade45d6b938e5fe1cedb22b';
const AZURE_REGION = 'eastus';
const OCCAM_VOICE = 'en-US-GuyNeural';

class SpeechSynthesisService {
  private static instance: SpeechSynthesisService;
  private speechConfig: sdk.SpeechConfig;
  private synthesizer: sdk.SpeechSynthesizer | null = null;
  private lastSpokenText: string = '';

  private constructor() {
    this.speechConfig = sdk.SpeechConfig.fromSubscription(AZURE_KEY, AZURE_REGION);
    this.speechConfig.speechSynthesisVoiceName = OCCAM_VOICE;
  }

  public static getInstance(): SpeechSynthesisService {
    if (!SpeechSynthesisService.instance) {
      SpeechSynthesisService.instance = new SpeechSynthesisService();
    }
    return SpeechSynthesisService.instance;
  }

  private initializeSynthesizer(): void {
    if (!this.synthesizer) {
      const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput();
      this.synthesizer = new sdk.SpeechSynthesizer(this.speechConfig, audioConfig);
    }
  }

  public async speak(text: string, agent: Agent = 'Occam'): Promise<void> {
    if (agent !== 'Occam') {
      console.log('Only Occam is configured for speech synthesis');
      return;
    }

    // Prevent repeating the same text
    if (text === this.lastSpokenText) {
      console.log('Skipping repeated text');
      return;
    }

    try {
      this.initializeSynthesizer();
      if (!this.synthesizer) {
        throw new Error('Speech synthesizer not initialized');
      }

      this.lastSpokenText = text;
      return new Promise((resolve, reject) => {
        this.synthesizer!.speakTextAsync(
          text,
          result => {
            if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
              resolve();
            } else {
              reject(new Error(`Speech synthesis failed: ${result.errorDetails}`));
            }
          },
          error => {
            reject(error);
          }
        );
      });
    } catch (error) {
      console.error('Error in speech synthesis:', error);
      throw error;
    }
  }

  public pause(): void {
    if (this.synthesizer) {
      this.synthesizer.pauseAsync();
    }
  }

  public resume(): void {
    if (this.synthesizer) {
      this.synthesizer.resumeAsync();
    }
  }

  public stop(): void {
    if (this.synthesizer) {
      this.synthesizer.close();
      this.synthesizer = null;
      this.lastSpokenText = '';
    }
  }

  public cleanup(): void {
    this.stop();
  }
}

export default SpeechSynthesisService;