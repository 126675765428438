import { BaseAIAgent } from './BaseAIAgent';
import { ChartGenerationService } from './ChartGenerationService';
import { IPMDARValidatorService } from './IPMDARValidatorService';

export class EVMAgent extends BaseAIAgent {
  private chartService: ChartGenerationService;
  private validatorService: IPMDARValidatorService;

  constructor() {
    super('Earned Value Management Professional');
    this.chartService = ChartGenerationService.getInstance();
    this.validatorService = IPMDARValidatorService.getInstance();
  }

  public async generateVisualization(data: any): Promise<any> {
    return this.chartService.generateChart({
      type: 'evm',
      data,
      agent: this.name
    });
  }

  public async validateData(data: any): Promise<boolean> {
    return this.validatorService.validateAgentResponse(this.name, data);
  }

  public getCapabilities(): string[] {
    return [
      'EVM Analysis',
      'Cost Control',
      'Performance Measurement',
      'Variance Analysis',
      'Forecasting'
    ];
  }
}