import React from 'react';
import { motion } from 'framer-motion';
import { Shield, AlertTriangle, CheckCircle } from 'lucide-react';

interface ComplianceStatusProps {
  status: {
    ipmdarCompliance: number;
    criticalViolations: number;
    warningCount: number;
    requiredActions: string[];
  };
}

const ComplianceStatus: React.FC<ComplianceStatusProps> = ({ status }) => {
  const getComplianceColor = (score: number) => {
    if (score >= 0.9) return 'text-green-500';
    if (score >= 0.7) return 'text-yellow-500';
    return 'text-red-500';
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
      <h3 className="text-lg font-semibold mb-4 flex items-center">
        <Shield className="mr-2 text-blue-500" size={20} />
        IPMDAR Compliance Status
      </h3>

      <div className="space-y-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
        >
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400">Overall Compliance</p>
            <p className={`text-2xl font-bold ${getComplianceColor(status.ipmdarCompliance)}`}>
              {(status.ipmdarCompliance * 100).toFixed(0)}%
            </p>
          </div>
          {status.ipmdarCompliance >= 0.9 ? (
            <CheckCircle className="text-green-500" size={24} />
          ) : (
            <AlertTriangle className="text-yellow-500" size={24} />
          )}
        </motion.div>

        <div className="grid grid-cols-2 gap-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
          >
            <p className="text-sm text-gray-600 dark:text-gray-400">Critical Violations</p>
            <p className="text-xl font-bold text-red-500">{status.criticalViolations}</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
          >
            <p className="text-sm text-gray-600 dark:text-gray-400">Warnings</p>
            <p className="text-xl font-bold text-yellow-500">{status.warningCount}</p>
          </motion.div>
        </div>

        <div className="mt-6">
          <h4 className="text-md font-semibold mb-3 flex items-center">
            <AlertTriangle className="mr-2 text-yellow-500" size={16} />
            Required Actions
          </h4>
          <div className="space-y-2">
            {status.requiredActions.map((action, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="p-3 bg-gray-50 dark:bg-gray-700 rounded-lg flex items-start"
              >
                <span className="text-gray-400 mr-2">•</span>
                <span className="text-gray-600 dark:text-gray-400">{action}</span>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplianceStatus;