import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, ChevronLeft, ChevronRight, Play, RefreshCw, Filter, Download, AlertCircle, CheckCircle, XCircle, Clock } from 'lucide-react';
import { Line } from 'react-chartjs-2';
import { format, addMonths, subMonths, startOfMonth } from 'date-fns';

interface TestResult {
  id: string;
  name: string;
  description: string;
  timestamp: Date;
  status: 'passed' | 'failed' | 'error';
  duration: number;
  errorLog?: string;
  performance: {
    memoryUsage: number;
    cpuUsage: number;
    responseTime: number;
  };
}

interface TestCase {
  id: string;
  name: string;
  description: string;
  document: string;
  category: string;
}

const TestHistoryDashboard: React.FC = () => {
  const [currentMonth, setCurrentMonth] = useState(startOfMonth(new Date()));
  const [selectedTest, setSelectedTest] = useState<TestResult | null>(null);
  const [isRunning, setIsRunning] = useState(false);
  const [filter, setFilter] = useState<'all' | 'passed' | 'failed'>('all');
  const [sortBy, setSortBy] = useState<'date' | 'name' | 'status'>('date');

  // Simulated test history data
  const [testHistory, setTestHistory] = useState<TestResult[]>([]);

  useEffect(() => {
    // Simulate loading test history
    const generateTestHistory = () => {
      const history: TestResult[] = [];
      const startDate = new Date(2024, 4, 1); // May 2024
      const endDate = new Date();

      for (let date = startDate; date <= endDate; date = addMonths(date, 1)) {
        for (let i = 1; i <= 3; i++) {
          history.push({
            id: `test-${date.getTime()}-${i}`,
            name: `Test Case ${i}`,
            description: `Monthly test case ${i} for IPMDAR validation`,
            timestamp: new Date(date),
            status: Math.random() > 0.3 ? 'passed' : Math.random() > 0.5 ? 'failed' : 'error',
            duration: Math.random() * 1000 + 500,
            errorLog: Math.random() > 0.7 ? 'Error: Validation failed for field X' : undefined,
            performance: {
              memoryUsage: Math.random() * 100,
              cpuUsage: Math.random() * 100,
              responseTime: Math.random() * 200 + 100
            }
          });
        }
      }
      return history;
    };

    setTestHistory(generateTestHistory());
  }, []);

  const handleRunTest = async (testId: string) => {
    setIsRunning(true);
    try {
      // Simulate test execution
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      setTestHistory(prev => {
        const newHistory = [...prev];
        const testIndex = newHistory.findIndex(t => t.id === testId);
        if (testIndex !== -1) {
          newHistory[testIndex] = {
            ...newHistory[testIndex],
            timestamp: new Date(),
            status: Math.random() > 0.2 ? 'passed' : 'failed',
            duration: Math.random() * 1000 + 500,
            performance: {
              memoryUsage: Math.random() * 100,
              cpuUsage: Math.random() * 100,
              responseTime: Math.random() * 200 + 100
            }
          };
        }
        return newHistory;
      });
    } catch (error) {
      console.error('Test execution failed:', error);
    } finally {
      setIsRunning(false);
    }
  };

  const filteredTests = testHistory
    .filter(test => {
      if (filter === 'all') return true;
      return test.status === filter;
    })
    .filter(test => {
      const testMonth = startOfMonth(test.timestamp);
      return testMonth.getTime() === currentMonth.getTime();
    })
    .sort((a, b) => {
      switch (sortBy) {
        case 'date':
          return b.timestamp.getTime() - a.timestamp.getTime();
        case 'name':
          return a.name.localeCompare(b.name);
        case 'status':
          return a.status.localeCompare(b.status);
        default:
          return 0;
      }
    });

  const performanceData = {
    labels: filteredTests.map(test => format(test.timestamp, 'MM/dd/yyyy')),
    datasets: [
      {
        label: 'Response Time (ms)',
        data: filteredTests.map(test => test.performance.responseTime),
        borderColor: 'rgb(59, 130, 246)',
        tension: 0.1
      },
      {
        label: 'CPU Usage (%)',
        data: filteredTests.map(test => test.performance.cpuUsage),
        borderColor: 'rgb(16, 185, 129)',
        tension: 0.1
      }
    ]
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <h2 className="text-2xl font-bold text-gray-800 dark:text-white">Test History</h2>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
            >
              <ChevronLeft size={20} />
            </button>
            <span className="font-medium">
              {format(currentMonth, 'MMMM yyyy')}
            </span>
            <button
              onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
            >
              <ChevronRight size={20} />
            </button>
          </div>
        </div>
        <div className="flex space-x-4">
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value as any)}
            className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
          >
            <option value="all">All Results</option>
            <option value="passed">Passed Only</option>
            <option value="failed">Failed Only</option>
          </select>
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value as any)}
            className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
          >
            <option value="date">Sort by Date</option>
            <option value="name">Sort by Name</option>
            <option value="status">Sort by Status</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="space-y-4">
          {filteredTests.map((test) => (
            <motion.div
              key={test.id}
              layout
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md"
            >
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-semibold">{test.name}</h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                    {test.description}
                  </p>
                  <div className="flex items-center space-x-4 mt-2">
                    <span className="flex items-center text-sm text-gray-500">
                      <Clock size={16} className="mr-1" />
                      {format(test.timestamp, 'MMM d, yyyy HH:mm')}
                    </span>
                    <span className="flex items-center text-sm text-gray-500">
                      <Clock size={16} className="mr-1" />
                      {test.duration.toFixed(0)}ms
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  {test.status === 'passed' && <CheckCircle className="text-green-500" size={24} />}
                  {test.status === 'failed' && <XCircle className="text-red-500" size={24} />}
                  {test.status === 'error' && <AlertCircle className="text-yellow-500" size={24} />}
                  <button
                    onClick={() => handleRunTest(test.id)}
                    disabled={isRunning}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200 flex items-center"
                  >
                    {isRunning ? (
                      <RefreshCw className="animate-spin mr-2" size={16} />
                    ) : (
                      <Play className="mr-2" size={16} />
                    )}
                    Run Test
                  </button>
                </div>
              </div>

              {test.errorLog && (
                <div className="mt-4 p-4 bg-red-50 dark:bg-red-900/20 rounded-lg">
                  <pre className="text-sm text-red-600 dark:text-red-400 whitespace-pre-wrap">
                    {test.errorLog}
                  </pre>
                </div>
              )}

              <div className="mt-4 grid grid-cols-3 gap-4">
                <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
                  <p className="text-sm text-gray-500 dark:text-gray-400">Memory Usage</p>
                  <p className="text-lg font-semibold">
                    {test.performance.memoryUsage.toFixed(1)}%
                  </p>
                </div>
                <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
                  <p className="text-sm text-gray-500 dark:text-gray-400">CPU Usage</p>
                  <p className="text-lg font-semibold">
                    {test.performance.cpuUsage.toFixed(1)}%
                  </p>
                </div>
                <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
                  <p className="text-sm text-gray-500 dark:text-gray-400">Response Time</p>
                  <p className="text-lg font-semibold">
                    {test.performance.responseTime.toFixed(0)}ms
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <div className="space-y-6">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold mb-4">Performance Trends</h3>
            <div className="h-80">
              <Line
                data={performanceData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      beginAtZero: true
                    }
                  }
                }}
              />
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold mb-4">Monthly Summary</h3>
            <div className="grid grid-cols-3 gap-4">
              <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                <p className="text-sm text-gray-500 dark:text-gray-400">Total Tests</p>
                <p className="text-2xl font-bold">{filteredTests.length}</p>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                <p className="text-sm text-gray-500 dark:text-gray-400">Pass Rate</p>
                <p className="text-2xl font-bold text-green-500">
                  {((filteredTests.filter(t => t.status === 'passed').length / filteredTests.length) * 100).toFixed(1)}%
                </p>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                <p className="text-sm text-gray-500 dark:text-gray-400">Avg Duration</p>
                <p className="text-2xl font-bold">
                  {(filteredTests.reduce((acc, test) => acc + test.duration, 0) / filteredTests.length).toFixed(0)}ms
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestHistoryDashboard;