import JSZip from 'jszip';

export interface ProcessedFile {
  id: string;
  name: string;
  content: string;
  type: string;
  size: number;
  metadata: {
    filename: string;
    type: string;
    lastModified: number;
    category?: string;
    tags?: string[];
    embedding?: number[];
  };
}

export const processFile = async (file: File): Promise<ProcessedFile[]> => {
  const processedFiles: ProcessedFile[] = [];

  try {
    if (file.type === 'application/zip') {
      const zip = new JSZip();
      const zipContents = await zip.loadAsync(file);
      
      for (const [filename, zipEntry] of Object.entries(zipContents.files)) {
        if (!zipEntry.dir) {
          const content = await zipEntry.async('string');
          const id = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
          
          processedFiles.push({
            id,
            name: filename,
            content,
            type: zipEntry.comment || 'text/plain',
            size: zipEntry.uncompressedSize || 0,
            metadata: {
              filename,
              type: zipEntry.comment || 'text/plain',
              lastModified: Date.now(),
              category: 'Imported',
              tags: ['imported', filename.split('.').pop() || 'unknown']
            },
          });
        }
      }
    } else {
      const content = await file.text();
      const id = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
      
      processedFiles.push({
        id,
        name: file.name,
        content,
        type: file.type || 'text/plain',
        size: file.size,
        metadata: {
          filename: file.name,
          type: file.type || 'text/plain',
          lastModified: file.lastModified,
          category: 'Imported',
          tags: ['imported', file.name.split('.').pop() || 'unknown']
        },
      });
    }

    return processedFiles;
  } catch (error) {
    console.error('Error processing file:', error);
    throw new Error(`Failed to process file: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
};

export const generateEmbeddings = async (content: string): Promise<number[]> => {
  try {
    // Simulate embedding generation with consistent dimensionality
    const embedding = new Array(384).fill(0).map(() => Math.random());
    return embedding;
  } catch (error) {
    console.error('Error generating embeddings:', error);
    throw new Error(`Failed to generate embeddings: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
};

export const searchVectorDatabase = async (query: string, vectorDb: ProcessedFile[]): Promise<ProcessedFile[]> => {
  try {
    if (!query.trim() || !vectorDb.length) {
      return [];
    }

    // Simulate vector similarity search
    return vectorDb.filter(item => 
      item.content.toLowerCase().includes(query.toLowerCase()) ||
      Object.values(item.metadata).some(value => 
        value.toString().toLowerCase().includes(query.toLowerCase())
      )
    );
  } catch (error) {
    console.error('Error searching vector database:', error);
    throw new Error(`Failed to search vector database: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
};