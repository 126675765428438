import React, { useState } from 'react';
import { Package, Download, Upload, Settings, RefreshCw, Save, AlertTriangle, CheckCircle, X, Database, Bot, Activity, BarChart2, FileText, Book, Brain, Users } from 'lucide-react';

const InstallationBuilder: React.FC = () => {
  const [buildConfig, setBuildConfig] = useState({
    version: '1.0.0',
    includeAIAgents: true,
    includeKnowledgeBase: true,
    includeIPMDARTools: true,
    customBranding: false,
    companyName: '',
    companyLogo: '',
    deploymentType: 'standalone',
    databaseConfig: {
      type: 'mssql',
      includeDemo: true
    },
    components: {
      dashboard: true,
      knowledgeBase: true,
      healthMonitoring: true,
      reporting: true,
      riskyProject: true,
      aiAgents: true,
      databaseInterface: true,
      userManagement: true,
      helpDesk: true,
      installationBuilder: true
    }
  });
  const [isBuilding, setIsBuilding] = useState(false);
  const [buildStatus, setBuildStatus] = useState<'idle' | 'building' | 'success' | 'error'>('idle');
  const [buildMessage, setBuildMessage] = useState('');

  const handleConfigChange = (key: string, value: any) => {
    setBuildConfig(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleComponentChange = (key: string, value: boolean) => {
    setBuildConfig(prev => ({
      ...prev,
      components: {
        ...prev.components,
        [key]: value
      }
    }));
  };

  const handleDatabaseConfigChange = (key: string, value: any) => {
    setBuildConfig(prev => ({
      ...prev,
      databaseConfig: {
        ...prev.databaseConfig,
        [key]: value
      }
    }));
  };

  const handleBuild = async () => {
    setIsBuilding(true);
    setBuildStatus('building');
    setBuildMessage('Building installation package...');

    try {
      // Simulate build process
      await new Promise(resolve => setTimeout(resolve, 3000));
      setBuildStatus('success');
      setBuildMessage('Installation package built successfully!');
    } catch (error) {
      setBuildStatus('error');
      setBuildMessage('Error building installation package. Please try again.');
    } finally {
      setIsBuilding(false);
    }
  };

  const components = [
    { id: 'dashboard', name: 'Dashboard', icon: BarChart2, description: 'Main dashboard interface with key metrics and AI assistance' },
    { id: 'knowledgeBase', name: 'Knowledge Base', icon: Book, description: 'Centralized knowledge repository for IPMDAR and project management' },
    { id: 'healthMonitoring', name: 'Health Monitoring', icon: Activity, description: 'Monitor IPMDAR submissions and system health' },
    { id: 'reporting', name: 'Reporting & Analytics', icon: FileText, description: 'Comprehensive reporting and analytics tools' },
    { id: 'riskyProject', name: 'RiskyProject', icon: AlertTriangle, description: 'Risk analysis and management tools' },
    { id: 'aiAgents', name: 'AI Agents', icon: Bot, description: 'AI-powered assistance and automation' },
    { id: 'databaseInterface', name: 'Database Interface', icon: Database, description: 'Direct database management interface' },
    { id: 'userManagement', name: 'User Management', icon: Users, description: 'User account and permission management' },
    { id: 'helpDesk', name: 'Help Desk', icon: AlertTriangle, description: 'Support ticket system and documentation' },
    { id: 'installationBuilder', name: 'Installation Builder', icon: Package, description: 'Package configuration and deployment tools' }
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center">
          <Package className="mr-2 text-blue-500" size={24} />
          Installation Builder
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={handleBuild}
            disabled={isBuilding}
            className={`bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200 flex items-center ${
              isBuilding ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isBuilding ? (
              <RefreshCw className="animate-spin mr-2" size={20} />
            ) : (
              <Package className="mr-2" size={20} />
            )}
            {isBuilding ? 'Building...' : 'Build Installation'}
          </button>
        </div>
      </div>

      {buildStatus !== 'idle' && (
        <div className={`p-4 rounded-lg ${
          buildStatus === 'building' ? 'bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200' :
          buildStatus === 'success' ? 'bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200' :
          'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200'
        }`}>
          <div className="flex items-center">
            {buildStatus === 'building' && <RefreshCw className="animate-spin mr-2" size={20} />}
            {buildStatus === 'success' && <CheckCircle className="mr-2" size={20} />}
            {buildStatus === 'error' && <AlertTriangle className="mr-2" size={20} />}
            <span>{buildMessage}</span>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4 text-gray-800 dark:text-white">Basic Configuration</h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Version</label>
              <input
                type="text"
                value={buildConfig.version}
                onChange={(e) => handleConfigChange('version', e.target.value)}
                className="mt-1 block w-full border rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Deployment Type</label>
              <select
                value={buildConfig.deploymentType}
                onChange={(e) => handleConfigChange('deploymentType', e.target.value)}
                className="mt-1 block w-full border rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
              >
                <option value="standalone">Standalone</option>
                <option value="client-server">Client-Server</option>
                <option value="web">Web-Based</option>
              </select>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4 text-gray-800 dark:text-white">Components</h3>
          <div className="space-y-4">
            {components.map(component => (
              <div key={component.id} className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
                <div className="flex items-center space-x-3">
                  <component.icon className="text-blue-500" size={20} />
                  <div>
                    <p className="font-medium text-gray-700 dark:text-gray-300">{component.name}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{component.description}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={buildConfig.components[component.id as keyof typeof buildConfig.components]}
                    onChange={(e) => handleComponentChange(component.id, e.target.checked)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4 text-gray-800 dark:text-white">Database Configuration</h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Database Type</label>
              <select
                value={buildConfig.databaseConfig.type}
                onChange={(e) => handleDatabaseConfigChange('type', e.target.value)}
                className="mt-1 block w-full border rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
              >
                <option value="mssql">Microsoft SQL Server</option>
                <option value="postgresql">PostgreSQL</option>
                <option value="sqlite">SQLite</option>
              </select>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={buildConfig.databaseConfig.includeDemo}
                onChange={(e) => handleDatabaseConfigChange('includeDemo', e.target.checked)}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-700 dark:text-gray-300">Include Demo Data</label>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4 text-gray-800 dark:text-white">Branding</h3>
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={buildConfig.customBranding}
                onChange={(e) => handleConfigChange('customBranding', e.target.checked)}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-700 dark:text-gray-300">Enable Custom Branding</label>
            </div>
            {buildConfig.customBranding && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Company Name</label>
                  <input
                    type="text"
                    value={buildConfig.companyName}
                    onChange={(e) => handleConfigChange('companyName', e.target.value)}
                    className="mt-1 block w-full border rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Company Logo</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                        handleConfigChange('companyLogo', URL.createObjectURL(file));
                      }
                    }}
                    className="mt-1 block w-full text-sm text-gray-500 dark:text-gray-400
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-full file:border-0
                      file:text-sm file:font-semibold
                      file:bg-blue-50 file:text-blue-700
                      hover:file:bg-blue-100
                      dark:file:bg-gray-700 dark:file:text-gray-300"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallationBuilder;