import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

interface DocumentPreviewProps {
  document: {
    name: string;
    type: string;
    content: string;
  };
  currentIndex: number;
  totalDocuments: number;
  onPrevious: () => void;
  onNext: () => void;
  onScroll: (e: React.UIEvent<HTMLDivElement>) => void;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  document,
  currentIndex,
  totalDocuments,
  onPrevious,
  onNext,
  onScroll,
}) => {
  return (
    <div className="flex-1 p-4">
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={onPrevious}
          className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
        >
          <ChevronLeft size={20} />
        </button>
        <span className="font-medium">
          {currentIndex + 1} of {totalDocuments}
        </span>
        <button
          onClick={onNext}
          className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
        >
          <ChevronRight size={20} />
        </button>
      </div>

      <div 
        className="overflow-x-auto overflow-y-auto max-h-[calc(90vh-16rem)]"
        onScroll={onScroll}
      >
        <div className="min-w-full">
          <SyntaxHighlighter
            language={document.type === 'json' ? 'json' : 'xml'}
            style={atomOneDark}
            showLineNumbers
            customStyle={{
              margin: 0,
              borderRadius: '0.5rem',
            }}
          >
            {document.content}
          </SyntaxHighlighter>
        </div>
      </div>
    </div>
  );
};

export default DocumentPreview;