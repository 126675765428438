import React from 'react';
import { motion } from 'framer-motion';
import { Database, CheckCircle, AlertTriangle, Zap, Clock } from 'lucide-react';
import { Line } from 'react-chartjs-2';

interface DataQualityMetricsProps {
  metrics: {
    completeness: number;
    accuracy: number;
    consistency: number;
    timeliness: number;
  };
}

const DataQualityMetrics: React.FC<DataQualityMetricsProps> = ({ metrics }) => {
  const getMetricColor = (value: number) => {
    if (value >= 0.9) return 'text-green-500';
    if (value >= 0.7) return 'text-yellow-500';
    return 'text-red-500';
  };

  const getMetricIcon = (value: number) => {
    if (value >= 0.9) return <CheckCircle size={20} className="text-green-500" />;
    if (value >= 0.7) return <AlertTriangle size={20} className="text-yellow-500" />;
    return <AlertTriangle size={20} className="text-red-500" />;
  };

  const chartData = {
    labels: ['Completeness', 'Accuracy', 'Consistency', 'Timeliness'],
    datasets: [
      {
        label: 'Current Quality Score',
        data: [
          metrics.completeness * 100,
          metrics.accuracy * 100,
          metrics.consistency * 100,
          metrics.timeliness * 100
        ],
        fill: true,
        backgroundColor: 'rgba(59, 130, 246, 0.2)',
        borderColor: 'rgb(59, 130, 246)',
        tension: 0.4
      },
      {
        label: 'Quality Threshold',
        data: [90, 90, 90, 90],
        fill: false,
        borderColor: 'rgba(239, 68, 68, 0.5)',
        borderDash: [5, 5],
        tension: 0.4
      }
    ]
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
      <h3 className="text-lg font-semibold mb-4 flex items-center">
        <Database className="mr-2 text-blue-500" size={20} />
        Data Quality Metrics
      </h3>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="space-y-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">Data Completeness</p>
                <p className={`text-xl font-bold ${getMetricColor(metrics.completeness)}`}>
                  {(metrics.completeness * 100).toFixed(0)}%
                </p>
              </div>
              {getMetricIcon(metrics.completeness)}
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">Data Accuracy</p>
                <p className={`text-xl font-bold ${getMetricColor(metrics.accuracy)}`}>
                  {(metrics.accuracy * 100).toFixed(0)}%
                </p>
              </div>
              {getMetricIcon(metrics.accuracy)}
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">Data Consistency</p>
                <p className={`text-xl font-bold ${getMetricColor(metrics.consistency)}`}>
                  {(metrics.consistency * 100).toFixed(0)}%
                </p>
              </div>
              {getMetricIcon(metrics.consistency)}
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">Data Timeliness</p>
                <p className={`text-xl font-bold ${getMetricColor(metrics.timeliness)}`}>
                  {(metrics.timeliness * 100).toFixed(0)}%
                </p>
              </div>
              {getMetricIcon(metrics.timeliness)}
            </div>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          className="h-80"
        >
          <Line
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                r: {
                  min: 0,
                  max: 100,
                  beginAtZero: true,
                  ticks: {
                    stepSize: 20
                  }
                }
              },
              plugins: {
                legend: {
                  position: 'bottom'
                }
              }
            }}
          />
        </motion.div>
      </div>

      <div className="mt-6 p-4 bg-blue-50 dark:bg-blue-900/30 rounded-lg">
        <h4 className="font-semibold text-blue-800 dark:text-blue-200 flex items-center mb-2">
          <Zap className="mr-2" size={16} />
          Quality Insights
        </h4>
        <ul className="space-y-2 text-blue-700 dark:text-blue-300">
          <li className="flex items-center">
            <Clock className="mr-2" size={14} />
            Last data refresh: {new Date().toLocaleString()}
          </li>
          <li className="flex items-center">
            <AlertTriangle className="mr-2" size={14} />
            {metrics.completeness < 0.9 && 'Data completeness needs improvement'}
            {metrics.accuracy < 0.9 && 'Data accuracy requires attention'}
            {metrics.consistency < 0.9 && 'Data consistency should be enhanced'}
            {metrics.timeliness < 0.9 && 'Data timeliness could be improved'}
            {Object.values(metrics).every(m => m >= 0.9) && 'All quality metrics are within acceptable ranges'}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DataQualityMetrics;