import React from 'react';
import { Users, Brain, Bot } from 'lucide-react';
import SlidingPanel from './SlidingPanel';
import { Agent } from '../types';

interface AgentPanelProps {
  isOpen: boolean;
  onClose: () => void;
  selectedAgent: Agent;
  onAgentChange: (agent: Agent) => void;
  agents: Agent[];
}

const AgentPanel: React.FC<AgentPanelProps> = ({
  isOpen,
  onClose,
  selectedAgent,
  onAgentChange,
  agents,
}) => {
  return (
    <SlidingPanel
      isOpen={isOpen}
      onClose={onClose}
      title="AI Agents"
      position="left"
      width="w-80"
    >
      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-semibold flex items-center mb-4">
            <Users className="mr-2" />
            Available Agents
          </h3>
          <div className="space-y-2">
            {agents.map((agent) => (
              <button
                key={agent}
                onClick={() => onAgentChange(agent)}
                className={`w-full p-3 rounded-lg flex items-center transition-colors duration-200 ${
                  selectedAgent === agent
                    ? 'bg-primary-500 text-white'
                    : 'bg-light-primary dark:bg-dark-primary text-gray-800 dark:text-gray-200 hover:bg-light-secondary dark:hover:bg-dark-secondary'
                }`}
              >
                <Bot size={20} className="mr-3" />
                <span>{agent}</span>
              </button>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-semibold flex items-center">
            <Brain className="mr-2" />
            Agent Details
          </h3>
          {selectedAgent && (
            <div className="p-4 bg-light-primary dark:bg-dark-primary rounded-lg">
              <h4 className="font-semibold mb-2">{selectedAgent}</h4>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Specialized in handling tasks related to {selectedAgent.toLowerCase()} operations and analysis.
              </p>
              <div className="mt-4">
                <h5 className="text-sm font-semibold mb-2">Capabilities:</h5>
                <ul className="text-sm space-y-1 list-disc list-inside text-gray-600 dark:text-gray-400">
                  <li>IPMDAR data analysis</li>
                  <li>Performance metrics evaluation</li>
                  <li>Risk assessment and mitigation</li>
                  <li>Project status reporting</li>
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className="pt-4 border-t dark:border-gray-700">
          <button
            onClick={onClose}
            className="w-full px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200"
          >
            Close Agent Panel
          </button>
        </div>
      </div>
    </SlidingPanel>
  );
};

export default AgentPanel;