import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { GitCompare, Check, X, AlertTriangle, ArrowRight, Eye, EyeOff } from 'lucide-react';

interface Solution {
  id: string;
  name: string;
  complexity: number;
  feasibility: number;
  resourceCost: number;
  timeToImplement: number;
  risks: string[];
  benefits: string[];
}

interface SolutionComparisonProps {
  solutions: Solution[];
  onSelect: (solutionId: string) => void;
  selectedId?: string;
}

const SolutionComparison: React.FC<SolutionComparisonProps> = ({
  solutions,
  onSelect,
  selectedId
}) => {
  const [expandedSolution, setExpandedSolution] = useState<string | null>(null);
  const [showDetails, setShowDetails] = useState(true);

  const getScoreColor = (score: number) => {
    if (score >= 8) return 'text-green-500';
    if (score >= 5) return 'text-yellow-500';
    return 'text-red-500';
  };

  const toggleSolutionExpansion = (id: string) => {
    setExpandedSolution(expandedSolution === id ? null : id);
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-semibold flex items-center">
          <GitCompare className="mr-2 text-blue-500" size={20} />
          Solution Comparison
        </h3>
        <button
          onClick={() => setShowDetails(!showDetails)}
          className="flex items-center text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
        >
          {showDetails ? (
            <>
              <EyeOff size={16} className="mr-1" />
              Hide Details
            </>
          ) : (
            <>
              <Eye size={16} className="mr-1" />
              Show Details
            </>
          )}
        </button>
      </div>

      <div className="space-y-4">
        {solutions.map((solution) => (
          <motion.div
            key={solution.id}
            layout
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className={`p-4 rounded-lg border transition-colors duration-200 ${
              selectedId === solution.id
                ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/30'
                : 'border-gray-200 dark:border-gray-700'
            }`}
          >
            <div className="flex justify-between items-start">
              <div className="flex-1">
                <div className="flex items-center">
                  <h4 className="font-medium text-gray-900 dark:text-white">
                    {solution.name}
                  </h4>
                  {selectedId === solution.id && (
                    <span className="ml-2 px-2 py-1 text-xs bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full">
                      Selected
                    </span>
                  )}
                </div>
                
                {showDetails && (
                  <div className="mt-2 grid grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-600 dark:text-gray-400">
                          Complexity
                        </span>
                        <span className={`font-medium ${getScoreColor(10 - solution.complexity)}`}>
                          {10 - solution.complexity}/10
                        </span>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-600 dark:text-gray-400">
                          Feasibility
                        </span>
                        <span className={`font-medium ${getScoreColor(solution.feasibility)}`}>
                          {solution.feasibility}/10
                        </span>
                      </div>
                    </div>
                    <div className="space-y-1">
                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-600 dark:text-gray-400">
                          Resource Cost
                        </span>
                        <span className={`font-medium ${getScoreColor(10 - solution.resourceCost)}`}>
                          {10 - solution.resourceCost}/10
                        </span>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-600 dark:text-gray-400">
                          Time to Implement
                        </span>
                        <span className="font-medium text-gray-900 dark:text-white">
                          {solution.timeToImplement} days
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex items-center space-x-2">
                <button
                  onClick={() => toggleSolutionExpansion(solution.id)}
                  className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
                >
                  {expandedSolution === solution.id ? (
                    <motion.div
                      initial={{ rotate: 0 }}
                      animate={{ rotate: 180 }}
                      transition={{ duration: 0.2 }}
                    >
                      <ArrowRight size={20} />
                    </motion.div>
                  ) : (
                    <ArrowRight size={20} />
                  )}
                </button>
                <button
                  onClick={() => onSelect(solution.id)}
                  className={`p-2 rounded-full transition-colors duration-200 ${
                    selectedId === solution.id
                      ? 'bg-blue-500 text-white'
                      : 'hover:bg-gray-100 dark:hover:bg-gray-700'
                  }`}
                >
                  {selectedId === solution.id ? (
                    <Check size={20} />
                  ) : (
                    <Eye size={20} />
                  )}
                </button>
              </div>
            </div>

            <AnimatePresence>
              {expandedSolution === solution.id && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="mt-4 pt-4 border-t dark:border-gray-700"
                >
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <h5 className="font-medium mb-2 flex items-center">
                        <Check className="text-green-500 mr-2" size={16} />
                        Benefits
                      </h5>
                      <ul className="space-y-2">
                        {solution.benefits.map((benefit, index) => (
                          <li
                            key={index}
                            className="flex items-start text-sm text-gray-600 dark:text-gray-400"
                          >
                            <span className="w-1 h-1 bg-green-500 rounded-full mt-2 mr-2" />
                            {benefit}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h5 className="font-medium mb-2 flex items-center">
                        <AlertTriangle className="text-yellow-500 mr-2" size={16} />
                        Risks
                      </h5>
                      <ul className="space-y-2">
                        {solution.risks.map((risk, index) => (
                          <li
                            key={index}
                            className="flex items-start text-sm text-gray-600 dark:text-gray-400"
                          >
                            <span className="w-1 h-1 bg-yellow-500 rounded-full mt-2 mr-2" />
                            {risk}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default SolutionComparison;