import axios from 'axios';

interface TicketData {
  subject: string;
  category: string;
  priority: string;
  description: string;
  email: string;
}

class HelpDeskService {
  private static instance: HelpDeskService;
  private baseURL: string = '/api/helpdesk';

  private constructor() {}

  public static getInstance(): HelpDeskService {
    if (!HelpDeskService.instance) {
      HelpDeskService.instance = new HelpDeskService();
    }
    return HelpDeskService.instance;
  }

  public async submitTicket(ticketData: TicketData): Promise<{ success: boolean; message: string }> {
    try {
      const response = await axios.post(`${this.baseURL}/tickets`, ticketData);
      return {
        success: true,
        message: 'Ticket submitted successfully'
      };
    } catch (error) {
      console.error('Error submitting ticket:', error);
      throw new Error(error instanceof Error ? error.message : 'Failed to submit ticket');
    }
  }

  public async getTicketStatus(ticketId: string): Promise<any> {
    try {
      const response = await axios.get(`${this.baseURL}/tickets/${ticketId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching ticket status:', error);
      throw new Error(error instanceof Error ? error.message : 'Failed to fetch ticket status');
    }
  }
}

export default HelpDeskService;