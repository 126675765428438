import React, { useState } from 'react';
import { 
  LayoutDashboard, 
  Database, 
  Activity, 
  BarChart2, 
  AlertTriangle, 
  Users, 
  Server, 
  MessageCircle, 
  Edit, 
  ThumbsUp, 
  ThumbsDown, 
  Send, 
  ChevronRight, 
  ChevronUp, 
  ChevronDown, 
  Brain, 
  Save, 
  FileText, 
  Book, 
  HelpCircle, 
  Package, 
  Cog, 
  Settings,
  Wrench,
  GitMerge,
  MessageSquare,
  PencilLine
} from 'lucide-react';
import { SidebarProps, MenuItem } from '../types';

const Sidebar: React.FC<SidebarProps> = ({ setCurrentView, currentView, userRole }) => {
  const [expandedSections, setExpandedSections] = useState<string[]>(['main']);

  const menuItems: { section: string; items: MenuItem[] }[] = [
    {
      section: 'main',
      items: [
        { 
          id: 'dashboard', 
          icon: 'LayoutDashboard', 
          label: 'Occam & Worker Bees Interface', 
          view: 'dashboard' 
        },
        { 
          id: 'knowledgeBase', 
          icon: 'Book', 
          label: 'Knowledge Base', 
          view: 'data' 
        },
        { 
          id: 'ipmdarHealth', 
          icon: 'Activity', 
          label: 'IPMDAR Health', 
          view: 'health' 
        },
        { 
          id: 'reporting', 
          icon: 'BarChart2', 
          label: 'Reporting & Analytics', 
          view: 'reports' 
        },
        { 
          id: 'riskyProject', 
          icon: 'AlertTriangle', 
          label: 'RiskyProject', 
          view: 'riskyproject' 
        },
        { 
          id: 'aiAgents', 
          icon: 'Users', 
          label: 'AI Agents Org Chart', 
          view: 'aiagents' 
        },
        { 
          id: 'helpDesk', 
          icon: 'HelpCircle', 
          label: 'Help Desk', 
          view: 'helpdesk' 
        },
        { 
          id: 'userFeedback', 
          icon: 'MessageSquare', 
          label: 'User Feedback', 
          view: 'feedback' 
        },
        { 
          id: 'editSidebar', 
          icon: 'PencilLine', 
          label: 'Edit Sidebar', 
          view: 'editSidebar' 
        }
      ]
    },
    {
      section: 'admin',
      items: userRole === 'Administrator' || userRole === 'Super Administrator' ? [
        { 
          id: 'database', 
          icon: 'Database', 
          label: 'Database Interface', 
          view: 'database' 
        },
        { 
          id: 'userManagement', 
          icon: 'Users', 
          label: 'User Management', 
          view: 'usermanagement' 
        },
        { 
          id: 'occamEval', 
          icon: 'FileText', 
          label: 'OccamEval Test', 
          view: 'occameval' 
        },
        {
          id: 'tools',
          icon: 'Wrench',
          label: 'Tools & Utilities',
          view: 'tools'
        }
      ] : []
    },
    {
      section: 'superAdmin',
      items: userRole === 'Super Administrator' ? [
        { 
          id: 'installer', 
          icon: 'Package', 
          label: 'Installation Builder', 
          view: 'installer' 
        },
        { 
          id: 'decouplingInterop', 
          icon: 'GitMerge', 
          label: 'Decoupling & Interoperability', 
          view: 'decouplingInterop' 
        }
      ] : []
    }
  ];

  const toggleSection = (section: string) => {
    setExpandedSections(prev => 
      prev.includes(section) 
        ? prev.filter(s => s !== section)
        : [...prev, section]
    );
  };

  const getIcon = (iconName: string) => {
    const icons: { [key: string]: React.ElementType } = {
      LayoutDashboard,
      Database,
      Activity,
      BarChart2,
      AlertTriangle,
      Users,
      Server,
      MessageCircle,
      Edit,
      ThumbsUp,
      ThumbsDown,
      Send,
      ChevronRight,
      ChevronUp,
      ChevronDown,
      Brain,
      Save,
      FileText,
      Book,
      HelpCircle,
      Package,
      Cog,
      Settings,
      Wrench,
      GitMerge,
      MessageSquare,
      PencilLine
    };
    return icons[iconName] || LayoutDashboard;
  };

  return (
    <div className="w-64 bg-gray-800 text-white p-4 flex flex-col h-screen">
      <div className="flex items-center mb-8">
        <Brain className="h-8 w-8 text-blue-500" />
        <span className="ml-2 text-xl font-bold">Occam AI</span>
      </div>

      <nav className="flex-1 space-y-4">
        {menuItems.map(({ section, items }) => items.length > 0 && (
          <div key={section} className="space-y-2">
            <div
              className="flex items-center justify-between cursor-pointer hover:bg-gray-700 p-2 rounded"
              onClick={() => toggleSection(section)}
            >
              <span className="text-sm font-semibold uppercase text-gray-400">
                {section === 'main' ? 'Main Navigation' : 
                 section === 'admin' ? 'Administration' : 
                 'System Configuration'}
              </span>
              {expandedSections.includes(section) ? 
                <ChevronUp size={16} /> : 
                <ChevronDown size={16} />
              }
            </div>
            
            {expandedSections.includes(section) && (
              <div className="space-y-1 ml-2">
                {items.map((item) => {
                  const Icon = getIcon(item.icon);
                  return (
                    <button
                      key={item.id}
                      onClick={() => setCurrentView(item.view)}
                      className={`flex items-center space-x-2 w-full p-2 rounded transition-colors duration-200 ${
                        currentView === item.view
                          ? 'bg-blue-600 text-white'
                          : 'text-gray-300 hover:bg-gray-700'
                      }`}
                    >
                      <Icon size={20} />
                      <span>{item.label}</span>
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        ))}
      </nav>

      <div className="pt-4 border-t border-gray-700">
        <div className="text-sm text-gray-400">
          <p>Copyright 2024 - SNA Software LLC - All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;