import React, { useState, useEffect, useRef } from 'react';
import { Database, Edit, Trash2, Plus, Save, X, ChevronDown, ChevronUp, RefreshCw, Search, Download, Upload, ChevronLeft, ChevronRight } from 'lucide-react';
import { ensureSerializable } from '../utils/serialization';

interface Entity {
  name: string;
  attributes: string[];
  data: any[];
}

const DatabaseInterface: React.FC = () => {
  const [entities, setEntities] = useState<Entity[]>([]);
  const [activeEntity, setActiveEntity] = useState<string | null>(null);
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [newRow, setNewRow] = useState<any>({});
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Simulated API call to fetch entities
      await new Promise(resolve => setTimeout(resolve, 1000));
      const mockEntities: Entity[] = [
        {
          name: 'User',
          attributes: ['id', 'firstName', 'lastName', 'email'],
          data: [
            { id: 1, firstName: 'John', lastName: 'Doe', email: 'john@example.com' },
            { id: 2, firstName: 'Jane', lastName: 'Smith', email: 'jane@example.com' },
          ],
        },
        {
          name: 'Project',
          attributes: ['id', 'name', 'startDate', 'endDate', 'status'],
          data: [
            { id: 1, name: 'Website Redesign', startDate: '2023-01-01', endDate: '2023-06-30', status: 'In Progress' },
            { id: 2, name: 'Mobile App Development', startDate: '2023-03-15', endDate: '2023-12-31', status: 'Planning' },
          ],
        },
      ];
      setEntities(mockEntities);
    } catch (error) {
      console.error('Error fetching data:', error);
      alert('Failed to fetch data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEntitySelect = (entityName: string) => {
    setActiveEntity(entityName);
    setEditingRow(null);
    setNewRow({});
  };

  const handleEdit = (index: number) => {
    setEditingRow(index);
    setNewRow(entities.find(e => e.name === activeEntity)?.data[index] || {});
  };

  const handleSave = (index: number) => {
    setIsSaving(true);
    const updatedEntities = entities.map(entity => {
      if (entity.name === activeEntity) {
        const updatedData = [...entity.data];
        updatedData[index] = newRow;
        return { ...entity, data: updatedData };
      }
      return entity;
    });
    setEntities(updatedEntities);
    setEditingRow(null);
    setNewRow({});
    setIsSaving(false);
  };

  const handleDelete = (index: number) => {
    setIsSaving(true);
    const updatedEntities = entities.map(entity => {
      if (entity.name === activeEntity) {
        const updatedData = entity.data.filter((_, i) => i !== index);
        return { ...entity, data: updatedData };
      }
      return entity;
    });
    setEntities(updatedEntities);
    setIsSaving(false);
  };

  const handleAdd = () => {
    setIsSaving(true);
    const updatedEntities = entities.map(entity => {
      if (entity.name === activeEntity) {
        return { ...entity, data: [...entity.data, newRow] };
      }
      return entity;
    });
    setEntities(updatedEntities);
    setNewRow({});
    setIsSaving(false);
  };

  const handleInputChange = (attribute: string, value: string) => {
    setNewRow(prev => ({ ...prev, [attribute]: value }));
  };

  const toggleRowExpansion = (index: number) => {
    setExpandedRows(prev =>
      prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
    );
  };

  const handleImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const content = e.target?.result as string;
          let importedEntities: Entity[];
          
          try {
            importedEntities = JSON.parse(content);
          } catch (parseError) {
            throw new Error('Invalid JSON format');
          }
          
          if (!Array.isArray(importedEntities)) {
            throw new Error('Imported data is not an array');
          }
          
          importedEntities.forEach((entity, index) => {
            if (!entity.name || !Array.isArray(entity.attributes) || !Array.isArray(entity.data)) {
              throw new Error(`Invalid entity at index ${index}`);
            }
          });

          setEntities(importedEntities);
          alert('Entities imported successfully!');
        } catch (error) {
          console.error('Error parsing imported file:', error);
          alert(`Error importing file: ${error instanceof Error ? error.message : 'Unknown error'}. Please make sure it's a valid JSON file containing an array of entities.`);
        }
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        alert('Error reading file. Please try again.');
      };
      reader.readAsText(file);
    }
  };

  const handleExport = () => {
    const dataStr = JSON.stringify(ensureSerializable(entities), null, 2);
    const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
    const exportFileDefaultName = 'database_export.json';

    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  };

  return (
    <div className="space-y-6 p-6 bg-gray-100 dark:bg-gray-900 min-h-screen">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white">Database Interface</h2>
        <div className="flex space-x-2">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImport}
            className="hidden"
            accept=".json"
          />
          <button 
            onClick={() => fileInputRef.current?.click()} 
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200 flex items-center"
          >
            <Upload size={20} className="mr-2" />
            Import
          </button>
          <button 
            onClick={handleExport}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors duration-200 flex items-center"
          >
            <Download size={20} className="mr-2" />
            Export
          </button>
          <button 
            onClick={fetchData}
            className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition-colors duration-200 flex items-center"
          >
            <RefreshCw size={20} className="mr-2" />
            Refresh Data
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <RefreshCw size={48} className="animate-spin text-blue-500" />
        </div>
      ) : (
        <>
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-white">Select Entity</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {entities.map(entity => (
                <button
                  key={entity.name}
                  onClick={() => handleEntitySelect(entity.name)}
                  className={`p-4 rounded-lg shadow transition-all duration-200 ${
                    activeEntity === entity.name
                      ? 'bg-blue-500 text-white'
                      : 'bg-white dark:bg-gray-700 text-gray-800 dark:text-white hover:bg-blue-100 dark:hover:bg-blue-900'
                  }`}
                >
                  {entity.name}
                </button>
              ))}
            </div>
          </div>
          {activeEntity && (
            <>
              <h3 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">{activeEntity} Data</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white dark:bg-gray-800">
                  <thead className="bg-gray-100 dark:bg-gray-700">
                    <tr>
                      <th className="py-2 px-4 text-left"></th>
                      {entities.find(e => e.name === activeEntity)?.attributes.map(attr => (
                        <th key={attr} className="py-2 px-4 text-left text-gray-600 dark:text-gray-300">{attr}</th>
                      ))}
                      <th className="py-2 px-4 text-left text-gray-600 dark:text-gray-300">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entities.find(e => e.name === activeEntity)?.data.map((row, index) => (
                      <React.Fragment key={index}>
                        <tr className={index % 2 === 0 ? 'bg-gray-50 dark:bg-gray-900' : 'bg-white dark:bg-gray-800'}>
                          <td className="py-2 px-4">
                            <button onClick={() => toggleRowExpansion(index)}>
                              {expandedRows.includes(index) ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                            </button>
                          </td>
                          {entities.find(e => e.name === activeEntity)?.attributes.map(attr => (
                            <td key={attr} className="py-2 px-4 text-gray-800 dark:text-gray-200">
                              {editingRow === index ? (
                                <input
                                  type="text"
                                  value={newRow[attr] || ''}
                                  onChange={(e) => handleInputChange(attr, e.target.value)}
                                  className="w-full p-1 border rounded dark:bg-gray-700 dark:text-white dark:border-gray-600"
                                />
                              ) : (
                                row[attr]
                              )}
                            </td>
                          ))}
                          <td className="py-2 px-4">
                            {editingRow === index ? (
                              <button onClick={() => handleSave(index)} className="text-green-500 mr-2">
                                <Save size={20} />
                              </button>
                            ) : (
                              <button onClick={() => handleEdit(index)} className="text-blue-500 mr-2">
                                <Edit size={20} />
                              </button>
                            )}
                            <button onClick={() => handleDelete(index)} className="text-red-500">
                              <Trash2 size={20} />
                            </button>
                          </td>
                        </tr>
                        {expandedRows.includes(index) && (
                          <tr>
                            <td colSpan={entities.find(e => e.name === activeEntity)?.attributes.length + 2} className="bg-gray-100 dark:bg-gray-700 p-4">
                              <pre className="text-sm text-gray-800 dark:text-gray-200">{JSON.stringify(row, null, 2)}</pre>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-6 bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
                <h3 className="text-lg font-semibold mb-4 text-gray-800 dark:text-white">Add New {activeEntity}</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {entities.find(e => e.name === activeEntity)?.attributes.map(attr => (
                    <div key={attr}>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{attr}</label>
                      <input
                        type="text"
                        value={newRow[attr] || ''}
                        onChange={(e) => handleInputChange(attr, e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  ))}
                </div>
                <button
                  onClick={handleAdd}
                  className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors duration-200 flex items-center"
                >
                  <Plus size={20} className="mr-2" />
                  Add {activeEntity}
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DatabaseInterface;