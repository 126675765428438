import React, { useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { useA11y } from '../hooks/useA11y';

interface SlidingPanelProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  position?: 'left' | 'right';
  width?: string;
  showBackdrop?: boolean;
}

const SlidingPanel: React.FC<SlidingPanelProps> = ({
  isOpen,
  onClose,
  title,
  children,
  position = 'right',
  width = 'w-96',
  showBackdrop = true,
}) => {
  const { elementRef } = useA11y({
    onEscape: onClose,
    trapFocus: true,
    autoFocus: true,
  });

  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen && showBackdrop) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose, showBackdrop]);

  const slideVariants = {
    hidden: {
      x: position === 'right' ? '100%' : '-100%',
      transition: {
        type: 'spring',
        damping: 30,
        stiffness: 300,
      },
    },
    visible: {
      x: 0,
      transition: {
        type: 'spring',
        damping: 30,
        stiffness: 300,
      },
    },
    exit: {
      x: position === 'right' ? '100%' : '-100%',
      transition: {
        type: 'spring',
        damping: 30,
        stiffness: 300,
      },
    },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {showBackdrop && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black cursor-pointer"
              onClick={onClose}
            />
          )}
          
          <motion.div
            ref={elementRef}
            role="dialog"
            aria-modal="true"
            aria-labelledby="sliding-panel-title"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={slideVariants}
            className={`fixed inset-y-0 ${position}-0 ${width} bg-light-surface dark:bg-dark-surface shadow-xl z-50`}
          >
            <div ref={panelRef} className="h-full flex flex-col">
              <div className="panel-header">
                <h2 id="sliding-panel-title" className="text-lg font-semibold text-gray-800 dark:text-white">
                  {title}
                </h2>
                <button
                  onClick={onClose}
                  className="p-2 hover:bg-light-primary dark:hover:bg-dark-primary rounded-full transition-colors duration-200"
                  aria-label="Close panel"
                >
                  <X size={20} />
                </button>
              </div>
              
              <div className="flex-1 overflow-y-auto">
                <div className="p-4">
                  {children}
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default SlidingPanel;