import React from 'react';

const A11yAnnouncer: React.FC = () => {
  return (
    <>
      <div
        id="a11y-announcer-polite"
        role="status"
        aria-live="polite"
        className="sr-only"
      />
      <div
        id="a11y-announcer-assertive"
        role="alert"
        aria-live="assertive"
        className="sr-only"
      />
    </>
  );
};

export default A11yAnnouncer;