import React, { useState, useEffect } from 'react';
import { AlertTriangle, FileText, Download, MessageSquare, Eye, ChevronLeft, ChevronRight, Clock, Bot, X, Archive, ChevronDown, ChevronUp } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import QualityReport from './QualityReport';
import DiscrepancyViewer from './DiscrepancyViewer';
import OccamChat from './OccamChat';
import JsonViewer from './JsonViewer';
import JSZip from 'jszip';

interface FileAnalyzerProps {
  files: File[];
  onClose: () => void;
}

interface ZipContent {
  name: string;
  content: string;
  isExpanded?: boolean;
}

interface Discrepancy {
  id: string;
  lineNumber: number;
  description: string;
  severity: 'High' | 'Medium' | 'Low';
  impact: string;
  recommendation: string;
  location: string;
  code: string;
}

const FileAnalyzer: React.FC<FileAnalyzerProps> = ({ files, onClose }) => {
  const [discrepancies, setDiscrepancies] = useState<Discrepancy[]>([]);
  const [selectedDiscrepancy, setSelectedDiscrepancy] = useState<Discrepancy | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(true);
  const [showJsonViewer, setShowJsonViewer] = useState(false);
  const [zipContents, setZipContents] = useState<ZipContent[]>([]);
  const [expandedFiles, setExpandedFiles] = useState<Set<string>>(new Set());
  const [contractMetadata, setContractMetadata] = useState({
    contractNumber: '',
    contractor: '',
    source: '',
    submissionDate: ''
  });
  const [showLeftPanel, setShowLeftPanel] = useState(true);
  const [showRightPanel, setShowRightPanel] = useState(true);

  useEffect(() => {
    analyzeFiles();
  }, [files]);

  const processZipFile = async (file: File) => {
    try {
      const zip = new JSZip();
      const zipData = await zip.loadAsync(file);
      const contents: ZipContent[] = [];

      for (const [filename, zipEntry] of Object.entries(zipData.files)) {
        if (!zipEntry.dir) {
          const content = await zipEntry.async('string');
          contents.push({
            name: filename,
            content,
            isExpanded: false
          });
        }
      }

      setZipContents(contents);
    } catch (error) {
      console.error('Error processing zip file:', error);
    }
  };

  const toggleFileExpansion = (fileName: string) => {
    setExpandedFiles(prev => {
      const newSet = new Set(prev);
      if (newSet.has(fileName)) {
        newSet.delete(fileName);
      } else {
        newSet.add(fileName);
      }
      return newSet;
    });
  };

  const analyzeFiles = async () => {
    setIsAnalyzing(true);
    try {
      // Process any zip files
      for (const file of files) {
        if (file.type === 'application/zip') {
          await processZipFile(file);
        }
      }

      // Simulate analysis process
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Example discrepancies
      const mockDiscrepancies: Discrepancy[] = [
        {
          id: '1',
          lineNumber: 45,
          description: 'Missing required ACWP field in CPD data',
          severity: 'High',
          impact: 'Prevents accurate cost performance analysis',
          recommendation: 'Add ACWP data for the current reporting period',
          location: 'cost_performance.json',
          code: '{\n  "cpd": {\n    "bcws": 1000000,\n    "bcwp": 950000\n    // Missing ACWP field\n  }\n}'
        },
        {
          id: '2',
          lineNumber: 123,
          description: 'Invalid date format in SPD timeline',
          severity: 'Medium',
          impact: 'May cause schedule analysis inconsistencies',
          recommendation: 'Update date format to YYYY-MM-DD',
          location: 'schedule_data.json',
          code: '{\n  "milestone": {\n    "date": "03/15/24" // Should be 2024-03-15\n  }\n}'
        }
      ];

      setDiscrepancies(mockDiscrepancies);
      setContractMetadata({
        contractNumber: 'CONT-2024-001',
        contractor: 'Acme Defense Systems',
        source: 'IPMDAR Submission',
        submissionDate: '2024-03-15'
      });
    } catch (error) {
      console.error('Error analyzing files:', error);
    } finally {
      setIsAnalyzing(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50">
      {/* Close Button */}
      <button
        onClick={onClose}
        className="absolute top-4 right-4 p-2 bg-white dark:bg-gray-800 rounded-full shadow-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200 z-50"
      >
        <X size={24} className="text-gray-600 dark:text-gray-300" />
      </button>

      <div className="h-full flex">
        {/* Left Panel - Occam Analysis */}
        <AnimatePresence>
          {showLeftPanel && (
            <motion.div
              initial={{ x: -300, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -300, opacity: 0 }}
              className="w-1/2 bg-white dark:bg-gray-800 p-6 overflow-y-auto"
            >
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold flex items-center">
                  <Bot className="mr-2 text-blue-500" size={24} />
                  Occam Analysis
                </h2>
                <button
                  onClick={() => setShowLeftPanel(false)}
                  className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
                >
                  <ChevronLeft size={20} />
                </button>
              </div>

              {/* Zip File Contents */}
              {zipContents.length > 0 && (
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-4 flex items-center">
                    <Archive className="mr-2 text-blue-500" size={20} />
                    Zip File Contents
                  </h3>
                  <div className="space-y-2">
                    {zipContents.map((file, index) => (
                      <div key={index} className="border dark:border-gray-700 rounded-lg">
                        <button
                          onClick={() => toggleFileExpansion(file.name)}
                          className="w-full px-4 py-2 flex items-center justify-between hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg"
                        >
                          <span className="flex items-center">
                            <FileText className="mr-2 text-gray-500" size={16} />
                            {file.name}
                          </span>
                          {expandedFiles.has(file.name) ? (
                            <ChevronUp size={16} />
                          ) : (
                            <ChevronDown size={16} />
                          )}
                        </button>
                        {expandedFiles.has(file.name) && (
                          <div className="p-4 border-t dark:border-gray-700 bg-gray-50 dark:bg-gray-900">
                            <pre className="text-sm overflow-x-auto">
                              {file.content}
                            </pre>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <QualityReport
                discrepancies={discrepancies}
                contractMetadata={contractMetadata}
                onSelectDiscrepancy={setSelectedDiscrepancy}
                selectedDiscrepancy={selectedDiscrepancy}
              />
            </motion.div>
          )}
        </AnimatePresence>

        {/* Center Toggle Buttons */}
        <div className="flex flex-col justify-center space-y-4">
          {!showLeftPanel && (
            <button
              onClick={() => setShowLeftPanel(true)}
              className="bg-blue-500 text-white p-2 rounded-r hover:bg-blue-600"
            >
              <ChevronRight size={20} />
            </button>
          )}
          {!showRightPanel && (
            <button
              onClick={() => setShowRightPanel(true)}
              className="bg-blue-500 text-white p-2 rounded-l hover:bg-blue-600"
            >
              <ChevronLeft size={20} />
            </button>
          )}
        </div>

        {/* Right Panel - Discrepancy Details */}
        <AnimatePresence>
          {showRightPanel && (
            <motion.div
              initial={{ x: 300, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 300, opacity: 0 }}
              className="w-1/2 bg-white dark:bg-gray-800 p-6 overflow-y-auto border-l dark:border-gray-700"
            >
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold flex items-center">
                  <FileText className="mr-2 text-blue-500" size={24} />
                  Location of Discrepancy
                </h2>
                <button
                  onClick={() => setShowRightPanel(false)}
                  className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
                >
                  <ChevronRight size={20} />
                </button>
              </div>

              {selectedDiscrepancy ? (
                <>
                  <DiscrepancyViewer 
                    discrepancy={selectedDiscrepancy}
                    onViewJson={() => setShowJsonViewer(true)}
                  />
                  <OccamChat
                    discrepancy={selectedDiscrepancy}
                    onDownloadChat={() => {/* Implement chat download */}}
                  />
                </>
              ) : (
                <div className="text-center text-gray-500 dark:text-gray-400 mt-12">
                  Select a discrepancy to view details
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* JSON Viewer Modal */}
      <AnimatePresence>
        {showJsonViewer && selectedDiscrepancy && (
          <JsonViewer
            code={selectedDiscrepancy.code}
            onClose={() => setShowJsonViewer(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default FileAnalyzer;