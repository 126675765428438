import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

const AZURE_KEY = 'c558aa7aeade45d6b938e5fe1cedb22b';
const AZURE_REGION = 'eastus';

class SpeechRecognitionService {
  private static instance: SpeechRecognitionService;
  private speechConfig: sdk.SpeechConfig;
  private recognizer: sdk.SpeechRecognizer | null = null;
  private isRecognizing: boolean = false;

  private constructor() {
    this.speechConfig = sdk.SpeechConfig.fromSubscription(AZURE_KEY, AZURE_REGION);
  }

  public static getInstance(): SpeechRecognitionService {
    if (!SpeechRecognitionService.instance) {
      SpeechRecognitionService.instance = new SpeechRecognitionService();
    }
    return SpeechRecognitionService.instance;
  }

  private initializeRecognizer(): void {
    if (!this.recognizer) {
      const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
      this.recognizer = new sdk.SpeechRecognizer(this.speechConfig, audioConfig);
    }
  }

  public async startListening(): Promise<string> {
    if (this.isRecognizing) {
      throw new Error('Speech recognition already in progress');
    }

    return new Promise((resolve, reject) => {
      try {
        this.initializeRecognizer();
        if (!this.recognizer) {
          throw new Error('Speech recognizer not initialized');
        }

        this.isRecognizing = true;

        // Handle successful recognition
        this.recognizer.recognized = (s, e) => {
          if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
            this.cleanup();
            resolve(e.result.text);
          } else if (e.result.reason === sdk.ResultReason.NoMatch) {
            this.cleanup();
            reject(new Error('No speech was recognized'));
          }
        };

        // Handle recognition cancellation
        this.recognizer.canceled = (s, e) => {
          this.cleanup();
          if (e.reason === sdk.CancellationReason.Error) {
            reject(new Error(`Speech recognition error: ${e.errorDetails}`));
          } else {
            reject(new Error('Speech recognition was canceled'));
          }
        };

        // Handle recognition errors
        this.recognizer.recognizing = (s, e) => {
          if (e.result.reason === sdk.ResultReason.NoMatch) {
            console.warn('Partial recognition result was discarded');
          }
        };

        // Start recognition with timeout
        this.recognizer.startContinuousRecognitionAsync(
          () => {
            // Set a timeout to automatically stop recognition after 30 seconds
            setTimeout(() => {
              if (this.isRecognizing) {
                this.cleanup();
                reject(new Error('Speech recognition timed out'));
              }
            }, 30000);
          },
          (error) => {
            this.cleanup();
            reject(new Error(`Failed to start speech recognition: ${error}`));
          }
        );
      } catch (error) {
        this.cleanup();
        reject(error);
      }
    });
  }

  public stop(): void {
    if (this.recognizer && this.isRecognizing) {
      try {
        this.recognizer.stopContinuousRecognitionAsync(
          () => {
            this.cleanup();
          },
          (error) => {
            console.error('Error stopping recognition:', error);
            this.cleanup();
          }
        );
      } catch (error) {
        console.error('Error in stop:', error);
        this.cleanup();
      }
    }
  }

  public cleanup(): void {
    this.isRecognizing = false;
    if (this.recognizer) {
      try {
        this.recognizer.close();
      } catch (error) {
        console.error('Error closing recognizer:', error);
      }
      this.recognizer = null;
    }
  }
}

export default SpeechRecognitionService;