import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Send, Upload, Bot, Mic, VolumeX, Volume2, AlertTriangle, RefreshCw, X, MessageSquare, Users, ChevronUp, ChevronDown, Minimize2 } from 'lucide-react';
import { Agent, Message } from '../types';
import useVoice from '../hooks/useVoice';

const AgentChatInterface: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [showAgentSelector, setShowAgentSelector] = useState(false);
  const [selectedAgents, setSelectedAgents] = useState<Agent[]>(['Occam']);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { speak, startListening, stopSpeaking, stopListening, isSpeaking, isListening } = useVoice();

  const agents: Agent[] = [
    'Occam',
    'Project Manager',
    'Earned Value Management Professional',
    'Program Manager',
    'Cost Control Analyst',
    'Schedule Analyst',
    'Risk Manager',
    'Performance Data Analyst',
    'Project Controller',
    'Quality Controller'
  ];

  useEffect(() => {
    // Add welcome message when component mounts
    const welcomeMessage: Message = {
      id: Date.now(),
      text: "Hi! I am Occam. How can I or my team of Worker Bees help you?\n\nHere's how to use our chat:\n\n• Select AI agents using the team icon above\n• Type your questions or requests in the chat\n• Upload files for analysis using the upload button\n• Use voice commands with the microphone\n• Download chat history as needed\n\nWe're here to help with:\n• IPMDAR Analysis\n• Project Management\n• Performance Metrics\n• Risk Assessment\n• Schedule Analysis",
      sender: 'agent',
      timestamp: new Date(),
      agent: 'Occam'
    };
    setMessages([welcomeMessage]);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const toggleAgentSelection = (agent: Agent) => {
    setSelectedAgents(prev => 
      prev.includes(agent) 
        ? prev.filter(a => a !== agent)
        : [...prev, agent]
    );
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim() || isSending) return;
    
    // Stop any ongoing speech before sending new message
    stopSpeaking();

    const userMessage: Message = {
      id: Date.now(),
      text: inputMessage,
      sender: 'user',
      timestamp: new Date(),
      agent: 'User'
    };

    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');
    setIsSending(true);

    try {
      // Simulate responses from all selected agents
      for (const agent of selectedAgents) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        const aiResponse: Message = {
          id: Date.now() + Math.random(),
          text: 'Hello! How can I assist you today?',
          sender: 'agent',
          timestamp: new Date(),
          agent: agent
        };
        
        setMessages(prev => [...prev, aiResponse]);
      }
    } catch (error) {
      console.error('Error in chat:', error);
      setMessages(prev => [
        ...prev,
        {
          id: Date.now(),
          text: 'Sorry, there was an error processing your message. Please try again.',
          sender: 'agent',
          timestamp: new Date(),
          agent: 'Occam'
        }
      ]);
    } finally {
      setIsSending(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const formatTime = (date: Date) => {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  if (!isVisible) {
    return (
      <button
        onClick={() => setIsVisible(true)}
        className="fixed bottom-4 right-4 bg-blue-500 text-white p-4 rounded-full shadow-lg hover:bg-blue-600 transition-colors duration-200"
      >
        <MessageSquare size={24} />
      </button>
    );
  }

  return (
    <div className={`fixed bottom-4 right-4 w-[400px] ${isMinimized ? 'h-auto' : 'h-[600px]'} bg-white dark:bg-gray-800 rounded-lg shadow-xl border border-gray-200 dark:border-gray-700 transition-all duration-200 overflow-hidden`}>
      <div className="p-4 border-b dark:border-gray-700 flex justify-between items-center">
        <div className="flex items-center">
          <Bot className="w-5 h-5 mr-2 text-blue-500" />
          <span className="font-semibold">AI Agents Chat</span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setShowAgentSelector(!showAgentSelector)}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
            title="Select Agents"
          >
            <Users size={20} />
          </button>
          <button
            onClick={() => setIsMinimized(!isMinimized)}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
            title={isMinimized ? "Expand" : "Minimize"}
          >
            {isMinimized ? <ChevronUp size={20} /> : <Minimize2 size={20} />}
          </button>
          <button
            onClick={() => setIsVisible(false)}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
            title="Close"
          >
            <X size={20} />
          </button>
        </div>
      </div>

      {!isMinimized && (
        <>
          <AnimatePresence>
            {showAgentSelector && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                className="border-b dark:border-gray-700"
              >
                <div className="p-4 grid grid-cols-2 gap-2">
                  {agents.map(agent => (
                    <button
                      key={agent}
                      onClick={() => toggleAgentSelection(agent)}
                      className={`p-2 rounded text-sm ${
                        selectedAgents.includes(agent)
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                      }`}
                    >
                      {agent}
                    </button>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          <div className="flex-1 overflow-y-auto p-4 space-y-4" style={{ height: 'calc(100% - 140px)' }}>
            {messages.map((message) => (
              <div
                key={message.id}
                className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
              >
                <div
                  className={`max-w-[75%] p-3 rounded-lg ${
                    message.sender === 'user'
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 dark:bg-gray-700'
                  }`}
                >
                  {message.sender !== 'user' && (
                    <div className="text-xs text-gray-500 dark:text-gray-400 mb-1">{message.agent}</div>
                  )}
                  <p className="text-sm whitespace-pre-wrap">{message.text}</p>
                  <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                    {formatTime(message.timestamp)}
                  </div>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>

          <div className="p-4 border-t dark:border-gray-700">
            <div className="flex space-x-2">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Type your message..."
                className="flex-1 px-4 py-2 bg-gray-100 dark:bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={isSending}
              />
              <button
                onClick={handleSendMessage}
                disabled={!inputMessage.trim() || isSending}
                className={`p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 ${
                  (!inputMessage.trim() || isSending) && 'opacity-50 cursor-not-allowed'
                }`}
              >
                {isSending ? <RefreshCw className="animate-spin" size={20} /> : <Send size={20} />}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AgentChatInterface;