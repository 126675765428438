import React, { useState, useEffect } from 'react';
import { User, Edit, Trash2, Plus, Check, X, RefreshCw, Save, Key, Shield } from 'lucide-react';
import PasswordChangeModal from './PasswordChangeModal';
import PasswordService from '../services/PasswordService';

interface UserAccount {
  id: string;
  username: string;
  email: string;
  role: 'Super Administrator' | 'Administrator' | 'User';
  isActive: boolean;
  lastLogin?: Date;
  createdAt: Date;
}

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<UserAccount[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editingUser, setEditingUser] = useState<UserAccount | null>(null);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [newUser, setNewUser] = useState<Partial<UserAccount>>({});
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState<string | null>(null);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [filterRole, setFilterRole] = useState<string>('all');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      // Simulated API call to fetch users
      await new Promise(resolve => setTimeout(resolve, 1000));
      const mockUsers: UserAccount[] = [
        { 
          id: '1', 
          username: 'superadmin', 
          email: 'superadmin@example.com', 
          role: 'Super Administrator', 
          isActive: true,
          createdAt: new Date('2024-01-01'),
          lastLogin: new Date()
        },
        { 
          id: '2', 
          username: 'admin', 
          email: 'admin@example.com', 
          role: 'Administrator', 
          isActive: true,
          createdAt: new Date('2024-01-02'),
          lastLogin: new Date()
        },
        { 
          id: '3', 
          username: 'user1', 
          email: 'user1@example.com', 
          role: 'User', 
          isActive: true,
          createdAt: new Date('2024-01-03')
        },
      ];
      setUsers(mockUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (user: UserAccount) => {
    setEditingUser(user);
  };

  const handlePasswordChange = async (oldPassword: string, newPassword: string) => {
    if (!selectedUser) return;
    
    const passwordService = PasswordService.getInstance();
    await passwordService.changePassword(selectedUser, oldPassword, newPassword);
    setShowPasswordModal(false);
    setSelectedUser(null);
    setSaveSuccess('Password changed successfully');
    setTimeout(() => setSaveSuccess(null), 3000);
  };

  const handleSave = async () => {
    if (editingUser) {
      setIsSaving(true);
      try {
        await new Promise(resolve => setTimeout(resolve, 500));
        setUsers(users.map(u => u.id === editingUser.id ? editingUser : u));
        setEditingUser(null);
        setSaveSuccess('User updated successfully');
        setTimeout(() => setSaveSuccess(null), 3000);
      } catch (error) {
        setSaveError('Failed to update user');
        setTimeout(() => setSaveError(null), 3000);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleDelete = async (userId: string) => {
    const user = users.find(u => u.id === userId);
    if (!user) return;

    if (user.role === 'Super Administrator') {
      setSaveError('Super Administrator accounts cannot be deleted');
      setTimeout(() => setSaveError(null), 3000);
      return;
    }

    if (window.confirm('Are you sure you want to delete this user?')) {
      setIsSaving(true);
      try {
        await new Promise(resolve => setTimeout(resolve, 500));
        setUsers(users.filter(u => u.id !== userId));
        setSaveSuccess('User deleted successfully');
        setTimeout(() => setSaveSuccess(null), 3000);
      } catch (error) {
        setSaveError('Failed to delete user');
        setTimeout(() => setSaveError(null), 3000);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleToggleActive = async (userId: string) => {
    const user = users.find(u => u.id === userId);
    if (!user) return;

    if (user.role === 'Super Administrator') {
      setSaveError('Super Administrator accounts cannot be deactivated');
      setTimeout(() => setSaveError(null), 3000);
      return;
    }

    setIsSaving(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 500));
      setUsers(users.map(u => u.id === userId ? { ...u, isActive: !u.isActive } : u));
      setSaveSuccess('User status updated successfully');
      setTimeout(() => setSaveSuccess(null), 3000);
    } catch (error) {
      setSaveError('Failed to update user status');
      setTimeout(() => setSaveError(null), 3000);
    } finally {
      setIsSaving(false);
    }
  };

  const handleAddUser = async () => {
    if (newUser.username && newUser.email && newUser.role) {
      setIsSaving(true);
      try {
        await new Promise(resolve => setTimeout(resolve, 500));
        const addedUser: UserAccount = {
          id: Date.now().toString(),
          username: newUser.username,
          email: newUser.email,
          role: newUser.role as UserAccount['role'],
          isActive: true,
          createdAt: new Date(),
        };
        setUsers([...users, addedUser]);
        setNewUser({});
        setIsAddingUser(false);
        setSaveSuccess('New user added successfully');
        setTimeout(() => setSaveSuccess(null), 3000);
      } catch (error) {
        setSaveError('Failed to add new user');
        setTimeout(() => setSaveError(null), 3000);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const filteredUsers = users.filter(user => 
    filterRole === 'all' || user.role === filterRole
  );

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center">
          <Shield className="mr-2 text-blue-500" size={24} />
          User Management
        </h2>
        <div className="flex space-x-4">
          <select
            value={filterRole}
            onChange={(e) => setFilterRole(e.target.value)}
            className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
          >
            <option value="all">All Roles</option>
            <option value="Super Administrator">Super Administrators</option>
            <option value="Administrator">Administrators</option>
            <option value="User">Users</option>
          </select>
          <button
            onClick={() => setIsAddingUser(true)}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors duration-200 flex items-center"
          >
            <Plus size={20} className="mr-2" />
            Add User
          </button>
        </div>
      </div>

      {saveSuccess && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
          <span className="block sm:inline">{saveSuccess}</span>
        </div>
      )}

      {saveError && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          <span className="block sm:inline">{saveError}</span>
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <RefreshCw size={48} className="animate-spin text-blue-500" />
        </div>
      ) : (
        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Username</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Email</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Role</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Last Login</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                {filteredUsers.map((user) => (
                  <tr key={user.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {editingUser?.id === user.id ? (
                        <input
                          type="text"
                          value={editingUser.username}
                          onChange={(e) => setEditingUser({ ...editingUser, username: e.target.value })}
                          className="border rounded px-2 py-1 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                        />
                      ) : (
                        <div className="flex items-center">
                          {user.role === 'Super Administrator' && (
                            <Shield className="mr-2 text-blue-500" size={16} />
                          )}
                          {user.username}
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {editingUser?.id === user.id ? (
                        <input
                          type="email"
                          value={editingUser.email}
                          onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })}
                          className="border rounded px-2 py-1 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                        />
                      ) : (
                        user.email
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {editingUser?.id === user.id ? (
                        <select
                          value={editingUser.role}
                          onChange={(e) => setEditingUser({ ...editingUser, role: e.target.value as UserAccount['role'] })}
                          className="border rounded px-2 py-1 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                        >
                          <option value="Super Administrator">Super Administrator</option>
                          <option value="Administrator">Administrator</option>
                          <option value="User">User</option>
                        </select>
                      ) : (
                        <span className={`px-2 py-1 rounded-full text-xs ${
                          user.role === 'Super Administrator' 
                            ? 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200'
                            : user.role === 'Administrator'
                            ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200'
                            : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
                        }`}>
                          {user.role}
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                        {user.isActive ? 'Active' : 'Inactive'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {user.lastLogin ? user.lastLogin.toLocaleDateString() : 'Never'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      {editingUser?.id === user.id ? (
                        <>
                          <button onClick={handleSave} className="text-indigo-600 hover:text-indigo-900 mr-2 dark:text-indigo-400 dark:hover:text-indigo-300">
                            <Check size={18} />
                          </button>
                          <button onClick={() => setEditingUser(null)} className="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300">
                            <X size={18} />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              setSelectedUser(user.username);
                              setShowPasswordModal(true);
                            }}
                            className="text-blue-600 hover:text-blue-900 mr-2 dark:text-blue-400 dark:hover:text-blue-300"
                            title="Change Password"
                          >
                            <Key size={18} />
                          </button>
                          {user.role !== 'Super Administrator' && (
                            <>
                              <button onClick={() => handleEdit(user)} className="text-indigo-600 hover:text-indigo-900 mr-2 dark:text-indigo-400 dark:hover:text-indigo-300">
                                <Edit size={18} />
                              </button>
                              <button onClick={() => handleDelete(user.id)} className="text-red-600 hover:text-red-900 mr-2 dark:text-red-400 dark:hover:text-red-300">
                                <Trash2 size={18} />
                              </button>
                              <button onClick={() => handleToggleActive(user.id)} className={`${user.isActive ? 'text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300' : 'text-green-600 hover:text-green-900 dark:text-green-400 dark:hover:text-green-300'}`}>
                                {user.isActive ? <X size={18} /> : <Check size={18} />}
                              </button>
                            </>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {isAddingUser && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">Add New User</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Username</label>
                <input
                  type="text"
                  value={newUser.username || ''}
                  onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
                <input
                  type="email"
                  value={newUser.email || ''}
                  onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Role</label>
                <select
                  value={newUser.role || ''}
                  onChange={(e) => setNewUser({ ...newUser, role: e.target.value as UserAccount['role'] })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                >
                  <option value="">Select a role</option>
                  <option value="Super Administrator">Super Administrator</option>
                  <option value="Administrator">Administrator</option>
                  <option value="User">User</option>
                </select>
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-2">
              <button
                onClick={() => setIsAddingUser(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={handleAddUser}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Add User
              </button>
            </div>
          </div>
        </div>
      )}

      <PasswordChangeModal
        isOpen={showPasswordModal}
        onClose={() => {
          setShowPasswordModal(false);
          setSelectedUser(null);
        }}
        onSubmit={handlePasswordChange}
        userRole={users.find(u => u.username === selectedUser)?.role || ''}
      />
    </div>
  );
};

export default UserManagement;