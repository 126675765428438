import React from 'react';
import { Brain, CheckCircle, AlertTriangle, RefreshCw } from 'lucide-react';
import { motion } from 'framer-motion';

interface AgentTrainingCardProps {
  agent: {
    name: string;
    role: string;
    status: 'training' | 'completed' | 'error';
    progress: number;
    learningMetrics?: {
      accuracy: number;
      dataProcessed: number;
      estimatedTimeRemaining: string;
    };
  };
}

const AgentTrainingCard: React.FC<AgentTrainingCardProps> = ({ agent }) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'training':
        return 'bg-blue-500';
      case 'completed':
        return 'bg-green-500';
      case 'error':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'training':
        return <RefreshCw className="animate-spin" size={20} />;
      case 'completed':
        return <CheckCircle size={20} />;
      case 'error':
        return <AlertTriangle size={20} />;
      default:
        return <Brain size={20} />;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md"
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-3">
          <div className={`p-2 rounded-full ${getStatusColor(agent.status)} bg-opacity-10`}>
            {getStatusIcon(agent.status)}
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">{agent.name}</h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">{agent.role}</p>
          </div>
        </div>
        <span className={`px-3 py-1 rounded-full text-sm ${
          agent.status === 'training' ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200' :
          agent.status === 'completed' ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200' :
          'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
        }`}>
          {agent.status.charAt(0).toUpperCase() + agent.status.slice(1)}
        </span>
      </div>

      <div className="space-y-4">
        <div>
          <div className="flex justify-between text-sm mb-1">
            <span className="text-gray-600 dark:text-gray-400">Training Progress</span>
            <span className="text-gray-800 dark:text-gray-200">{agent.progress}%</span>
          </div>
          <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: `${agent.progress}%` }}
              className={`h-2 rounded-full ${getStatusColor(agent.status)}`}
              transition={{ duration: 0.5 }}
            />
          </div>
        </div>

        {agent.learningMetrics && (
          <div className="grid grid-cols-3 gap-4 pt-4">
            <div className="text-center">
              <p className="text-sm text-gray-500 dark:text-gray-400">Accuracy</p>
              <p className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                {agent.learningMetrics.accuracy}%
              </p>
            </div>
            <div className="text-center">
              <p className="text-sm text-gray-500 dark:text-gray-400">Data Processed</p>
              <p className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                {agent.learningMetrics.dataProcessed}MB
              </p>
            </div>
            <div className="text-center">
              <p className="text-sm text-gray-500 dark:text-gray-400">Time Remaining</p>
              <p className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                {agent.learningMetrics.estimatedTimeRemaining}
              </p>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default AgentTrainingCard;