import React from 'react';
import { Search } from 'lucide-react';

interface DocumentSearchProps {
  searchQuery: string;
  onSearchChange: (query: string ) => void;
}

const DocumentSearch: React.FC<DocumentSearchProps> = ({
  searchQuery,
  onSearchChange,
}) => {
  return (
    <div className="relative mb-4">
      <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
      <input
        type="text"
        placeholder="Search documents..."
        value={searchQuery}
        onChange={(e) => onSearchChange(e.target.value)}
        className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
      />
    </div>
  );
};

export default DocumentSearch;