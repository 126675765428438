import React from 'react';
import { Search, Upload, RefreshCw } from 'lucide-react';

interface KnowledgeBaseSearchProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  onUpload: () => void;
  isProcessing: boolean;
  fileInputRef: React.RefObject<HTMLInputElement>;
}

const KnowledgeBaseSearch: React.FC<KnowledgeBaseSearchProps> = ({
  searchQuery,
  setSearchQuery,
  onUpload,
  isProcessing,
  fileInputRef,
}) => {
  return (
    <div className="flex-1">
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        <input
          type="text"
          placeholder="Search knowledge base..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
        />
      </div>
    </div>
  );
};

export default KnowledgeBaseSearch;