import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import { azureConfig } from '../config/azure';

interface ApiResponse {
  choices?: Array<{
    message?: {
      content?: string;
    };
  }>;
  error?: {
    message: string;
    code: string;
    details?: any;
  };
}

interface ApiError extends Error {
  code?: string;
  status?: number;
  details?: any;
}

class AzureService {
  private static instance: AzureService;
  private apiKey: string = azureConfig.apiKey;
  private endpoint: string = azureConfig.endpoint;
  private deploymentName: string = azureConfig.deployment;
  private maxRetries: number = 3;
  private retryDelay: number = 1000;

  private constructor() {}

  public static getInstance(): AzureService {
    if (!AzureService.instance) {
      AzureService.instance = new AzureService();
    }
    return AzureService.instance;
  }

  private createApiError(message: string, code: string, status?: number, details?: any): ApiError {
    const error = new Error(message) as ApiError;
    error.code = code;
    error.status = status;
    error.details = details;
    return error;
  }

  private async makeApiRequest(url: string, body: any, attempt: number = 1): Promise<ApiResponse> {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': this.apiKey,
          'Accept': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.error?.message || 'Unknown error occurred';
        const errorCode = response.status;

        if (errorCode === 401) {
          throw this.createApiError(
            'Invalid API key or unauthorized access',
            'UNAUTHORIZED',
            401
          );
        } else if (errorCode === 429 && attempt < this.maxRetries) {
          const retryAfter = parseInt(response.headers.get('Retry-After') || '1', 10);
          await new Promise(resolve => setTimeout(resolve, retryAfter * 1000));
          return this.makeApiRequest(url, body, attempt + 1);
        }

        throw this.createApiError(
          `Azure API error: ${errorMessage}`,
          'API_ERROR',
          errorCode,
          errorData.error
        );
      }

      const contentType = response.headers.get('content-type');
      if (!contentType?.includes('application/json')) {
        throw this.createApiError(
          'Unexpected response type from API',
          'INVALID_CONTENT_TYPE',
          response.status,
          { contentType }
        );
      }

      return await response.json();
    } catch (error) {
      if (error instanceof Error) {
        if ((error as ApiError).code) {
          throw error;
        }
        throw this.createApiError(
          `Request failed: ${error.message}`,
          'REQUEST_FAILED',
          undefined,
          { originalError: error.message }
        );
      }
      throw this.createApiError(
        'An unexpected error occurred',
        'UNKNOWN_ERROR',
        undefined,
        { error }
      );
    }
  }

  private getSystemPromptForAgent(agent: string): string {
    const basePrompt = `As an AI assistant based on the Proteus Envision user guide, I'll provide information on earned value management, implementation advice, and potential future improvements, while adhering to the guidelines outlined.`;

    const prompts: { [key: string]: string } = {
      'Occam': `${basePrompt} As Occam, I specialize in providing comprehensive assistance for the Proteus Envision Suite.`,
      'Project Manager': `${basePrompt} As a Senior Project Manager, I focus on managing large-scale projects using EVM principles.`,
      'Earned Value Management Professional': `${basePrompt} As an EVM Professional, I provide expertise in EIA-748D standards and NDIA IPMD guidelines.`,
      'Program Manager': `${basePrompt} As a Program Manager, I oversee multiple related projects and ensure strategic alignment.`,
      'Cost Control Analyst': `${basePrompt} As a Cost Control Analyst, I specialize in financial tracking and variance analysis.`,
      'Schedule Analyst': `${basePrompt} As a Schedule Analyst, I focus on project scheduling and timeline management.`,
      'Risk Manager': `${basePrompt} As a Risk Manager, I specialize in project risk assessment and mitigation strategies.`,
      'Performance Data Analyst': `${basePrompt} As a Performance Data Analyst, I focus on EVM metrics and project performance analysis.`,
      'Project Controller': `${basePrompt} As a Senior Project Controller, I oversee project controls and performance management.`,
      'Quality Controller': `${basePrompt} As a Quality Controller, I ensure adherence to quality standards and process compliance.`,
    };

    return prompts[agent] || prompts['Occam'];
  }

  public async generateCompletion(message: string, agent: string): Promise<string> {
    try {
      const url = `${this.endpoint}/openai/deployments/${this.deploymentName}/chat/completions?api-version=${azureConfig.apiVersion}`;
      
      const body = {
        messages: [
          {
            role: 'system',
            content: this.getSystemPromptForAgent(agent)
          },
          {
            role: 'user',
            content: message
          }
        ],
        max_tokens: 800,
        temperature: 0.7,
        frequency_penalty: 0,
        presence_penalty: 0,
        top_p: 0.95,
        stream: false
      };

      const data = await this.makeApiRequest(url, body);
      
      if (!data.choices?.[0]?.message?.content) {
        throw this.createApiError(
          'Invalid response format from Azure API',
          'INVALID_RESPONSE',
          undefined,
          { response: data }
        );
      }

      return data.choices[0].message.content;
    } catch (error) {
      console.error('Error in Azure OpenAI API call:', {
        name: error instanceof Error ? error.name : 'Unknown',
        message: error instanceof Error ? error.message : 'Unknown error',
        code: (error as ApiError).code,
        status: (error as ApiError).status,
        details: (error as ApiError).details
      });
      throw error;
    }
  }

  public async textToSpeech(text: string, agent: string = 'Occam'): Promise<void> {
    const speechConfig = sdk.SpeechConfig.fromSubscription(azureConfig.speechKey || '', azureConfig.speechRegion || '');
    speechConfig.speechSynthesisVoiceName = agent === 'Occam' ? 'en-US-GuyNeural' : 'en-US-JennyNeural';
    
    const synthesizer = new sdk.SpeechSynthesizer(speechConfig);
    
    return new Promise((resolve, reject) => {
      synthesizer.speakTextAsync(
        text,
        result => {
          synthesizer.close();
          if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
            resolve();
          } else {
            reject(new Error(`Speech synthesis failed: ${result.errorDetails}`));
          }
        },
        error => {
          synthesizer.close();
          reject(error);
        }
      );
    });
  }

  public async speechToText(): Promise<string> {
    const speechConfig = sdk.SpeechConfig.fromSubscription(azureConfig.speechKey || '', azureConfig.speechRegion || '');
    const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

    return new Promise((resolve, reject) => {
      recognizer.recognizeOnceAsync(
        result => {
          recognizer.close();
          if (result.reason === sdk.ResultReason.RecognizedSpeech) {
            resolve(result.text);
          } else {
            reject(new Error(`Speech recognition failed: ${result.reason}`));
          }
        },
        error => {
          recognizer.close();
          reject(error);
        }
      );
    });
  }
}

export default AzureService;