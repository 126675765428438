import React, { useState, useEffect } from 'react';
import { FileText, Code, Eye, Search, RefreshCw } from 'lucide-react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ProteusService from '../../services/ProteusService';

interface Discrepancy {
  id: string;
  lineNumber: number;
  description: string;
  severity: 'High' | 'Medium' | 'Low';
  impact: string;
  recommendation: string;
  location: string;
  code: string;
}

interface DiscrepancyViewerProps {
  discrepancy: Discrepancy;
  onViewJson: () => void;
}

const DiscrepancyViewer: React.FC<DiscrepancyViewerProps> = ({ discrepancy, onViewJson }) => {
  const [isSearching, setIsSearching] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    checkProteusConnection();
  }, []);

  const checkProteusConnection = async () => {
    const proteusService = ProteusService.getInstance();
    setIsConnected(proteusService.isConnectedToProteus());
  };

  const handleSearchProteus = async () => {
    if (!isConnected) {
      alert('Please connect to Proteus Envision Suite first');
      return;
    }

    setIsSearching(true);
    try {
      const proteusService = ProteusService.getInstance();
      // Implement actual Proteus search here
      await new Promise(resolve => setTimeout(resolve, 1500));
      console.log('Searching Proteus for:', discrepancy.location);
    } catch (error) {
      console.error('Error searching Proteus:', error);
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <FileText className="mr-2 text-blue-500" size={20} />
          <h3 className="text-lg font-semibold">{discrepancy.location}</h3>
        </div>
        <span className="text-sm text-gray-500">Line {discrepancy.lineNumber}</span>
      </div>

      <div className="bg-gray-50 dark:bg-gray-900 rounded-lg p-4">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center">
            <Code className="mr-2 text-blue-500" size={20} />
            <h4 className="font-semibold">Code Preview</h4>
          </div>
          <button
            onClick={onViewJson}
            className="flex items-center px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200"
          >
            <Eye size={16} className="mr-1" />
            View JSON
          </button>
        </div>
        <SyntaxHighlighter
          language="json"
          style={atomOneDark}
          showLineNumbers
          wrapLines
          lineProps={(lineNumber) => ({
            style: {
              backgroundColor: lineNumber === discrepancy.lineNumber ? 'rgba(255, 0, 0, 0.2)' : undefined,
              display: 'block',
              width: '100%'
            }
          })}
        >
          {discrepancy.code}
        </SyntaxHighlighter>
      </div>

      <div className="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg">
        <h4 className="font-semibold mb-2">IPMDAR Reference</h4>
        <p className="text-sm text-gray-600 dark:text-gray-400">
          According to IPMDAR DID section 2.3.1, all cost performance data must include ACWP values for accurate
          variance analysis and reporting.
        </p>
      </div>

      <div className="bg-gray-50 dark:bg-gray-900 p-4 rounded-lg">
        <h4 className="font-semibold mb-2">Resolution Steps</h4>
        <ol className="list-decimal list-inside space-y-2 text-sm text-gray-600 dark:text-gray-400">
          <li>Locate the missing ACWP data in your source system</li>
          <li>Validate the data accuracy and completeness</li>
          <li>Update the CPD file with the correct ACWP values</li>
          <li>Re-run validation checks to ensure compliance</li>
        </ol>
      </div>

      <div className="flex justify-end space-x-4">
        <button 
          onClick={onViewJson}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200 flex items-center"
        >
          <Eye size={16} className="mr-2" />
          View Full JSON
        </button>
        <button 
          onClick={handleSearchProteus}
          disabled={!isConnected || isSearching}
          className={`px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors duration-200 flex items-center ${
            (!isConnected || isSearching) && 'opacity-50 cursor-not-allowed'
          }`}
        >
          {isSearching ? (
            <>
              <RefreshCw size={16} className="mr-2 animate-spin" />
              Searching...
            </>
          ) : (
            <>
              <Search size={16} className="mr-2" />
              Search Proteus Envision Suite
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default DiscrepancyViewer;