import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { AlertTriangle, FileText, Database, CheckCircle, AlertCircle, Brain, RefreshCw, TrendingUp, BarChart2, Shield } from 'lucide-react';
import RiskDashboard from './RiskDashboard';
import RiskCategoryAnalysis from './RiskCategoryAnalysis';
import ComplianceStatus from './ComplianceStatus';
import DataQualityMetrics from './DataQualityMetrics';
import ProteusService from '../../services/ProteusService';
import { ProcessedFile } from '../../utils/fileProcessing';

interface ForensicRiskAnalysisProps {
  uploadedFiles: ProcessedFile[];
  onAnalysisComplete?: (report: any) => void;
}

const ForensicRiskAnalysis: React.FC<ForensicRiskAnalysisProps> = ({
  uploadedFiles,
  onAnalysisComplete
}) => {
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [analysisResults, setAnalysisResults] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    checkConnection();
  }, []);

  useEffect(() => {
    if (isConnected && uploadedFiles.length > 0) {
      performAnalysis();
    }
  }, [uploadedFiles, isConnected]);

  const checkConnection = async () => {
    const proteusService = ProteusService.getInstance();
    setIsConnected(proteusService.isConnectedToProteus());
  };

  const performAnalysis = async () => {
    if (!isConnected) {
      setError('Proteus connection required for analysis');
      return;
    }

    if (uploadedFiles.length === 0) {
      setError('No files available for analysis');
      return;
    }

    setIsAnalyzing(true);
    setError(null);

    try {
      // Simulate analysis process
      await new Promise(resolve => setTimeout(resolve, 2000));

      const results = {
        aleatoryRisks: {
          costVariance: {
            severity: 'High',
            confidence: 0.85,
            metrics: {
              mean: 125000,
              standardDev: 15000,
              percentile95: 150000
            }
          },
          scheduleVariance: {
            severity: 'Medium',
            confidence: 0.92,
            metrics: {
              meanDelay: 15,
              standardDev: 5,
              percentile95: 25
            }
          },
          performanceMetrics: {
            severity: 'Low',
            confidence: 0.95,
            metrics: {
              cpi: 0.95,
              spi: 0.98,
              tcpi: 1.05
            }
          }
        },
        epistemicRisks: {
          dataQuality: {
            severity: 'Medium',
            confidence: 0.88,
            issues: [
              'Missing baseline data in 3 work packages',
              'Inconsistent reporting formats in time-phased data'
            ]
          },
          methodologyGaps: {
            severity: 'Low',
            confidence: 0.90,
            issues: [
              'Limited historical data for new work packages',
              'Estimation methodology variations across teams'
            ]
          },
          systematicBias: {
            severity: 'Medium',
            confidence: 0.85,
            issues: [
              'Consistent underestimation in specific work categories',
              'Schedule optimism bias detected'
            ]
          }
        },
        complianceStatus: {
          ipmdarCompliance: 0.92,
          criticalViolations: 2,
          warningCount: 5,
          requiredActions: [
            'Update Format 1 reporting structure',
            'Resolve data inconsistencies in CPR Format 5'
          ]
        },
        dataQualityMetrics: {
          completeness: 0.95,
          accuracy: 0.88,
          consistency: 0.90,
          timeliness: 0.85
        },
        recommendations: [
          {
            category: 'Cost Control',
            priority: 'High',
            action: 'Implement enhanced cost variance tracking system'
          },
          {
            category: 'Schedule Management',
            priority: 'Medium',
            action: 'Review and adjust resource allocation in delayed work packages'
          },
          {
            category: 'Data Quality',
            priority: 'High',
            action: 'Standardize reporting formats across all work packages'
          }
        ]
      };

      setAnalysisResults(results);
      onAnalysisComplete?.(results);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Analysis failed');
    } finally {
      setIsAnalyzing(false);
    }
  };

  if (!isConnected) {
    return (
      <div className="bg-yellow-100 dark:bg-yellow-900 p-4 rounded-lg">
        <div className="flex items-center">
          <AlertTriangle className="text-yellow-500 mr-2" size={24} />
          <p className="text-yellow-700 dark:text-yellow-300">
            Proteus connection required for forensic risk analysis
          </p>
        </div>
      </div>
    );
  }

  if (uploadedFiles.length === 0) {
    return (
      <div className="bg-blue-100 dark:bg-blue-900 p-4 rounded-lg">
        <div className="flex items-center">
          <FileText className="text-blue-500 mr-2" size={24} />
          <p className="text-blue-700 dark:text-blue-300">
            Upload files to begin forensic risk analysis
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center">
          <Shield className="mr-2 text-blue-500" size={24} />
          Forensic Risk Analysis
        </h2>
        <div className="flex items-center space-x-2">
          {isConnected && (
            <span className="flex items-center text-green-500">
              <Database className="mr-1" size={16} />
              Connected to Proteus
            </span>
          )}
          <button
            onClick={performAnalysis}
            disabled={isAnalyzing || !isConnected}
            className={`bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200 flex items-center ${
              (isAnalyzing || !isConnected) && 'opacity-50 cursor-not-allowed'
            }`}
          >
            {isAnalyzing ? (
              <>
                <RefreshCw className="animate-spin mr-2" size={20} />
                Analyzing...
              </>
            ) : (
              <>
                <Brain className="mr-2" size={20} />
                Run Analysis
              </>
            )}
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-100 dark:bg-red-900 p-4 rounded-lg">
          <div className="flex items-center">
            <AlertCircle className="text-red-500 mr-2" size={24} />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      <AnimatePresence mode="wait">
        {analysisResults && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="space-y-6"
          >
            <RiskDashboard
              aleatoryRisks={analysisResults.aleatoryRisks}
              epistemicRisks={analysisResults.epistemicRisks}
            />

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <RiskCategoryAnalysis
                aleatoryRisks={analysisResults.aleatoryRisks}
                epistemicRisks={analysisResults.epistemicRisks}
              />
              <ComplianceStatus status={analysisResults.complianceStatus} />
            </div>

            <DataQualityMetrics metrics={analysisResults.dataQualityMetrics} />

            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
              <h3 className="text-lg font-semibold mb-4 flex items-center">
                <TrendingUp className="mr-2 text-blue-500" size={20} />
                Recommended Actions
              </h3>
              <div className="space-y-4">
                {analysisResults.recommendations.map((rec: any, index: number) => (
                  <div
                    key={index}
                    className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <h4 className="font-semibold text-gray-800 dark:text-gray-200">
                          {rec.category}
                        </h4>
                        <p className="text-gray-600 dark:text-gray-400 mt-1">
                          {rec.action}
                        </p>
                      </div>
                      <span
                        className={`px-3 py-1 rounded-full text-sm font-medium ${
                          rec.priority === 'High'
                            ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
                            : rec.priority === 'Medium'
                            ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
                            : 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                        }`}
                      >
                        {rec.priority}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ForensicRiskAnalysis;