import { Agent } from '../types';
import { getBaseKnowledge } from '../utils/knowledgeBase';
import { processFile, generateEmbeddings } from '../utils/fileProcessing';
import { saveToStorage } from '../utils/storage';

class KnowledgeTrainingService {
  private static instance: KnowledgeTrainingService;
  private trainingStatus: Map<Agent, {
    status: 'pending' | 'training' | 'completed' | 'error';
    progress: number;
    error?: string;
  }>;

  private constructor() {
    this.trainingStatus = new Map();
  }

  public static getInstance(): KnowledgeTrainingService {
    if (!KnowledgeTrainingService.instance) {
      KnowledgeTrainingService.instance = new KnowledgeTrainingService();
    }
    return KnowledgeTrainingService.instance;
  }

  public async trainAgent(agent: Agent): Promise<void> {
    try {
      this.trainingStatus.set(agent, { status: 'training', progress: 0 });
      
      // Get base knowledge
      const baseKnowledge = getBaseKnowledge();
      let progress = 0;
      const totalFiles = baseKnowledge.length;

      // Process each knowledge item
      for (const item of baseKnowledge) {
        // Generate embeddings for the content
        const embedding = await generateEmbeddings(item.content);
        
        // Process the file with agent-specific context
        const processedData = await this.processForAgent(agent, {
          ...item,
          metadata: {
            ...item.metadata,
            embedding,
            agent,
            trainedAt: new Date().toISOString()
          }
        });

        // Save processed data
        await this.saveTrainingData(agent, processedData);

        // Update progress
        progress += (1 / totalFiles) * 100;
        this.trainingStatus.set(agent, { 
          status: 'training', 
          progress: Math.min(Math.round(progress), 99)
        });
      }

      // Mark training as complete
      this.trainingStatus.set(agent, { status: 'completed', progress: 100 });

    } catch (error) {
      console.error(`Error training agent ${agent}:`, error);
      this.trainingStatus.set(agent, { 
        status: 'error', 
        progress: 0,
        error: error instanceof Error ? error.message : 'Unknown error occurred'
      });
      throw error;
    }
  }

  private async processForAgent(agent: Agent, data: any): Promise<any> {
    // Agent-specific processing logic
    switch (agent) {
      case 'Occam':
        return this.processForOccam(data);
      case 'Project Manager':
        return this.processForProjectManager(data);
      case 'Earned Value Management Professional':
        return this.processForEVMProfessional(data);
      default:
        return data;
    }
  }

  private async processForOccam(data: any): Promise<any> {
    // Occam-specific processing
    return {
      ...data,
      metadata: {
        ...data.metadata,
        processedFor: 'Occam',
        capabilities: [
          'IPMDAR Analysis',
          'Project Management',
          'Data Validation',
          'Risk Assessment',
          'Performance Analysis'
        ]
      }
    };
  }

  private async processForProjectManager(data: any): Promise<any> {
    // Project Manager specific processing
    return {
      ...data,
      metadata: {
        ...data.metadata,
        processedFor: 'Project Manager',
        capabilities: [
          'Project Planning',
          'Resource Management',
          'Schedule Analysis',
          'Risk Management',
          'Performance Tracking'
        ]
      }
    };
  }

  private async processForEVMProfessional(data: any): Promise<any> {
    // EVM Professional specific processing
    return {
      ...data,
      metadata: {
        ...data.metadata,
        processedFor: 'EVM Professional',
        capabilities: [
          'EVM Analysis',
          'Cost Control',
          'Performance Measurement',
          'Variance Analysis',
          'Forecasting'
        ]
      }
    };
  }

  private async saveTrainingData(agent: Agent, data: any): Promise<void> {
    const key = `training_data_${agent}`;
    try {
      await saveToStorage(key, data);
    } catch (error) {
      console.error(`Error saving training data for ${agent}:`, error);
      throw error;
    }
  }

  public getTrainingStatus(agent: Agent) {
    return this.trainingStatus.get(agent) || { 
      status: 'pending', 
      progress: 0 
    };
  }

  public isAgentTrained(agent: Agent): boolean {
    const status = this.trainingStatus.get(agent);
    return status?.status === 'completed';
  }
}

export default KnowledgeTrainingService;