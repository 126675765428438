import { useState, useCallback } from 'react';
import { ValidationResult, ValidationError, ValidationWarning } from '../types';

interface ValidationRules {
  [key: string]: {
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    pattern?: RegExp;
    custom?: (value: any) => boolean;
    message?: string;
  };
}

export const useFormValidation = (rules: ValidationRules) => {
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [warnings, setWarnings] = useState<ValidationWarning[]>([]);

  const validate = useCallback((data: { [key: string]: any }): ValidationResult => {
    const newErrors: ValidationError[] = [];
    const newWarnings: ValidationWarning[] = [];

    Object.entries(rules).forEach(([field, fieldRules]) => {
      const value = data[field];

      // Required field validation
      if (fieldRules.required && (!value || value.toString().trim() === '')) {
        newErrors.push({
          field,
          message: fieldRules.message || `${field} is required`,
          code: 'REQUIRED',
        });
      }

      if (value) {
        // Minimum length validation
        if (fieldRules.minLength && value.length < fieldRules.minLength) {
          newErrors.push({
            field,
            message: `${field} must be at least ${fieldRules.minLength} characters`,
            code: 'MIN_LENGTH',
          });
        }

        // Maximum length validation
        if (fieldRules.maxLength && value.length > fieldRules.maxLength) {
          newErrors.push({
            field,
            message: `${field} must be no more than ${fieldRules.maxLength} characters`,
            code: 'MAX_LENGTH',
          });
        }

        // Pattern validation
        if (fieldRules.pattern && !fieldRules.pattern.test(value)) {
          newErrors.push({
            field,
            message: fieldRules.message || `${field} format is invalid`,
            code: 'PATTERN',
          });
        }

        // Custom validation
        if (fieldRules.custom && !fieldRules.custom(value)) {
          newErrors.push({
            field,
            message: fieldRules.message || `${field} is invalid`,
            code: 'CUSTOM',
          });
        }
      }
    });

    setErrors(newErrors);
    setWarnings(newWarnings);

    return {
      isValid: newErrors.length === 0,
      errors: newErrors,
      warnings: newWarnings,
    };
  }, [rules]);

  return {
    errors,
    warnings,
    validate,
    clearErrors: () => setErrors([]),
    clearWarnings: () => setWarnings([]),
  };
};

export default useFormValidation;