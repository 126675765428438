import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Play, RefreshCw, CheckCircle, XCircle, AlertTriangle, Filter, ChevronDown, ChevronUp, Brain, Download, FileText, Code, Database, History } from 'lucide-react';
import TestRunner from './TestRunner';
import TestResults from './TestResults';
import TestMetrics from './TestMetrics';
import TestHistoryDashboard from './TestHistoryDashboard';
import { useTestSuite } from '../hooks/useTestSuite';

interface TestCase {
  id: string;
  name: string;
  description: string;
  test: () => Promise<boolean>;
  category: string;
  document: string;
  expectedResult: string;
}

const OccamEvalTestPage: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [expandedTests, setExpandedTests] = useState<string[]>([]);
  const [exportFormat, setExportFormat] = useState<'json' | 'csv' | 'pdf'>('json');
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [activeTab, setActiveTab] = useState<'ipmdar' | 'proteus' | 'eval' | 'history'>('ipmdar');

  // Test case generators remain the same...
  const generateIPMDARTests = (): TestCase[] => {
    const testCases: TestCase[] = [];
    const documents = [
      'IPMDAR BCR.proteus',
      'DECM Overview.proteus',
      'DECM Process Overview.proteus',
    ];

    documents.forEach(doc => {
      for (let i = 1; i <= 5; i++) {
        testCases.push({
          id: `${doc}-test-${i}`,
          name: `Test Case ${i} for ${doc}`,
          description: `Comprehensive test case ${i} for ${doc}`,
          test: async () => Math.random() > 0.2,
          category: doc.split('.')[0].split(' ')[0].toLowerCase(),
          document: doc,
          expectedResult: 'Expected test behavior and outcomes'
        });
      }
    });

    return testCases;
  };

  const generateProteusTests = (): TestCase[] => {
    return [
      {
        id: 'proteus-integration-1',
        name: 'Proteus Data Integration',
        description: 'Verify data integration with Proteus Envision Suite',
        test: async () => Math.random() > 0.2,
        category: 'integration',
        document: 'Proteus Integration Spec',
        expectedResult: 'Successful data synchronization'
      },
      {
        id: 'proteus-api-1',
        name: 'Proteus API Communication',
        description: 'Test API endpoints and responses',
        test: async () => Math.random() > 0.2,
        category: 'api',
        document: 'API Documentation',
        expectedResult: 'Valid API responses'
      }
    ];
  };

  const generateEvalTests = (): TestCase[] => {
    return [
      {
        id: 'eval-hallucination-1',
        name: 'Hallucination Detection',
        description: 'Test AI response accuracy and hallucination detection',
        test: async () => Math.random() > 0.2,
        category: 'deepeval',
        document: 'AI Evaluation Metrics',
        expectedResult: 'No hallucinations detected'
      },
      {
        id: 'eval-consistency-1',
        name: 'Response Consistency',
        description: 'Verify consistent responses across similar queries',
        test: async () => Math.random() > 0.2,
        category: 'deepeval',
        document: 'Consistency Metrics',
        expectedResult: 'Consistent responses maintained'
      },
      {
        id: 'eval-context-1',
        name: 'Context Awareness',
        description: 'Test AI understanding of context and requirements',
        test: async () => Math.random() > 0.2,
        category: 'deepeval',
        document: 'Context Analysis',
        expectedResult: 'Proper context understanding'
      },
      {
        id: 'eval-bias-1',
        name: 'Bias Detection',
        description: 'Check for potential biases in AI responses',
        test: async () => Math.random() > 0.2,
        category: 'deepeval',
        document: 'Bias Analysis',
        expectedResult: 'No significant bias detected'
      },
      {
        id: 'eval-performance-1',
        name: 'Performance Metrics',
        description: 'Evaluate response time and resource usage',
        test: async () => Math.random() > 0.2,
        category: 'deepeval',
        document: 'Performance Metrics',
        expectedResult: 'Optimal performance levels'
      }
    ];
  };

  const getActiveTests = () => {
    switch (activeTab) {
      case 'ipmdar':
        return generateIPMDARTests();
      case 'proteus':
        return generateProteusTests();
      case 'eval':
        return generateEvalTests();
      default:
        return [];
    }
  };

  const testCases = getActiveTests();
  const { results, isRunning, currentTest, runAllTests } = useTestSuite(testCases);

  const handleExport = () => {
    const exportData = {
      timestamp: new Date().toISOString(),
      results,
      summary: {
        total: results.length,
        passed: results.filter(r => r.passed).length,
        failed: results.filter(r => !r.passed).length,
      }
    };

    const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `occam-eval-results-${new Date().toISOString()}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const renderTabContent = () => {
    if (activeTab === 'history') {
      return <TestHistoryDashboard />;
    }

    return (
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 space-y-6">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4 flex items-center">
              <Brain className="mr-2 text-blue-500" size={24} />
              Test Categories
            </h3>
            <div className="flex flex-wrap gap-2">
              {['all', ...new Set(testCases.map(test => test.category))].map(category => (
                <button
                  key={category}
                  onClick={() => setSelectedCategory(category)}
                  className={`px-4 py-2 rounded-lg ${
                    selectedCategory === category
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                  }`}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </button>
              ))}
            </div>
          </div>

          <div className="space-y-4">
            {testCases
              .filter(test => selectedCategory === 'all' || test.category === selectedCategory)
              .map(test => (
                <motion.div
                  key={test.id}
                  layout
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md"
                >
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() => setExpandedTests(prev =>
                      prev.includes(test.id)
                        ? prev.filter(id => id !== test.id)
                        : [...prev, test.id]
                    )}
                  >
                    <div>
                      <h3 className="text-lg font-semibold">{test.name}</h3>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Document: {test.document}
                      </p>
                    </div>
                    <div className="flex items-center space-x-4">
                      {results.find(r => r.id === test.id)?.passed ? (
                        <CheckCircle className="text-green-500" size={24} />
                      ) : results.find(r => r.id === test.id)?.error ? (
                        <XCircle className="text-red-500" size={24} />
                      ) : (
                        <AlertTriangle className="text-gray-400" size={24} />
                      )}
                      {expandedTests.includes(test.id) ? (
                        <ChevronUp size={20} />
                      ) : (
                        <ChevronDown size={20} />
                      )}
                    </div>
                  </div>

                  <AnimatePresence>
                    {expandedTests.includes(test.id) && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="mt-4 pt-4 border-t dark:border-gray-700"
                      >
                        <div className="space-y-4">
                          <div>
                            <h4 className="font-semibold mb-2">Description</h4>
                            <p className="text-gray-600 dark:text-gray-400">
                              {test.description}
                            </p>
                          </div>
                          <div>
                            <h4 className="font-semibold mb-2">Expected Result</h4>
                            <p className="text-gray-600 dark:text-gray-400">
                              {test.expectedResult}
                            </p>
                          </div>
                          {results.find(r => r.id === test.id) && (
                            <TestResults
                              testId={test.id}
                              results={results}
                            />
                          )}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              ))}
          </div>
        </div>

        <div className="space-y-6">
          <TestMetrics
            results={results}
            tests={testCases}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white">OccamEval Test Suite</h2>
        <div className="flex space-x-4">
          <button
            onClick={() => setShowExportOptions(!showExportOptions)}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200 flex items-center"
          >
            <Download size={20} className="mr-2" />
            Export Results
          </button>
          <button
            onClick={() => runAllTests()}
            disabled={isRunning}
            className={`bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors duration-200 flex items-center ${
              isRunning ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isRunning ? (
              <RefreshCw className="animate-spin mr-2" size={20} />
            ) : (
              <Play className="mr-2" size={20} />
            )}
            {isRunning ? 'Running Tests...' : 'Run All Tests'}
          </button>
        </div>
      </div>

      <div className="flex space-x-4 border-b border-gray-200 dark:border-gray-700 mb-6">
        <button
          onClick={() => setActiveTab('ipmdar')}
          className={`px-4 py-2 font-medium ${
            activeTab === 'ipmdar'
              ? 'border-b-2 border-blue-500 text-blue-500'
              : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300'
          }`}
        >
          <FileText className="inline-block mr-2" size={16} />
          IPMDAR Tests
        </button>
        <button
          onClick={() => setActiveTab('proteus')}
          className={`px-4 py-2 font-medium ${
            activeTab === 'proteus'
              ? 'border-b-2 border-blue-500 text-blue-500'
              : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300'
          }`}
        >
          <Database className="inline-block mr-2" size={16} />
          Proteus Tests
        </button>
        <button
          onClick={() => setActiveTab('eval')}
          className={`px-4 py-2 font-medium ${
            activeTab === 'eval'
              ? 'border-b-2 border-blue-500 text-blue-500'
              : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300'
          }`}
        >
          <Brain className="inline-block mr-2" size={16} />
          Eval Tests
        </button>
        <button
          onClick={() => setActiveTab('history')}
          className={`px-4 py-2 font-medium ${
            activeTab === 'history'
              ? 'border-b-2 border-blue-500 text-blue-500'
              : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300'
          }`}
        >
          <History className="inline-block mr-2" size={16} />
          Test History
        </button>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.2 }}
        >
          {renderTabContent()}
        </motion.div>
      </AnimatePresence>

      <AnimatePresence>
        {showExportOptions && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg z-10"
          >
            <div className="p-2">
              <button
                onClick={() => {
                  setExportFormat('json');
                  handleExport();
                  setShowExportOptions(false);
                }}
                className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
              >
                Export as JSON
              </button>
              <button
                onClick={() => {
                  setExportFormat('csv');
                  handleExport();
                  setShowExportOptions(false);
                }}
                className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
              >
                Export as CSV
              </button>
              <button
                onClick={() => {
                  setExportFormat('pdf');
                  handleExport();
                  setShowExportOptions(false);
                }}
                className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
              >
                Export as PDF
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default OccamEvalTestPage;