import React from 'react';
import { Calendar, AlertTriangle, CheckCircle, Clock, TrendingUp } from 'lucide-react';
import { Bar } from 'react-chartjs-2';

interface ScheduleMetrics {
  slippage: number;
  criticalPath: string[];
  delays: Array<{
    activity: string;
    delay: number;
    cause: string;
  }>;
}

const ScheduleTab: React.FC<{ metrics?: ScheduleMetrics }> = ({ metrics }) => {
  const scheduleHealthData = {
    labels: ['On Track', 'At Risk', 'Delayed', 'Completed'],
    datasets: [{
      data: [65, 20, 10, 5],
      backgroundColor: [
        'rgba(34, 197, 94, 0.6)',
        'rgba(234, 179, 8, 0.6)',
        'rgba(239, 68, 68, 0.6)',
        'rgba(59, 130, 246, 0.6)'
      ],
      borderColor: [
        'rgb(34, 197, 94)',
        'rgb(234, 179, 8)',
        'rgb(239, 68, 68)',
        'rgb(59, 130, 246)'
      ],
      borderWidth: 1
    }]
  };

  return (
    <div className="space-y-6">
      {/* Schedule Overview Card */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4 flex items-center text-gray-800 dark:text-white">
          <Calendar className="mr-2 text-blue-500" size={24} />
          Schedule Overview
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Schedule Performance Index</p>
                <p className="text-2xl font-bold text-green-500">0.98</p>
              </div>
              <TrendingUp className="text-green-500" size={24} />
            </div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Critical Path Tasks</p>
                <p className="text-2xl font-bold text-blue-500">12</p>
              </div>
              <Clock className="text-blue-500" size={24} />
            </div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Schedule Variance</p>
                <p className="text-2xl font-bold text-yellow-500">-5 days</p>
              </div>
              <AlertTriangle className="text-yellow-500" size={24} />
            </div>
          </div>
        </div>
      </div>

      {/* Schedule Health Chart */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Schedule Health</h3>
        <div className="h-64">
          <Bar 
            data={scheduleHealthData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                }
              }
            }}
          />
        </div>
      </div>

      {/* Critical Path Analysis */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Critical Path Analysis</h3>
        <div className="space-y-4">
          {metrics?.criticalPath.map((task, index) => (
            <div key={index} className="flex items-center space-x-2 p-2 bg-gray-50 dark:bg-gray-700 rounded">
              <div className="flex-shrink-0">
                {index + 1}.
              </div>
              <div className="flex-1">
                <p className="text-gray-800 dark:text-gray-200">{task}</p>
              </div>
              <CheckCircle className="text-green-500 flex-shrink-0" size={20} />
            </div>
          ))}
        </div>
      </div>

      {/* Schedule Delays */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Schedule Delays</h3>
        <div className="space-y-4">
          {metrics?.delays.map((delay, index) => (
            <div key={index} className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <div className="flex items-center justify-between mb-2">
                <h4 className="font-semibold text-gray-800 dark:text-gray-200">{delay.activity}</h4>
                <span className="px-3 py-1 bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200 rounded-full text-sm">
                  {delay.delay} days
                </span>
              </div>
              <p className="text-gray-600 dark:text-gray-400 text-sm">{delay.cause}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScheduleTab;