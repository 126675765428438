import React, { useState } from 'react';
import { Table, Search, Plus, Edit, Trash2, Download, Upload, RefreshCw, Database, FileText, Code, AlertTriangle, X, ChevronUp, ChevronDown } from 'lucide-react';
import { format } from 'sql-formatter';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ProteusService from '../services/ProteusService';
import ScheduleTab from './tabs/ScheduleTab';
import BudgetTab from './tabs/BudgetTab';
import ResourceTab from './tabs/ResourceTab';

interface SQLTable {
  name: string;
  description: string;
  columns: Array<{
    name: string;
    type: string;
    nullable: boolean;
    description: string;
  }>;
}

const RiskyProject: React.FC = () => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<'schedule' | 'budget' | 'resources' | 'proteus' | 'sql'>('schedule');
  const [connectionStatus, setConnectionStatus] = useState<'disconnected' | 'connecting' | 'connected'>('disconnected');
  const [selectedTable, setSelectedTable] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedTables, setExpandedTables] = useState<string[]>([]);

  const tables: SQLTable[] = [
    {
      name: 'RP_Bands',
      description: 'Stores band information for risk analysis',
      columns: [
        { name: 'band_id', type: 'int', nullable: false, description: 'Unique identifier for the band' },
        { name: 'band_name', type: 'nvarchar(max)', nullable: true, description: 'Name of the band' },
        { name: 'band_low', type: 'real', nullable: true, description: 'Lower bound value' },
        { name: 'band_high', type: 'real', nullable: true, description: 'Upper bound value' },
        { name: 'band_color', type: 'int', nullable: true, description: 'Color code for visualization' }
      ]
    },
    {
      name: 'RP_Risk_Status',
      description: 'Tracks risk status and metrics',
      columns: [
        { name: 'riskstatus_guid', type: 'nvarchar(max)', nullable: true, description: 'Unique identifier for risk status' },
        { name: 'riskstatus_date', type: 'datetime', nullable: true, description: 'Date of status update' },
        { name: 'riskstatus_name', type: 'nvarchar(max)', nullable: true, description: 'Name of the risk status' },
        { name: 'riskstatus_risk_name', type: 'nvarchar(max)', nullable: false, description: 'Associated risk name' },
        { name: 'riskstatus_probability', type: 'real', nullable: true, description: 'Probability of risk occurrence' }
      ]
    },
    {
      name: 'RP_Projects',
      description: 'Main project information table',
      columns: [
        { name: 'project_id', type: 'int', nullable: false, description: 'Unique identifier for the project' },
        { name: 'project_name', type: 'nvarchar(max)', nullable: false, description: 'Name of the project' },
        { name: 'version', type: 'nvarchar(max)', nullable: true, description: 'Project version' },
        { name: 'description', type: 'nvarchar(max)', nullable: true, description: 'Project description' },
        { name: 'start_time', type: 'datetime', nullable: true, description: 'Project start time' }
      ]
    }
  ];

  const connectToProteus = async () => {
    setIsConnecting(true);
    setError(null);
    setConnectionStatus('connecting');
    
    try {
      const proteusService = ProteusService.getInstance();
      await proteusService.connect({
        server: process.env.PROTEUS_SERVER || 'localhost',
        database: 'RiskyProject',
        username: process.env.PROTEUS_USERNAME || 'admin',
        password: process.env.PROTEUS_PASSWORD || 'admin',
        options: {
          encrypt: true,
          trustServerCertificate: false
        }
      });
      
      setConnectionStatus('connected');
    } catch (error) {
      setError('Failed to connect to Proteus. Please check your credentials and try again.');
      setConnectionStatus('disconnected');
      console.error('Connection error:', error);
    } finally {
      setIsConnecting(false);
    }
  };

  const toggleTableExpansion = (tableName: string) => {
    setExpandedTables(prev => 
      prev.includes(tableName) ? prev.filter(t => t !== tableName) : [...prev, tableName]
    );
  };

  const filteredTables = tables.filter(table => 
    table.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    table.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    table.columns.some(col => 
      col.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      col.description.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const getTableCreateSQL = (table: SQLTable): string => {
    const columnDefinitions = table.columns.map(col => {
      let definition = `  ${col.name} ${col.type}`;
      if (!col.nullable) {
        definition += ' NOT NULL';
      }
      return definition;
    }).join(',\n');

    return `CREATE TABLE ${table.name} (\n${columnDefinitions}\n)`;
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white">RiskyProject & Proteus Analytics</h2>
        <div className="flex space-x-2">
          <button
            onClick={connectToProteus}
            disabled={isConnecting || connectionStatus === 'connected'}
            className={`bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200 flex items-center ${
              (isConnecting || connectionStatus === 'connected') ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isConnecting ? (
              <RefreshCw className="animate-spin mr-2" size={20} />
            ) : (
              <Database className="mr-2" size={20} />
            )}
            {isConnecting ? 'Connecting...' : connectionStatus === 'connected' ? 'Connected' : 'Connect to Proteus'}
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      {/* Navigation Tabs */}
      <div className="flex space-x-4 border-b border-gray-200 dark:border-gray-700">
        {(['schedule', 'budget', 'resources', 'proteus', 'sql'] as const).map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`px-4 py-2 font-medium text-sm ${
              activeTab === tab
                ? 'border-b-2 border-blue-500 text-blue-500'
                : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300'
            }`}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="mt-6">
        {activeTab === 'schedule' && <ScheduleTab />}
        {activeTab === 'budget' && <BudgetTab />}
        {activeTab === 'resources' && <ResourceTab />}
        {activeTab === 'proteus' && (
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">Proteus Analytics</h3>
            {/* Add Proteus analytics content here */}
          </div>
        )}
        {activeTab === 'sql' && (
          <div className="space-y-4">
            <div className="flex space-x-4 mb-4">
              <div className="flex-1">
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                  <input
                    type="text"
                    placeholder="Search tables, columns, or descriptions..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-6">
              {filteredTables.map((table) => (
                <div key={table.name} className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                  <div 
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() => toggleTableExpansion(table.name)}
                  >
                    <div className="flex items-center space-x-3">
                      <Table className="text-blue-500" size={24} />
                      <div>
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-white">{table.name}</h3>
                        <p className="text-sm text-gray-600 dark:text-gray-400">{table.description}</p>
                      </div>
                    </div>
                    {expandedTables.includes(table.name) ? (
                      <ChevronUp size={20} />
                    ) : (
                      <ChevronDown size={20} />
                    )}
                  </div>

                  {expandedTables.includes(table.name) && (
                    <div className="mt-4 space-y-4">
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                          <thead className="bg-gray-50 dark:bg-gray-700">
                            <tr>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Column</th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Type</th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Nullable</th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Description</th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                            {table.columns.map((column, index) => (
                              <tr key={column.name} className={index % 2 === 0 ? 'bg-gray-50 dark:bg-gray-900' : ''}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">{column.name}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">{column.type}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                  {column.nullable ? 'Yes' : 'No'}
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-400">{column.description}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="mt-6">
                        <h4 className="text-lg font-semibold mb-2 text-gray-800 dark:text-white">Create Table SQL</h4>
                        <SyntaxHighlighter
                          language="sql"
                          style={atomOneDark}
                          className="rounded-lg"
                        >
                          {format(getTableCreateSQL(table), {
                            language: 'sql',
                            uppercase: true,
                            linesBetweenQueries: 2
                          })}
                        </SyntaxHighlighter>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RiskyProject;