import React from 'react';
import { DollarSign, TrendingUp, TrendingDown, AlertCircle } from 'lucide-react';
import { Line } from 'react-chartjs-2';

interface BudgetMetrics {
  overruns: Array<{
    category: string;
    amount: number;
    trend: 'increasing' | 'decreasing' | 'stable';
  }>;
  spendingPatterns: Array<{
    period: string;
    planned: number;
    actual: number;
  }>;
}

const BudgetTab: React.FC<{ metrics?: BudgetMetrics }> = ({ metrics }) => {
  const spendingData = {
    labels: metrics?.spendingPatterns.map(p => p.period) || [],
    datasets: [
      {
        label: 'Planned Spending',
        data: metrics?.spendingPatterns.map(p => p.planned) || [],
        borderColor: 'rgb(59, 130, 246)',
        tension: 0.1,
        fill: false
      },
      {
        label: 'Actual Spending',
        data: metrics?.spendingPatterns.map(p => p.actual) || [],
        borderColor: 'rgb(239, 68, 68)',
        tension: 0.1,
        fill: false
      }
    ]
  };

  return (
    <div className="space-y-6">
      {/* Budget Overview */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4 flex items-center text-gray-800 dark:text-white">
          <DollarSign className="mr-2 text-green-500" size={24} />
          Budget Overview
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Total Budget</p>
                <p className="text-2xl font-bold text-green-500">$2.5M</p>
              </div>
              <DollarSign className="text-green-500" size={24} />
            </div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Spent to Date</p>
                <p className="text-2xl font-bold text-blue-500">$1.2M</p>
              </div>
              <TrendingUp className="text-blue-500" size={24} />
            </div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Remaining</p>
                <p className="text-2xl font-bold text-yellow-500">$1.3M</p>
              </div>
              <AlertCircle className="text-yellow-500" size={24} />
            </div>
          </div>
        </div>
      </div>

      {/* Spending Trends */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Spending Trends</h3>
        <div className="h-80">
          <Line 
            data={spendingData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'top' as const
                }
              },
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            }}
          />
        </div>
      </div>

      {/* Cost Overruns */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Cost Overruns</h3>
        <div className="space-y-4">
          {metrics?.overruns.map((overrun, index) => (
            <div key={index} className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <div className="flex items-center justify-between">
                <div>
                  <h4 className="font-semibold text-gray-800 dark:text-gray-200">{overrun.category}</h4>
                  <p className="text-2xl font-bold text-red-500">
                    ${overrun.amount.toLocaleString()}
                  </p>
                </div>
                {overrun.trend === 'increasing' ? (
                  <TrendingUp className="text-red-500" size={24} />
                ) : overrun.trend === 'decreasing' ? (
                  <TrendingDown className="text-green-500" size={24} />
                ) : (
                  <div className="w-6 h-0.5 bg-gray-400" />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BudgetTab;