import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useKnowledgeBase from '../hooks/useKnowledgeBase';
import KnowledgeBaseHeader from './knowledge/KnowledgeBaseHeader';
import KnowledgeBaseSearch from './knowledge/KnowledgeBaseSearch';
import KnowledgeBaseFileList from './knowledge/KnowledgeBaseFileList';
import KnowledgeBaseAnalytics from './knowledge/KnowledgeBaseAnalytics';
import KnowledgeBaseBatchActions from './knowledge/KnowledgeBaseBatchActions';
import KnowledgeBaseFilters from './knowledge/KnowledgeBaseFilters';
import AgentTrainingCard from './AgentTrainingCard';
import AgentTrainingStatus from './knowledge/AgentTrainingStatus';
import { Agent } from '../types';
import { motion } from 'framer-motion';
import { Upload, AlertCircle } from 'lucide-react';
import KnowledgeTrainingService from '../services/KnowledgeTrainingService';

interface UnifiedKnowledgeManagementProps {
  userRole: string;
}

const UnifiedKnowledgeManagement: React.FC<UnifiedKnowledgeManagementProps> = ({ userRole }) => {
  const [activeSection, setActiveSection] = useState<'knowledge' | 'agent'>('knowledge');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [isTraining, setIsTraining] = useState(false);
  const [trainingError, setTrainingError] = useState<string | null>(null);
  const [filters, setFilters] = useState({
    type: 'all',
    category: 'all',
    dateRange: 'all'
  });
  const { files, isProcessing, addFiles, removeFile, updateFile } = useKnowledgeBase();
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const [agents] = useState<Agent[]>([
    'Occam',
    'Project Manager',
    'Earned Value Management Professional',
    'Program Manager',
    'Cost Control Analyst',
    'Schedule Analyst',
    'Risk Manager',
    'Performance Data Analyst',
    'Project Controller',
    'Quality Controller'
  ]);

  const onDrop = async (acceptedFiles: File[]) => {
    await addFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/plain': ['.txt'],
      'application/json': ['.json'],
      'text/csv': ['.csv'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/zip': ['.zip']
    }
  });

  const handleBatchAction = async (action: 'delete' | 'categorize' | 'export' | 'train', category?: string) => {
    if (action === 'train') {
      setIsTraining(true);
      setTrainingError(null);
      const trainingService = KnowledgeTrainingService.getInstance();

      try {
        for (const agent of agents) {
          await trainingService.trainAgent(agent);
        }
      } catch (error) {
        setTrainingError(error instanceof Error ? error.message : 'Failed to train AI agents');
      } finally {
        setIsTraining(false);
      }
      return;
    }

    switch (action) {
      case 'delete':
        selectedFiles.forEach(id => removeFile(id));
        setSelectedFiles([]);
        break;
      case 'categorize':
        if (category) {
          selectedFiles.forEach(id => {
            const file = files.find(f => f.id === id);
            if (file) {
              updateFile(id, { ...file, category });
            }
          });
        }
        break;
      case 'export':
        const selectedData = files.filter(file => selectedFiles.includes(file.id));
        const blob = new Blob([JSON.stringify(selectedData, null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `knowledge-base-export-${new Date().toISOString().split('T')[0]}.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        break;
    }
  };

  return (
    <div className="space-y-6 p-6">
      <KnowledgeBaseHeader 
        activeSection={activeSection} 
        setActiveSection={setActiveSection} 
      />

      {trainingError && (
        <div className="bg-red-100 dark:bg-red-900 p-4 rounded-lg flex items-center text-red-700 dark:text-red-200">
          <AlertCircle className="mr-2" size={20} />
          {trainingError}
        </div>
      )}

      {activeSection === 'knowledge' ? (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2 space-y-6">
            <div {...getRootProps()} className={`border-2 border-dashed rounded-lg p-8 text-center transition-colors duration-200 ${
              isDragActive ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/30' : 'border-gray-300 dark:border-gray-600'
            }`}>
              <input {...getInputProps()} />
              <Upload className={`mx-auto h-12 w-12 ${
                isDragActive ? 'text-blue-500' : 'text-gray-400'
              }`} />
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                {isDragActive ? 'Drop files here...' : 'Drag and drop files here, or click to select files'}
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-500 mt-1">
                Supported formats: TXT, JSON, CSV, PDF, DOC, DOCX, ZIP
              </p>
            </div>

            <div className="flex space-x-4">
              <KnowledgeBaseSearch
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                onUpload={onDrop}
                isProcessing={isProcessing}
                fileInputRef={fileInputRef}
              />
              <KnowledgeBaseFilters
                filters={filters}
                setFilters={setFilters}
              />
            </div>

            <KnowledgeBaseBatchActions
              selectedCount={selectedFiles.length}
              onAction={handleBatchAction}
              isTraining={isTraining}
            />

            <KnowledgeBaseFileList 
              files={files} 
              onRemove={removeFile}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              filters={filters}
              searchQuery={searchQuery}
            />
          </div>

          <div className="space-y-6">
            <KnowledgeBaseAnalytics files={files} />
          </div>
        </div>
      ) : (
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {agents.map((agent, index) => (
              <AgentTrainingCard 
                key={index} 
                agent={{
                  name: agent,
                  role: 'AI Assistant',
                  status: KnowledgeTrainingService.getInstance().isAgentTrained(agent) ? 'completed' : 'training',
                  progress: KnowledgeTrainingService.getInstance().getTrainingStatus(agent).progress,
                  learningMetrics: {
                    accuracy: 98,
                    dataProcessed: 1024,
                    estimatedTimeRemaining: '0s'
                  }
                }} 
              />
            ))}
          </div>
          <AgentTrainingStatus agents={agents} />
        </div>
      )}
    </div>
  );
};

export default UnifiedKnowledgeManagement;