import React from 'react';
import { Sun, Moon, Link, LogOut } from 'lucide-react';

interface DashboardHeaderProps {
  darkMode: boolean;
  setDarkMode: (mode: boolean) => void;
  setUser: (user: string | null) => void;
  setShowConnectModal: (show: boolean) => void;
}

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  darkMode,
  setDarkMode,
  setUser,
  setShowConnectModal
}) => {
  return (
    <header className="bg-white dark:bg-gray-800 shadow-md">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Occam Dashboard</h1>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setDarkMode(!darkMode)}
            className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
            title={darkMode ? 'Light Mode' : 'Dark Mode'}
          >
            {darkMode ? <Sun size={24} /> : <Moon size={24} />}
          </button>
          <button
            onClick={() => setShowConnectModal(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200 flex items-center"
          >
            <Link size={20} className="mr-2" />
            Connect to Proteus
          </button>
          <button
            onClick={() => setUser(null)}
            className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
            title="Logout"
          >
            <LogOut size={24} />
          </button>
        </div>
      </div>
    </header>
  );
};