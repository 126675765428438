import React from 'react';
import { Settings, Key, Bot, Database } from 'lucide-react';
import SlidingPanel from './SlidingPanel';

interface SettingsPanelProps {
  isOpen: boolean;
  onClose: () => void;
  selectedLLM: string;
  apiKey: string;
  onLLMChange: (llm: string) => void;
  onApiKeyChange: (key: string) => void;
  onApiKeySubmit: () => void;
}

const SettingsPanel: React.FC<SettingsPanelProps> = ({
  isOpen,
  onClose,
  selectedLLM,
  apiKey,
  onLLMChange,
  onApiKeyChange,
  onApiKeySubmit,
}) => {
  return (
    <SlidingPanel
      isOpen={isOpen}
      onClose={onClose}
      title="Settings"
      position="right"
      width="w-96"
    >
      <div className="space-y-6">
        <div className="space-y-4">
          <h3 className="text-lg font-semibold flex items-center">
            <Bot className="mr-2" />
            Model Settings
          </h3>
          <div>
            <label className="block text-sm font-medium mb-1">Language Model</label>
            <select
              value={selectedLLM}
              onChange={(e) => onLLMChange(e.target.value)}
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500 dark:bg-dark-primary dark:border-gray-600"
            >
              <option value="gpt4">ChatGPT-4</option>
              <option value="claude">Claude Sonnet 3.5</option>
              <option value="mistral">Mistral</option>
              <option value="groq">Groq</option>
              <option value="gemini">Gemini</option>
            </select>
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-semibold flex items-center">
            <Key className="mr-2" />
            API Configuration
          </h3>
          <div>
            <label className="block text-sm font-medium mb-1">API Key</label>
            <div className="flex space-x-2">
              <input
                type="password"
                value={apiKey}
                onChange={(e) => onApiKeyChange(e.target.value)}
                className="flex-1 p-2 border rounded-lg focus:ring-2 focus:ring-primary-500 dark:bg-dark-primary dark:border-gray-600"
                placeholder="Enter API Key"
              />
              <button
                onClick={onApiKeySubmit}
                className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors duration-200"
              >
                Save
              </button>
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-semibold flex items-center">
            <Database className="mr-2" />
            Vector Database
          </h3>
          <div className="p-4 bg-light-primary dark:bg-dark-primary rounded-lg">
            <p className="text-sm">
              The vector database is used to store and retrieve knowledge embeddings for improved AI responses.
            </p>
            <div className="mt-2 text-sm">
              <div className="flex justify-between">
                <span>Total Entries:</span>
                <span className="font-semibold">1,234</span>
              </div>
              <div className="flex justify-between">
                <span>Last Updated:</span>
                <span className="font-semibold">2 hours ago</span>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-4 border-t dark:border-gray-700">
          <button
            onClick={onClose}
            className="w-full px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200"
          >
            Close Settings
          </button>
        </div>
      </div>
    </SlidingPanel>
  );
};

export default SettingsPanel;