import React, { useState, useEffect } from 'react';
import { Activity, Server, Database, Clock, AlertTriangle, CheckCircle, RefreshCw, FileText, BarChart2, Bot, X } from 'lucide-react';

const UnifiedHealthMonitoring: React.FC = () => {
  const [activeSection, setActiveSection] = useState<'ipmdar' | 'system'>('ipmdar');
  const [isLoading, setIsLoading] = useState(true);
  const [metrics, setMetrics] = useState([
    { icon: Server, title: 'Server Status', value: 'Online', color: 'text-green-500' },
    { icon: Database, title: 'Database Connection', value: 'Stable', color: 'text-green-500' },
    { icon: Activity, title: 'API Response Time', value: '45ms', color: 'text-blue-500' },
    { icon: Clock, title: 'Uptime', value: '99.9%', color: 'text-blue-500' },
  ]);

  const ipmdarMetrics = [
    { icon: FileText, title: 'IPMDAR Submissions', value: '12/12', color: 'text-green-500' },
    { icon: AlertTriangle, title: 'Data Anomalies', value: '3', color: 'text-yellow-500' },
    { icon: CheckCircle, title: 'Validation Rate', value: '98%', color: 'text-green-500' },
    { icon: Clock, title: 'Avg. Processing Time', value: '2.3s', color: 'text-blue-500' },
    { icon: BarChart2, title: 'Performance Index', value: '0.95', color: 'text-purple-500' },
  ];

  const ipmdarDatasets = [
    { name: 'Contract Data', status: 'Complete', lastUpdate: '2023-04-14' },
    { name: 'Time-Phased Data', status: 'Partial', lastUpdate: '2023-04-13' },
    { name: 'Performance Data', status: 'Complete', lastUpdate: '2023-04-14' },
    { name: 'Baseline Data', status: 'Complete', lastUpdate: '2023-04-12' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await new Promise(resolve => setTimeout(resolve, 1500));
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white">Health Monitoring</h2>
        <div className="flex space-x-4">
          <button
            onClick={() => setActiveSection('ipmdar')}
            className={`px-4 py-2 rounded-lg ${
              activeSection === 'ipmdar'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
            }`}
          >
            <Activity className="inline-block mr-2" size={20} />
            IPMDAR Health
          </button>
          <button
            onClick={() => setActiveSection('system')}
            className={`px-4 py-2 rounded-lg ${
              activeSection === 'system'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
            }`}
          >
            <Server className="inline-block mr-2" size={20} />
            System Health
          </button>
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <RefreshCw size={48} className="animate-spin text-blue-500" />
        </div>
      ) : (
        <>
          {activeSection === 'ipmdar' ? (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {ipmdarMetrics.map((metric, index) => (
                  <div key={index} className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md transition-all duration-200 hover:shadow-lg">
                    <div className="flex items-center space-x-4">
                      <metric.icon size={24} className={metric.color} />
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">{metric.title}</p>
                        <p className={`text-xl font-bold ${metric.color}`}>{metric.value}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold mb-4">IPMDAR Dataset Status</h3>
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th className="text-left py-2">Dataset</th>
                        <th className="text-left py-2">Status</th>
                        <th className="text-left py-2">Last Update</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ipmdarDatasets.map((dataset, index) => (
                        <tr key={index}>
                          <td className="py-2">{dataset.name}</td>
                          <td className="py-2">
                            <span className={`px-2 py-1 rounded-full text-xs ${
                              dataset.status === 'Complete' 
                                ? 'bg-green-100 text-green-800' 
                                : 'bg-yellow-100 text-yellow-800'
                            }`}>
                              {dataset.status}
                            </span>
                          </td>
                          <td className="py-2">{dataset.lastUpdate}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {metrics.map((metric, index) => (
                  <div key={index} className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                    <div className="flex items-center space-x-4">
                      <metric.icon size={24} className={metric.color} />
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">{metric.title}</p>
                        <p className={`text-xl font-bold ${metric.color}`}>{metric.value}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold mb-4">System Logs</h3>
                <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg max-h-60 overflow-auto">
                  <pre className="text-sm text-gray-700 dark:text-gray-300">
                    {`[2023-04-15 10:30:22] INFO: System startup complete
[2023-04-15 10:35:15] WARNING: High API response time detected
[2023-04-15 10:40:03] INFO: IPMDAR data validation completed
[2023-04-15 10:45:30] ERROR: Failed to process Contract Data for ProjectID: PRJ-001
[2023-04-15 10:50:12] INFO: Database connection refreshed`}
                  </pre>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UnifiedHealthMonitoring;