import React, { useState, useEffect } from 'react';
import { BarChart2, PieChart, LineChart, Download, Filter, Calendar, RefreshCw, Sliders, X, FileText, Eye, AlertTriangle, Bot, CheckCircle, Brain, Search, Play } from 'lucide-react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { motion, AnimatePresence } from 'framer-motion';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend);

interface TestResult {
  score: number;
  details: string;
  recommendations: string[];
  status: 'pending' | 'running' | 'completed';
  chartData?: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string[];
    }[];
  };
}

const QualityCheckPopup: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [qualityChecks, setQualityChecks] = useState([
    { id: 1, name: 'Data Completeness', status: 'passed', details: 'All required fields are present' },
    { id: 2, name: 'Format Validation', status: 'warning', details: 'Some date fields have inconsistent formats' },
    { id: 3, name: 'Data Consistency', status: 'failed', details: 'Discrepancies found between related data points' },
  ]);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return isVisible ? (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-2xl w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Quality Check Results</h3>
          <button onClick={() => setIsVisible(false)} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <div className="space-y-4">
          {qualityChecks.map(check => (
            <div key={check.id} className="border-b dark:border-gray-700 pb-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {check.status === 'passed' && <CheckCircle className="text-green-500 mr-2" size={20} />}
                  {check.status === 'warning' && <AlertTriangle className="text-yellow-500 mr-2" size={20} />}
                  {check.status === 'failed' && <X className="text-red-500 mr-2" size={20} />}
                  <span className="font-medium">{check.name}</span>
                </div>
                <span className={`px-2 py-1 rounded-full text-sm ${
                  check.status === 'passed' ? 'bg-green-100 text-green-800' :
                  check.status === 'warning' ? 'bg-yellow-100 text-yellow-800' :
                  'bg-red-100 text-red-800'
                }`}>
                  {check.status}
                </span>
              </div>
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">{check.details}</p>
            </div>
          ))}
        </div>
        <div className="mt-6 flex justify-end">
          <button
            onClick={() => setIsVisible(false)}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

const ReportingAnalytics: React.FC = () => {
  const [dateRange, setDateRange] = useState('last30');
  const [customDateRange, setCustomDateRange] = useState({ start: '', end: '' });
  const [showCustomDateRange, setShowCustomDateRange] = useState(false);
  const [aiAgentMessage, setAiAgentMessage] = useState<string>('AI Agent is analyzing data...');
  const [aiGeneratedCharts, setAiGeneratedCharts] = useState<any[]>([]);
  const [showQualityCheck, setShowQualityCheck] = useState(false);
  const [testResults, setTestResults] = useState<{ [key: string]: TestResult }>({
    hallucination: { score: 0, details: '', recommendations: [], status: 'pending' },
    bias: { score: 0, details: '', recommendations: [], status: 'pending' },
    consistency: { score: 0, details: '', recommendations: [], status: 'pending' },
    relevance: { score: 0, details: '', recommendations: [], status: 'pending' },
  });
  const [redTeamFeedback, setRedTeamFeedback] = useState<string>('');

  useEffect(() => {
    // Simulate AI agent analysis and chart generation
    const analyzeData = async () => {
      await new Promise(resolve => setTimeout(resolve, 2000));
      setAiAgentMessage('Analysis complete. Key insights: Performance metrics are trending positively. Recommend focusing on cost optimization and technical performance improvement.');
      
      // Generate sample charts with months instead of weeks
      setAiGeneratedCharts([
        {
          type: 'line',
          options: {
            plugins: { title: { display: true, text: 'Burndown Chart' } },
          },
          data: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
            datasets: [
              {
                label: 'Ideal Burndown',
                data: [100, 80, 60, 40, 20, 0],
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
              },
              {
                label: 'Actual Burndown',
                data: [100, 85, 75, 55, 40, 20],
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1,
              },
            ],
          },
        },
        {
          type: 'line',
          options: {
            plugins: { title: { display: true, text: 'Burn Up Chart' } },
          },
          data: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
            datasets: [
              {
                label: 'Total Scope',
                data: [100, 100, 100, 100, 100, 100],
                borderColor: 'rgb(54, 162, 235)',
                tension: 0.1,
              },
              {
                label: 'Completed Work',
                data: [0, 15, 25, 45, 60, 80],
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
              },
            ],
          },
        },
        {
          type: 'line',
          options: {
            plugins: { title: { display: true, text: 'Earned Value Management Chart' } },
          },
          data: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
            datasets: [
              {
                label: 'Planned Value (PV)',
                data: [10, 25, 40, 60, 80, 100],
                borderColor: 'rgb(54, 162, 235)',
                tension: 0.1,
              },
              {
                label: 'Earned Value (EV)',
                data: [8, 20, 35, 55, 70, 85],
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
              },
              {
                label: 'Actual Cost (AC)',
                data: [12, 28, 45, 62, 75, 90],
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1,
              },
            ],
          },
        },
        {
          type: 'line',
          options: {
            plugins: { title: { display: true, text: 'Baseline Execution Index (BEI) Chart' } },
          },
          data: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
            datasets: [
              {
                label: 'BEI',
                data: [1, 0.95, 0.98, 1.02, 0.97, 1.05],
                borderColor: 'rgb(153, 102, 255)',
                tension: 0.1,
              },
            ],
          },
        },
        {
          type: 'line',
          options: {
            plugins: { title: { display: true, text: 'Current Execution Index (CEI) Chart' } },
          },
          data: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
            datasets: [
              {
                label: 'CEI',
                data: [1, 1.05, 0.98, 1.03, 1.01, 0.99],
                borderColor: 'rgb(255, 159, 64)',
                tension: 0.1,
              },
            ],
          },
        },
      ]);
      
      setShowQualityCheck(true);
    };
    analyzeData();
  }, []);

  const runTest = (testName: string) => {
    setTestResults(prev => ({
      ...prev,
      [testName]: { ...prev[testName], status: 'running' }
    }));

    // Simulate running a test
    setTimeout(() => {
      const score = Math.random();
      let details = '';
      let recommendations: string[] = [];
      let chartData;

      switch (testName) {
        case 'hallucination':
          details = "The model showed a low tendency for hallucination in IPMDAR-related queries.";
          recommendations = [
            "Continue monitoring for consistency in outputs.",
            "Regularly update the model with the latest IPMDAR guidelines.",
            "Implement a fact-checking mechanism for critical IPMDAR data points.",
          ];
          chartData = {
            labels: ['Accurate', 'Hallucinated'],
            datasets: [{
              data: [score * 100, (1 - score) * 100],
              backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)'],
            }],
          };
          break;
        case 'bias':
          details = "The model demonstrated minimal bias in project management scenarios.";
          recommendations = [
            "Continue to provide balanced training data.",
            "Implement regular bias audits.",
            "Develop a diverse set of IPMDAR-related test cases.",
          ];
          chartData = {
            labels: ['Unbiased', 'Potentially Biased'],
            datasets: [{
              data: [score * 100, (1 - score) * 100],
              backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)'],
            }],
          };
          break;
        case 'consistency':
          details = "The model showed high consistency in IPMDAR interpretations across multiple queries.";
          recommendations = [
            "Maintain a comprehensive test suite for consistency checks.",
            "Regularly validate against official IPMDAR documentation.",
            "Implement version control for IPMDAR interpretations.",
          ];
          chartData = {
            labels: ['Consistent', 'Inconsistent'],
            datasets: [{
              data: [score * 100, (1 - score) * 100],
              backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)'],
            }],
          };
          break;
        case 'relevance':
          details = "The model demonstrated high relevance in its responses to IPMDAR-related queries.";
          recommendations = [
            "Continue to fine-tune on domain-specific IPMDAR data.",
            "Implement a feedback loop with subject matter experts.",
            "Develop a mechanism to stay updated with IPMDAR standards.",
          ];
          chartData = {
            labels: ['Relevant', 'Irrelevant'],
            datasets: [{
              data: [score * 100, (1 - score) * 100],
              backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)'],
            }],
          };
          break;
      }

      setTestResults(prev => ({
        ...prev,
        [testName]: { score, details, recommendations, status: 'completed', chartData }
      }));

      setRedTeamFeedback(`Red Team Analysis for ${testName} test:
1. The test effectively evaluates the model's performance on ${testName.toLowerCase()} in the context of IPMDAR data.
2. The ${score < 0.7 ? 'low' : 'high'} score of ${score.toFixed(2)} indicates ${score < 0.7 ? 'potential issues' : 'strong performance'} in this area.
3. Recommendation: ${recommendations[0]}`);
    }, 2000);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white">IPMDAR Reporting & Analytics</h2>
        <div className="flex space-x-2">
          <select 
            className="border rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
            value={dateRange}
            onChange={(e) => {
              setDateRange(e.target.value);
              setShowCustomDateRange(e.target.value === 'custom');
            }}
          >
            <option value="last7">Last 7 days</option>
            <option value="last30">Last 30 days</option>
            <option value="last90">Last 90 days</option>
            <option value="custom">Custom range</option>
          </select>
          {showCustomDateRange && (
            <div className="flex space-x-2">
              <input
                type="date"
                value={customDateRange.start}
                onChange={(e) => setCustomDateRange(prev => ({ ...prev, start: e.target.value }))}
                className="border rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
              />
              <input
                type="date"
                value={customDateRange.end}
                onChange={(e) => setCustomDateRange(prev => ({ ...prev, end: e.target.value }))}
                className="border rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
              />
            </div>
          )}
          <button 
            onClick={() => alert('Filters applied!')}
            className="flex items-center space-x-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200"
          >
            <Filter size={20} />
            <span>Apply Filter</span>
          </button>
        </div>
      </div>

      <div className="bg-blue-100 dark:bg-blue-900 p-4 rounded-lg flex items-center">
        <Bot size={24} className="text-blue-500 dark:text-blue-300 mr-2" />
        <p className="text-blue-800 dark:text-blue-200">{aiAgentMessage}</p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {aiGeneratedCharts.map((chart, index) => (
          <div key={index} className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold mb-4 text-gray-800 dark:text-white">{chart.options.plugins.title.text}</h3>
            <div className="h-80">
              {chart.type === 'bar' && <Bar data={chart.data} options={chart.options} />}
              {chart.type === 'line' && <Line data={chart.data} options={chart.options} />}
              {chart.type === 'pie' && <Pie data={chart.data} options={chart.options} />}
            </div>
          </div>
        ))}
      </div>

      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md mt-6">
        <h3 className="text-xl font-semibold mb-4 flex items-center text-gray-800 dark:text-white">
          <Brain className="mr-2 text-purple-500" size={24} />
          OccamEval Tests
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {Object.entries(testResults).map(([testName, result]) => (
            <div key={testName} className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
              <h4 className="text-lg font-semibold mb-2 capitalize text-gray-800 dark:text-white">{testName} Test</h4>
              {result.status === 'pending' && (
                <button
                  onClick={() => runTest(testName)}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200 flex items-center"
                >
                  <Play size={20} className="mr-2" />
                  Run Test
                </button>
              )}
              {result.status === 'running' && (
                <div className="flex items-center text-yellow-500">
                  <RefreshCw size={20} className="mr-2 animate-spin" />
                  Running test...
                </div>
              )}
              {result.status === 'completed' && (
                <div>
                  <p className="font-semibold text-gray-800 dark:text-white">Score: {result.score.toFixed(2)}</p>
                  <p className="text-sm text-gray-600 dark:text-gray-300 mt-2">{result.details}</p>
                  <div className="mt-4 h-40">
                    {result.chartData && (
                      <Pie data={result.chartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    )}
                  </div>
                  <h5 className="font-semibold mt-4 text-gray-800 dark:text-white">Recommendations:</h5>
                  <ul className="list-disc pl-5 text-sm text-gray-600 dark:text-gray-300">
                    {result.recommendations.map((rec, index) => (
                      <li key={index}>{rec}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {redTeamFeedback && (
        <div className="bg-red-100 dark:bg-red-900 p-4 rounded-lg">
          <h5 className="font-semibold text-red-800 dark:text-red-200 mb-2 flex items-center">
            <AlertTriangle size={20} className="mr-2" />
            Red Team Feedback
          </h5>
          <pre className="text-sm text-red-700 dark:text-red-300 whitespace-pre-wrap">{redTeamFeedback}</pre>
        </div>
      )}

      {showQualityCheck && <QualityCheckPopup />}
    </div>
  );
};

export default ReportingAnalytics;