import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Send, 
  Upload, 
  Bot, 
  Mic, 
  VolumeX, 
  Volume2, 
  AlertTriangle, 
  RefreshCw, 
  Brain,
  FileText,
  TrendingUp,
  DollarSign,
  Clock,
  Shield,
  CheckSquare,
  Activity,
  Trash2,
  Download,
  X,
  Database,
  Search
} from 'lucide-react';
import { AIAgentsProps, Message, Agent } from '../types';
import { sendMessage } from '../utils/api';
import { OccamAnalysis } from './analysis';
import useVoice from '../hooks/useVoice';
import AgentWelcomeMessage from './AgentWelcomeMessage';
import ProteusService from '../services/ProteusService';
import VoiceControls from './VoiceControls';

const AIAgents: React.FC<AIAgentsProps> = ({ addRecentActivity, onFileUpload }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedAgent, setSelectedAgent] = useState<Agent>('Occam');
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [showWelcome, setShowWelcome] = useState(false);
  const [isProteusConnected, setIsProteusConnected] = useState(false);
  const [isSearchingProteus, setIsSearchingProteus] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { speak, startListening, stopSpeaking, stopListening, isSpeaking, isListening } = useVoice();
  const [showAnalysis, setShowAnalysis] = useState(false);

  const agents = [
    {
      id: 'occam',
      name: 'Occam' as Agent,
      icon: Brain,
      description: 'IPMDAR Analysis',
      color: 'bg-blue-600'
    },
    {
      id: 'project-manager',
      name: 'Project Manager' as Agent,
      icon: FileText,
      description: 'Project Planning',
      color: 'bg-gray-800'
    },
    {
      id: 'evm-professional',
      name: 'Earned Value Management Professional' as Agent,
      icon: TrendingUp,
      description: 'EVM Analysis',
      color: 'bg-gray-800'
    },
    {
      id: 'cost-analyst',
      name: 'Cost Control Analyst' as Agent,
      icon: DollarSign,
      description: 'Cost Analysis',
      color: 'bg-gray-800'
    },
    {
      id: 'schedule-analyst',
      name: 'Schedule Analyst' as Agent,
      icon: Clock,
      description: 'Schedule Analysis',
      color: 'bg-gray-800'
    },
    {
      id: 'risk-manager',
      name: 'Risk Manager' as Agent,
      icon: AlertTriangle,
      description: 'Risk Assessment',
      color: 'bg-gray-800'
    },
    {
      id: 'performance-analyst',
      name: 'Performance Data Analyst' as Agent,
      icon: Activity,
      description: 'Performance Analysis',
      color: 'bg-gray-800'
    },
    {
      id: 'project-controller',
      name: 'Project Controller' as Agent,
      icon: Shield,
      description: 'Project Controls',
      color: 'bg-gray-800'
    },
    {
      id: 'quality-controller',
      name: 'Quality Controller' as Agent,
      icon: CheckSquare,
      description: 'Quality Control',
      color: 'bg-gray-800'
    }
  ];

  useEffect(() => {
    checkProteusConnection();
    scrollToBottom();
    setupAssessmentTimer();
  }, [messages]);

  const checkProteusConnection = async () => {
    const proteusService = ProteusService.getInstance();
    setIsProteusConnected(proteusService.isConnectedToProteus());
  };

  const setupAssessmentTimer = () => {
    const timer = setInterval(() => {
      if (messages.length > 0 && !isLoading) {
        const question = getNextAssessmentQuestion();
        addMessage({
          id: Date.now(),
          text: question,
          sender: 'agent',
          timestamp: new Date(),
          agent: selectedAgent,
          isAssessment: true
        });
      }
    }, 5 * 60 * 1000); // 5 minutes

    return () => clearInterval(timer);
  };

  const getNextAssessmentQuestion = () => {
    const questions = {
      'Occam': [
        'How would you rate your understanding of the IPMDAR requirements we discussed?',
        'What aspects of the analysis would you like to explore further?',
        'Would you like me to explain any specific IPMDAR concepts in more detail?'
      ],
      'Project Manager': [
        'How confident are you in the current project timeline?',
        'What project risks would you like to discuss?',
        'Should we review the resource allocation strategy?'
      ]
      // Add questions for other agents...
    };

    const agentQuestions = questions[selectedAgent] || questions['Occam'];
    return agentQuestions[Math.floor(Math.random() * agentQuestions.length)];
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleAgentSelect = (agent: Agent) => {
    setSelectedAgent(agent);
    setShowWelcome(true);
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim() || isLoading) return;
    
    // Stop any ongoing speech before sending new message
    stopSpeaking();

    const userMessage: Message = { 
      id: Date.now(), 
      text: inputMessage, 
      sender: 'user',
      timestamp: new Date(),
      agent: 'User'
    };

    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');
    setIsLoading(true);
    setError(null);

    try {
      const response = await sendMessage(inputMessage, messages, 'azure', {
        apiKey: 'a1bdb11c3a4f4453a23a7982483e1809',
        endpoint: 'https://ai-occamaiv1hub770033508777.openai.azure.com',
        deploymentName: 'gpt-4o-realtime-preview'
      }, selectedAgent, uploadedFiles);

      const aiResponse: Message = { 
        id: Date.now() + 1, 
        text: response.data.message, 
        sender: 'agent',
        timestamp: new Date(),
        agent: selectedAgent
      };
      setMessages(prev => [...prev, aiResponse]);
      addRecentActivity(Bot, `${selectedAgent} responded to a query`);

      if (isSpeaking) {
        await speak(response.data.message);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
      setError(`Error: ${errorMessage}`);
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setUploadedFiles(Array.from(files));
      onFileUpload(Array.from(files));
    }
  };

  const handleClearChat = () => {
    setMessages([]);
    setUploadedFiles([]);
  };

  const handleDownloadChat = () => {
    const chatHistory = messages.map(msg => 
      `[${msg.timestamp.toLocaleString()}] ${msg.agent}: ${msg.text}`
    ).join('\n\n');
    
    const blob = new Blob([chatHistory], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `chat-history-${new Date().toISOString()}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleSearchProteus = async () => {
    if (!isProteusConnected) {
      setError('Please connect to Proteus Envision Suite first');
      return;
    }

    setIsSearchingProteus(true);
    try {
      // Implement Proteus search functionality
      await new Promise(resolve => setTimeout(resolve, 1500));
      addMessage({
        id: Date.now(),
        text: 'Searching Proteus Envision Suite for relevant information...',
        sender: 'agent',
        timestamp: new Date(),
        agent: selectedAgent
      });
    } catch (error) {
      setError('Error searching Proteus Envision Suite');
    } finally {
      setIsSearchingProteus(false);
    }
  };

  return (
    <div className="space-y-6">
      {/* Instructions */}
      <div className="bg-blue-50 dark:bg-blue-900/30 p-4 mb-4 rounded-lg">
        <h3 className="font-semibold mb-2">How to use the AI Agent Interface:</h3>
        <ul className="list-disc list-inside space-y-1 text-sm">
          <li>Select an AI agent to chat with from the grid above</li>
          <li>Upload files for analysis using the upload button</li>
          <li>Type your questions or requests in the chat input</li>
          <li>Use voice commands by clicking the microphone icon</li>
          <li>Download chat history or clear the conversation as needed</li>
        </ul>
      </div>

      {/* Agent Grid */}
      <div className="grid grid-cols-3 gap-4">
        {agents.map((agent) => (
          <button
            key={agent.id}
            onClick={() => handleAgentSelect(agent.name)}
            className={`p-4 rounded-lg transition-all duration-200 ${
              selectedAgent === agent.name ? agent.color : 'bg-gray-800'
            } text-white`}
          >
            <div className="flex flex-col items-center space-y-2">
              <agent.icon className="h-8 w-8" />
              <div className="text-center">
                <div className="font-semibold">{agent.name}</div>
                <div className="text-xs opacity-75">{agent.description}</div>
              </div>
            </div>
          </button>
        ))}
      </div>

      {/* Chat Interface */}
      <div className="flex-1 p-4 space-y-4 overflow-y-auto">
        {selectedAgent === 'Occam' && showAnalysis && <OccamAnalysis />}
        {messages.map((message) => (
          <motion.div
            key={message.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
          >
            <div
              className={`max-w-3/4 p-3 rounded-lg ${
                message.sender === 'user'
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-700 text-white'
              }`}
            >
              {message.sender !== 'user' && (
                <div className="flex items-center text-xs text-gray-300 mb-1">
                  <Brain size={12} className="mr-1" />
                  {message.agent}
                </div>
              )}
              <p className="whitespace-pre-wrap">{message.text}</p>
              <div className="text-xs text-gray-300 mt-1">
                {message.timestamp.toLocaleTimeString()}
              </div>
            </div>
          </motion.div>
        ))}
        <div ref={messagesEndRef} />
        
        <AnimatePresence>
          {isLoading && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="flex justify-start"
            >
              <div className="bg-blue-100 dark:bg-blue-900/30 rounded-lg p-4 flex items-center space-x-2">
                <Brain className="text-blue-500 animate-pulse" size={20} />
                <span className="text-blue-700 dark:text-blue-300">
                  {selectedAgent} is thinking...
                </span>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {error && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex justify-center"
          >
            <div className="bg-red-100 dark:bg-red-900/30 text-red-700 dark:text-red-300 rounded-lg p-4 flex items-center space-x-2">
              <AlertTriangle size={20} />
              <span>{error}</span>
            </div>
          </motion.div>
        )}
      </div>

      {/* Input Area */}
      <div className="p-4 bg-gray-800 border-t border-gray-700">
        <div className="flex space-x-2 mb-2">
          <div className="relative flex-1">
            <textarea
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleSendMessage()}
              placeholder={`Ask ${selectedAgent} something...`}
              className="w-full px-4 py-2 pr-12 bg-gray-100 dark:bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
              rows={3}
              disabled={isLoading}
            />
            <div className="absolute bottom-2 right-2">
              <VoiceControls
                onSpeechResult={(text) => setInputMessage(text)}
                messageToSpeak={messages[messages.length - 1]?.text}
                disabled={isLoading}
              />
            </div>
          </div>
          <button
            onClick={handleSendMessage}
            disabled={!inputMessage.trim() || isLoading}
            className={`px-6 self-end h-10 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center ${
              (!inputMessage.trim() || isLoading) && 'opacity-50 cursor-not-allowed'
            }`}
          >
            {isLoading ? (
              <RefreshCw className="animate-spin" size={20} />
            ) : (
              <Send size={20} />
            )}
          </button>
          <button
            onClick={() => fileInputRef.current?.click()}
            className="p-2 bg-gray-700 text-white rounded hover:bg-gray-600 transition-colors duration-200"
          >
            <Upload size={20} />
          </button>
          <button
            onClick={handleClearChat}
            className="p-2 bg-gray-700 text-white rounded hover:bg-gray-600 transition-colors duration-200"
          >
            <Trash2 size={20} />
          </button>
          <button
            onClick={handleDownloadChat}
            className="p-2 bg-gray-700 text-white rounded hover:bg-gray-600 transition-colors duration-200"
          >
            <Download size={20} />
          </button>
          <button
            onClick={() => isSpeaking ? stopSpeaking() : startListening()}
            className="p-2 bg-gray-700 text-white rounded hover:bg-gray-600 transition-colors duration-200"
          >
            {isSpeaking ? <VolumeX size={20} /> : <Volume2 size={20} />}
          </button>
          <button
            onClick={handleSearchProteus}
            disabled={!isProteusConnected || isSearchingProteus}
            className={`p-2 bg-gray-700 text-white rounded hover:bg-gray-600 transition-colors duration-200 ${
              (!isProteusConnected || isSearchingProteus) && 'opacity-50 cursor-not-allowed'
            }`}
          >
            {isSearchingProteus ? (
              <RefreshCw className="animate-spin" size={20} />
            ) : (
              <Search size={20} />
            )}
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            className="hidden"
            multiple
          />
        </div>
      </div>
    </div>
  );
};

export default AIAgents;