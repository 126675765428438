import React, { useState } from 'react';
import { AlertCircle, Database, Key } from 'lucide-react';

interface ConnectModalProps {
  showConnectModal: boolean;
  setShowConnectModal: (show: boolean) => void;
  connectionInfo: {
    server: string;
    database: string;
    username: string;
    password: string;
  };
  setConnectionInfo: (info: {
    server: string;
    database: string;
    username: string;
    password: string;
  }) => void;
  handleConnectToProteus: () => void;
}

export const ConnectModal: React.FC<ConnectModalProps> = ({
  showConnectModal,
  setShowConnectModal,
  connectionInfo,
  setConnectionInfo,
  handleConnectToProteus
}) => {
  const [useAzure, setUseAzure] = useState(false);
  const [azureInfo, setAzureInfo] = useState({
    tenantId: '',
    clientId: '',
    clientSecret: '',
    subscriptionId: '',
    resourceGroup: ''
  });
  const [showAdvanced, setShowAdvanced] = useState(false);

  if (!showConnectModal) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white flex items-center">
          <Database className="mr-2" />
          Connect to Proteus
        </h2>

        <div className="mb-4">
          <label className="inline-flex items-center mb-4 text-gray-700 dark:text-gray-300">
            <input
              type="checkbox"
              checked={useAzure}
              onChange={(e) => setUseAzure(e.target.checked)}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="ml-2">Use Azure Authentication</span>
          </label>
        </div>

        {useAzure ? (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Tenant ID</label>
              <input
                type="text"
                value={azureInfo.tenantId}
                onChange={(e) => setAzureInfo({ ...azureInfo, tenantId: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                placeholder="Enter Azure Tenant ID"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Client ID</label>
              <input
                type="text"
                value={azureInfo.clientId}
                onChange={(e) => setAzureInfo({ ...azureInfo, clientId: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                placeholder="Enter Azure Client ID"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Client Secret</label>
              <input
                type="password"
                value={azureInfo.clientSecret}
                onChange={(e) => setAzureInfo({ ...azureInfo, clientSecret: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                placeholder="Enter Azure Client Secret"
              />
            </div>
            {showAdvanced && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Subscription ID</label>
                  <input
                    type="text"
                    value={azureInfo.subscriptionId}
                    onChange={(e) => setAzureInfo({ ...azureInfo, subscriptionId: e.target.value })}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                    placeholder="Enter Azure Subscription ID"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Resource Group</label>
                  <input
                    type="text"
                    value={azureInfo.resourceGroup}
                    onChange={(e) => setAzureInfo({ ...azureInfo, resourceGroup: e.target.value })}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                    placeholder="Enter Azure Resource Group"
                  />
                </div>
              </>
            )}
            <button
              onClick={() => setShowAdvanced(!showAdvanced)}
              className="text-blue-500 hover:text-blue-600 text-sm"
            >
              {showAdvanced ? 'Hide Advanced Options' : 'Show Advanced Options'}
            </button>
          </div>
        ) : (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Server</label>
              <input
                type="text"
                value={connectionInfo.server}
                onChange={(e) => setConnectionInfo({ ...connectionInfo, server: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                placeholder="Enter server address"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Database</label>
              <input
                type="text"
                value={connectionInfo.database}
                onChange={(e) => setConnectionInfo({ ...connectionInfo, database: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                placeholder="Enter database name"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Username</label>
              <input
                type="text"
                value={connectionInfo.username}
                onChange={(e) => setConnectionInfo({ ...connectionInfo, username: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                placeholder="Enter username"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Password</label>
              <input
                type="password"
                value={connectionInfo.password}
                onChange={(e) => setConnectionInfo({ ...connectionInfo, password: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 dark:bg-gray-700 dark:text-white dark:border-gray-600"
                placeholder="Enter password"
              />
            </div>
          </div>
        )}

        <div className="bg-blue-50 dark:bg-blue-900/30 rounded-lg p-4 mt-4">
          <div className="flex items-start">
            <AlertCircle className="text-blue-500 mt-0.5 mr-2" size={16} />
            <p className="text-sm text-blue-700 dark:text-blue-300">
              {useAzure 
                ? 'Azure authentication provides enhanced security through Azure Active Directory integration.'
                : 'Standard authentication uses direct database credentials. Consider using Azure authentication for enhanced security.'}
            </p>
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={() => setShowConnectModal(false)}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
          >
            Cancel
          </button>
          <button
            onClick={handleConnectToProteus}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center"
          >
            <Key className="mr-2" size={16} />
            Connect
          </button>
        </div>
      </div>
    </div>
  );
};