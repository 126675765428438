import React from 'react';
import { motion } from 'framer-motion';
import { AlertTriangle, TrendingUp, AlertCircle, CheckCircle } from 'lucide-react';

interface RiskCategoryAnalysisProps {
  aleatoryRisks: any;
  epistemicRisks: any;
}

const RiskCategoryAnalysis: React.FC<RiskCategoryAnalysisProps> = ({
  aleatoryRisks,
  epistemicRisks
}) => {
  const getSeverityIcon = (severity: string) => {
    switch (severity.toLowerCase()) {
      case 'high':
        return <AlertTriangle className="text-red-500" size={20} />;
      case 'medium':
        return <AlertCircle className="text-yellow-500" size={20} />;
      case 'low':
        return <CheckCircle className="text-green-500" size={20} />;
      default:
        return null;
    }
  };

  const getSeverityClass = (severity: string) => {
    switch (severity.toLowerCase()) {
      case 'high':
        return 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200';
      case 'medium':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200';
      case 'low':
        return 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200';
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200';
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
      <h3 className="text-lg font-semibold mb-4 flex items-center">
        <AlertTriangle className="mr-2 text-yellow-500" size={20} />
        Risk Category Analysis
      </h3>

      <div className="space-y-6">
        <div>
          <h4 className="text-md font-semibold mb-3 flex items-center">
            <TrendingUp className="mr-2 text-blue-500" size={16} />
            Aleatory Risks (Statistical Uncertainties)
          </h4>
          <div className="space-y-4">
            {Object.entries(aleatoryRisks).map(([key, value]: [string, any]) => (
              <motion.div
                key={key}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center">
                    {getSeverityIcon(value.severity)}
                    <h5 className="ml-2 font-medium">
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </h5>
                  </div>
                  <span className={`px-2 py-1 rounded-full text-xs ${getSeverityClass(value.severity)}`}>
                    {value.severity}
                  </span>
                </div>
                <div className="mt-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600 dark:text-gray-400">Confidence Level</span>
                    <span className="font-medium">{(value.confidence * 100).toFixed(0)}%</span>
                  </div>
                  {Object.entries(value.metrics).map(([metricKey, metricValue]: [string, any]) => (
                    <div key={metricKey} className="flex justify-between text-sm mt-1">
                      <span className="text-gray-600 dark:text-gray-400">
                        {metricKey.replace(/([A-Z])/g, ' $1').trim()}
                      </span>
                      <span className="font-medium">{typeof metricValue === 'number' ? metricValue.toFixed(2) : metricValue}</span>
                    </div>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        <div>
          <h4 className="text-md font-semibold mb-3 flex items-center">
            <AlertCircle className="mr-2 text-purple-500" size={16} />
            Epistemic Risks (Systematic Uncertainties)
          </h4>
          <div className="space-y-4">
            {Object.entries(epistemicRisks).map(([key, value]: [string, any]) => (
              <motion.div
                key={key}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center">
                    {getSeverityIcon(value.severity)}
                    <h5 className="ml-2 font-medium">
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </h5>
                  </div>
                  <span className={`px-2 py-1 rounded-full text-xs ${getSeverityClass(value.severity)}`}>
                    {value.severity}
                  </span>
                </div>
                <div className="mt-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600 dark:text-gray-400">Confidence Level</span>
                    <span className="font-medium">{(value.confidence * 100).toFixed(0)}%</span>
                  </div>
                  <div className="mt-2 space-y-1">
                    {value.issues.map((issue: string, index: number) => (
                      <div key={index} className="flex items-start text-sm">
                        <span className="text-gray-400 mr-2">•</span>
                        <span className="text-gray-600 dark:text-gray-400">{issue}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskCategoryAnalysis;