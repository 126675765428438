import React, { useState } from 'react';
import { PencilLine, Save, Plus, Trash2, ChevronUp, ChevronDown } from 'lucide-react';

interface MenuItem {
  id: string;
  label: string;
  view: string;
  enabled: boolean;
  order: number;
}

const EditSidebar: React.FC = () => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([
    { id: 'dashboard', label: 'Occam & Worker Bees Interface', view: 'dashboard', enabled: true, order: 0 },
    { id: 'knowledgeBase', label: 'Knowledge Base', view: 'data', enabled: true, order: 1 },
    { id: 'ipmdarHealth', label: 'IPMDAR Health', view: 'health', enabled: true, order: 2 },
    { id: 'reporting', label: 'Reporting & Analytics', view: 'reports', enabled: true, order: 3 },
    { id: 'riskyProject', label: 'RiskyProject', view: 'riskyproject', enabled: true, order: 4 },
    { id: 'aiAgents', label: 'AI Agents Org Chart', view: 'aiagents', enabled: true, order: 5 },
    { id: 'proteus', label: 'Proteus Analytics', view: 'proteus', enabled: true, order: 6 },
    { id: 'helpDesk', label: 'Help Desk', view: 'helpdesk', enabled: true, order: 7 }
  ]);

  const moveItem = (index: number, direction: 'up' | 'down') => {
    const newItems = [...menuItems];
    if (direction === 'up' && index > 0) {
      [newItems[index], newItems[index - 1]] = [newItems[index - 1], newItems[index]];
    } else if (direction === 'down' && index < newItems.length - 1) {
      [newItems[index], newItems[index + 1]] = [newItems[index + 1], newItems[index]];
    }
    setMenuItems(newItems.map((item, i) => ({ ...item, order: i })));
  };

  const toggleItem = (id: string) => {
    setMenuItems(items =>
      items.map(item =>
        item.id === id ? { ...item, enabled: !item.enabled } : item
      )
    );
  };

  const handleSave = () => {
    // In a real implementation, this would save to backend/localStorage
    console.log('Saving sidebar configuration:', menuItems);
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-6 flex items-center text-gray-800 dark:text-white">
          <PencilLine className="mr-2" />
          Edit Sidebar
        </h2>

        <div className="space-y-4">
          {menuItems.map((item, index) => (
            <div
              key={item.id}
              className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
            >
              <div className="flex items-center space-x-4">
                <input
                  type="checkbox"
                  checked={item.enabled}
                  onChange={() => toggleItem(item.id)}
                  className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
                />
                <span className="text-gray-700 dark:text-gray-200">{item.label}</span>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => moveItem(index, 'up')}
                  disabled={index === 0}
                  className="p-1 hover:bg-gray-200 dark:hover:bg-gray-600 rounded"
                >
                  <ChevronUp size={20} />
                </button>
                <button
                  onClick={() => moveItem(index, 'down')}
                  disabled={index === menuItems.length - 1}
                  className="p-1 hover:bg-gray-200 dark:hover:bg-gray-600 rounded"
                >
                  <ChevronDown size={20} />
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center"
          >
            <Save className="w-4 h-4 mr-2" />
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSidebar;