import React from 'react';
import { motion } from 'framer-motion';
import { AlertTriangle, TrendingUp, BarChart2 } from 'lucide-react';
import { Bar } from 'react-chartjs-2';

interface RiskDashboardProps {
  aleatoryRisks: any;
  epistemicRisks: any;
}

const RiskDashboard: React.FC<RiskDashboardProps> = ({
  aleatoryRisks,
  epistemicRisks
}) => {
  const riskData = {
    labels: ['Cost', 'Schedule', 'Performance'],
    datasets: [
      {
        label: 'Risk Severity',
        data: [
          aleatoryRisks.costVariance.severity === 'High' ? 3 : 
          aleatoryRisks.costVariance.severity === 'Medium' ? 2 : 1,
          aleatoryRisks.scheduleVariance.severity === 'High' ? 3 : 
          aleatoryRisks.scheduleVariance.severity === 'Medium' ? 2 : 1,
          aleatoryRisks.performanceMetrics.severity === 'High' ? 3 : 
          aleatoryRisks.performanceMetrics.severity === 'Medium' ? 2 : 1
        ],
        backgroundColor: [
          'rgba(239, 68, 68, 0.5)',
          'rgba(234, 179, 8, 0.5)',
          'rgba(34, 197, 94, 0.5)'
        ],
        borderColor: [
          'rgb(239, 68, 68)',
          'rgb(234, 179, 8)',
          'rgb(34, 197, 94)'
        ],
        borderWidth: 1
      }
    ]
  };

  const confidenceData = {
    labels: ['Cost', 'Schedule', 'Performance', 'Data Quality', 'Methodology', 'Bias'],
    datasets: [
      {
        label: 'Confidence Level',
        data: [
          aleatoryRisks.costVariance.confidence * 100,
          aleatoryRisks.scheduleVariance.confidence * 100,
          aleatoryRisks.performanceMetrics.confidence * 100,
          epistemicRisks.dataQuality.confidence * 100,
          epistemicRisks.methodologyGaps.confidence * 100,
          epistemicRisks.systematicBias.confidence * 100
        ],
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        borderColor: 'rgb(59, 130, 246)',
        borderWidth: 1
      }
    ]
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg"
      >
        <h3 className="text-lg font-semibold mb-4 flex items-center">
          <AlertTriangle className="mr-2 text-yellow-500" size={20} />
          Risk Severity Distribution
        </h3>
        <div className="h-64">
          <Bar
            data={riskData}
            options={{
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true,
                  max: 3,
                  ticks: {
                    callback: function(value) {
                      return ['Low', 'Medium', 'High'][Number(value) - 1];
                    }
                  }
                }
              }
            }}
          />
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg"
      >
        <h3 className="text-lg font-semibold mb-4 flex items-center">
          <TrendingUp className="mr-2 text-blue-500" size={20} />
          Confidence Levels
        </h3>
        <div className="h-64">
          <Bar
            data={confidenceData}
            options={{
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true,
                  max: 100,
                  ticks: {
                    callback: function(value) {
                      return value + '%';
                    }
                  }
                }
              }
            }}
          />
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="lg:col-span-2 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg"
      >
        <h3 className="text-lg font-semibold mb-4 flex items-center">
          <BarChart2 className="mr-2 text-green-500" size={20} />
          Key Risk Indicators
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <h4 className="font-semibold text-gray-800 dark:text-gray-200">Cost Performance</h4>
            <div className="mt-2 space-y-2">
              <div className="flex justify-between">
                <span className="text-gray-600 dark:text-gray-400">CPI</span>
                <span className="font-medium">{aleatoryRisks.performanceMetrics.metrics.cpi.toFixed(2)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600 dark:text-gray-400">TCPI</span>
                <span className="font-medium">{aleatoryRisks.performanceMetrics.metrics.tcpi.toFixed(2)}</span>
              </div>
            </div>
          </div>

          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <h4 className="font-semibold text-gray-800 dark:text-gray-200">Schedule Performance</h4>
            <div className="mt-2 space-y-2">
              <div className="flex justify-between">
                <span className="text-gray-600 dark:text-gray-400">SPI</span>
                <span className="font-medium">{aleatoryRisks.performanceMetrics.metrics.spi.toFixed(2)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600 dark:text-gray-400">Mean Delay</span>
                <span className="font-medium">{aleatoryRisks.scheduleVariance.metrics.meanDelay} days</span>
              </div>
            </div>
          </div>

          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <h4 className="font-semibold text-gray-800 dark:text-gray-200">Data Quality</h4>
            <div className="mt-2 space-y-2">
              <div className="flex justify-between">
                <span className="text-gray-600 dark:text-gray-400">Completeness</span>
                <span className="font-medium">{(epistemicRisks.dataQuality.confidence * 100).toFixed(0)}%</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600 dark:text-gray-400">Critical Issues</span>
                <span className="font-medium">{epistemicRisks.dataQuality.issues.length}</span>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default RiskDashboard;