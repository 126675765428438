import React from 'react';
import { Users, AlertCircle, Activity, BarChart2 } from 'lucide-react';
import { Bar } from 'react-chartjs-2';

interface ResourceMetrics {
  bottlenecks: Array<{
    resource: string;
    utilization: number;
    impact: string;
  }>;
  allocation: Array<{
    resource: string;
    allocated: number;
    actual: number;
  }>;
}

const ResourceTab: React.FC<{ metrics?: ResourceMetrics }> = ({ metrics }) => {
  const allocationData = {
    labels: metrics?.allocation.map(a => a.resource) || [],
    datasets: [
      {
        label: 'Allocated Hours',
        data: metrics?.allocation.map(a => a.allocated) || [],
        backgroundColor: 'rgba(59, 130, 246, 0.6)',
        borderColor: 'rgb(59, 130, 246)',
        borderWidth: 1
      },
      {
        label: 'Actual Hours',
        data: metrics?.allocation.map(a => a.actual) || [],
        backgroundColor: 'rgba(239, 68, 68, 0.6)',
        borderColor: 'rgb(239, 68, 68)',
        borderWidth: 1
      }
    ]
  };

  return (
    <div className="space-y-6">
      {/* Resource Overview */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4 flex items-center text-gray-800 dark:text-white">
          <Users className="mr-2 text-blue-500" size={24} />
          Resource Overview
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Total Resources</p>
                <p className="text-2xl font-bold text-blue-500">45</p>
              </div>
              <Users className="text-blue-500" size={24} />
            </div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Utilization Rate</p>
                <p className="text-2xl font-bold text-green-500">87%</p>
              </div>
              <Activity className="text-green-500" size={24} />
            </div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Resource Gaps</p>
                <p className="text-2xl font-bold text-yellow-500">3</p>
              </div>
              <AlertCircle className="text-yellow-500" size={24} />
            </div>
          </div>
        </div>
      </div>

      {/* Resource Allocation */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4 flex items-center text-gray-800 dark:text-white">
          <BarChart2 className="mr-2 text-blue-500" size={24} />
          Resource Allocation
        </h3>
        <div className="h-80">
          <Bar 
            data={allocationData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            }}
          />
        </div>
      </div>

      {/* Resource Bottlenecks */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Resource Bottlenecks</h3>
        <div className="space-y-4">
          {metrics?.bottlenecks.map((bottleneck, index) => (
            <div key={index} className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <div className="flex items-center justify-between mb-2">
                <h4 className="font-semibold text-gray-800 dark:text-gray-200">{bottleneck.resource}</h4>
                <span className={`px-3 py-1 rounded-full text-sm ${
                  bottleneck.utilization > 0.9 
                    ? 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200'
                    : bottleneck.utilization > 0.75
                    ? 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200'
                    : 'bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200'
                }`}>
                  {(bottleneck.utilization * 100).toFixed(1)}% Utilized
                </span>
              </div>
              <p className="text-gray-600 dark:text-gray-400 text-sm">{bottleneck.impact}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResourceTab;